.markerMap {
  width: 30px;
  height: 30px;
}

/* select box */
/* .select-box {
    z-index: 4;
  } */
.select-box > div:first-child {
  height: calc(1.6875rem + 2rem + 2px);
}

.select-box > div:first-child:hover {
  height: calc(1.6875rem + 2rem + 2px);
}

/* toast color */

.Toastify__toast--success {
  background: #f8b63a !important;
}
.Toastify__toast--error {
  background: #fae439 !important;
}

.loaderHolder {
  left: 0;
  top: 0;
  position: fixed;
  background: transparent;
  width: 100%;
  height: 100%;
  background-color: rgba(126, 125, 111, 0.23);
  z-index: 1000;
}
.loaderMain {
  position: absolute;
  left: 50%;
  top: 35%;
  z-index: 1000;
  height: 31px;
  width: 31px;
}

.tokenization {
  margin-bottom: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 40px;
}

.btn-custom {
  background-color: #fdc63d !important;
  border-color: #fdc63d !important;
  color: #000 !important;
  border-radius: 5px;
}

.btn-default {
  background-color: #fff !important;
  border-color: #ccc !important;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}
.pagination > li {
  display: inline;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #337ab7;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  z-index: 2;
  color: #23527c;
  background-color: #eeeeee;
  border-color: #ddd;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  z-index: 3;
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
  cursor: default;
}

.tab-content > .tab-pane {
  display: block !important;
}

@media (min-width: 768px) {
  #search-form #search-form-bottom #search-form-bottom-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: -70px;
    z-index: 0 !important;
  }
}

.reatinn-box {
  display: flex;
  max-width: 500px !important;
  height: auto;
  flex-wrap: wrap;
}

.reatinn-box > div.dashed-border {
  position: relative;
  padding: 0 !important;
  margin-right: 10px;
}

.reatinn-box > div.dashed-border .image-item__btn-wrapper {
  position: absolute;
  top: 0;
  right: 0;
}

.reatinn-box > div.dashed-border > img {
  height: 100px;
  object-fit: cover;
  width: 100px;
}

.reatinn-box > div.dashed-border .image-item__btn-wrapper > button {
  width: 25px;
  height: 25px;
  overflow: hidden;
  border: 0;
  background-color: rgb(255 255 255 / 74%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #c32e0b;
}

.reatinn-box > label.dashed-border {
  width: 100px;
  min-width: 130px;
  height: 100px;
  padding: 0 10px !important;
  margin: 0;
}
.reatinn-box > * {
  margin-bottom: 10px;
}
.reatinn-box .dashed-border .image-upload > p {
  width: 100px;
}

.review-modal .form-control.secondary-input {
  border: 1px solid #ccc !important;
}

.error {
  color: #b00020;
}
#landing-page-section_4-container .benefit-image {
  height: 110px;
  min-width: 110px;
  width: 110px;
  padding: 1rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#landing-page-section_4-container .no-gutters .d-flex {
  justify-content: space-between;
}

.App {
  font-family: sans-serif;
  text-align: center;
}
.modal {
  background: rgba(0, 0, 0, 0.6);
}
.modal-container {
  position: absolute;
  width: 60%;
  background: #ffffff;
  left: 25%;
  top: 30%;
  padding: 20px;
}
.whyus_header {
  margin-top: 8rem !important;
}
.img-fluid-col {
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-nav {
  gap: 0.5rem;
}
.landing-page-footer .social-nav a {
  display: flex;
  width: 45px;
  height: 45px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.card .property-image {
  max-height: 220px !important;
  overflow: hidden;
}
.card .property-image img {
  min-height: 220px;
  width: 100% !important;
}
.owl-stage-outer {
  padding: 1rem 0;
}
.investment-deal .investment-deal-images {
  max-height: 220px !important;
  height: 220px;
  overflow: hidden;
  border-radius: 10px;
}
.investment-deal .investment-deal-images img {
  min-height: 180px !important;
  display: block;
}
.investment-deal .investment-deal-card {
  margin-top: -20px !important;
}

.footer_bottom {
  padding: 3rem 0;
  padding-bottom: 3rem;
  color: #b9b9b9;
}
.copyright-text {
  color: #b9b9b9 !important;
}
.footer_bottom p {
  font-size: 0.85rem;
  font-weight: normal;
}
.footer_bottom p:first-child {
  font-size: 1rem;
}

.comingSoonBtn {
  position: absolute;
  top: -10px;
  right: 0;
  background-image: linear-gradient(to right, #fbb040 0%, #ffde17 100%);
  font-size: 14px;
  padding: 0.1rem 0.75rem;
}

#property-detail-amenities {
  flex-wrap: wrap;
  row-gap: 2rem;
  justify-content: flex-start !important;
}

#property-detail-amenities .property-detail-amenity {
  width: 25%;
}

.upload-agent-profile {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
}

.list-property-image {
  overflow: hidden;
  max-height: 363px;
  border-radius: 10px;
}
.featured-property-images a {
  overflow: hidden;
  height: 260px;
  border-radius: 10px;
}
.featured-property .property-card {
  margin-top: -80px !important;
}
.featured-property-images img {
  min-height: 260px;
}
.investment_deals_carousel {
}
/* .investment-deal-images {
  min-height: 260px;
} */
.investment-deal {
}
.image-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.agent-profile_upload {
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
}
.agent-profile_upload img {
  position: absolute;
  top: 50%;
  width: 120%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#search-form-top-buttons {
  margin-bottom: 35px !important;
}
#search-form-top-buttons .btn {
  background: rgb(255, 255, 255);
}
#search-form-top-buttons .btn.btn-gradient {
  background-image: linear-gradient(
    to right,
    #fbb040 0%,
    #ffde17 100%
  ) !important;
}
.forerroemsg .error {
  position: absolute;
  top: 100px;
}
#company-nav-tab-agent-management #search-filter_options {
  margin-bottom: 0 !important;
}

.agent-image-box {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-top: -70px;
  top: -10px;
  background-color: #fff;
  left: -3rem;
}

.agent-image-box img {
  margin-top: 0 !important;
  max-width: 110% !important;
  border-radius: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 1024px) {
  .agent-image-box {
    left: 50%;
    margin-top: 0;
    margin: 1rem 0;
    transform: translateX(-50%);
  }
}

.pageNotFound {
  max-width: 700px;
  margin: 0 auto;
  position: relative;
  top: 4rem;
  text-align: center;
}

.pageNotFound img {
  width: 100%;
}
.pageNotFound h1 {
  margin-bottom: 2rem;
}
.pageNotFound p {
  width: 70%;
  margin: 0 auto;
  font-size: 20px;
  color: grey;
}

.pageNotFound .btnBox {
  display: flex;
  margin-top: 3rem;
  justify-content: center;
  gap: 2rem;
}

#search-form-top {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.image-upload .form-group {
  position: absolute;
}

.video-react .video-react-big-play-button {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.property-card .empty--card {
  height: 420px;
}
.featured--proprty {
  overflow: hidden;
}

.featured--proprty a {
  text-decoration: none;
  color: #212529;
}

.featured--proprty .card-body {
  position: relative;
}
.featured--proprty .card-body .not--approved {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
}
.featured--proprty .card-body .property-type {
  position: relative;
}
.property-agent {
  display: flex;
  align-items: center;
}
.investment--banner {
  background-image: url(../images/investments-banner.png);
  background-size: cover;
  background-position: left bottom;
  background-repeat: no-repeat;
  color: #fff;
}
.investment--banner h1 span {
  background-image: linear-gradient(to right, #fbb040 0%, #ffde17 100%);
  width: fit-content;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.investment--banner .banner--heading {
  padding: 8rem 2rem;
}
.investment--banner .banner--heading h1 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 70%;
  margin: 0 auto;
}

.investorDataList {
  list-style: none;
  padding: 0;
}

#investor-section_6 {
  padding: 4rem 0;
}

.realEstateDataList {
  list-style: none;
  padding: 0;
}
.realEstateDataList li {
  display: flex;
  gap: 0.75rem;
  margin-bottom: 1rem;
  align-items: flex-start;
}
.realEstateDataList .icon {
  min-width: 60px;
  height: 60px;
  border-radius: 50%;
}

.realEstateDataList .icon img {
  object-fit: cover;
}
