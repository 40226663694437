/******************************
 *  AQARCHAIN - CUSTOM THEME  *
 ******************************/
/**********************
 * SETTINGS OVERRIDES *
 **********************/
/*!
 * Bootstrap v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
 :root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #fae439;
    --secondary: #010101;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #374047;
    --blue: #007bff;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 1024px;
    --breakpoint-xl: 1440px;
    --breakpoint-xxl: 1680px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  
  article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
  }
  
  body {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
  }
  
  [tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
  }
  
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  
  abbr[title],
  abbr[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
            text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
  }
  
  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }
  
  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  
  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }
  
  dt {
    font-weight: 700;
  }
  
  dd {
    margin-bottom: .5rem;
    margin-left: 0;
  }
  
  blockquote {
    margin: 0 0 1rem;
  }
  
  b,
  strong {
    font-weight: bolder;
  }
  
  small {
    font-size: 80%;
  }
  
  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }
  
  sub {
    bottom: -.25em;
  }
  
  sup {
    top: -.5em;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }
  
  a:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  
  a:not([href]):not([class]) {
    color: inherit;
    text-decoration: none;
  }
  
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
  }
  
  pre,
  code,
  kbd,
  samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em;
  }
  
  pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
  }
  
  figure {
    margin: 0 0 1rem;
  }
  
  img {
    vertical-align: middle;
    border-style: none;
  }
  
  svg {
    overflow: hidden;
    vertical-align: middle;
  }
  
  table {
    border-collapse: collapse;
  }
  
  caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom;
  }
  
  th {
    text-align: inherit;
    text-align: -webkit-match-parent;
  }
  
  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
  
  button {
    border-radius: 0;
  }
  
  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }
  
  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  
  button,
  input {
    overflow: visible;
  }
  
  button,
  select {
    text-transform: none;
  }
  
  [role="button"] {
    cursor: pointer;
  }
  
  select {
    word-wrap: normal;
  }
  
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }
  
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer;
  }
  
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }
  
  input[type="radio"],
  input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
  }
  
  textarea {
    overflow: auto;
    resize: vertical;
  }
  
  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }
  
  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
  }
  
  progress {
    vertical-align: baseline;
  }
  
  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }
  
  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }
  
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  
  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }
  
  output {
    display: inline-block;
  }
  
  summary {
    display: list-item;
    cursor: pointer;
  }
  
  template {
    display: none;
  }
  
  [hidden] {
    display: none !important;
  }
  
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }
  
  h1, .h1 {
    font-size: 2.5rem;
  }
  
  h2, .h2 {
    font-size: 1.875rem;
  }
  
  h3, .h3 {
    font-size: 1.5625rem;
  }
  
  h4, .h4 {
    font-size: 1rem;
  }
  
  h5, .h5 {
    font-size: 1.1rem;
  }
  
  h6, .h6 {
    font-size: 0.625rem;
  }
  
  .lead {
    font-size: 1.25rem;
    font-weight: 300;
  }
  
  .display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2;
  }
  
  .display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
  
  .display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
  
  .display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
  
  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  small,
  .small {
    font-size: 80%;
    font-weight: 400;
  }
  
  mark,
  .mark {
    padding: 0.2em;
    background-color: #fcf8e3;
  }
  
  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }
  
  .list-inline {
    padding-left: 0;
    list-style: none;
  }
  
  .list-inline-item {
    display: inline-block;
  }
  
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  
  .initialism {
    font-size: 90%;
    text-transform: uppercase;
  }
  
  .blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }
  
  .blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d;
  }
  
  .blockquote-footer::before {
    content: "\2014\00A0";
  }
  
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  
  .img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
  }
  
  .figure {
    display: inline-block;
  }
  
  .figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
  }
  
  .figure-caption {
    font-size: 90%;
    color: #6c757d;
  }
  
  code {
    font-size: 87.5%;
    color: #e83e8c;
    word-wrap: break-word;
  }
  
  a > code {
    color: inherit;
  }
  
  kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: 0.2rem;
  }
  
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
  }
  
  pre {
    display: block;
    font-size: 87.5%;
    color: #212529;
  }
  
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
  }
  
  .pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
  }
  
  .container,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  
  @media (min-width: 576px) {
    .container, .container-sm {
      max-width: 1680px;
    }
  }
  
  @media (min-width: 768px) {
    .container, .container-sm, .container-md {
      max-width: 1680px;
    }
  }
  
  @media (min-width: 1024px) {
    .container, .container-sm, .container-md, .container-lg {
      max-width: 1680px;
    }
  }
  
  @media (min-width: 1440px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
      max-width: 1680px;
    }
  }
  
  @media (min-width: 1680px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
      max-width: 1680px;
    }
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  
  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }
  
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
  
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
  .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
  .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
  .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
  .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
  .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
  .col-xxl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  
  .row-cols-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  
  .row-cols-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  
  .row-cols-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  
  .row-cols-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  
  .row-cols-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  
  .row-cols-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  
  .col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  
  .col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  
  .col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  
  .col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  
  .col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  
  .col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  
  .col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  
  .col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  
  .col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  
  .col-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  
  .col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  
  .col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  
  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  
  .order-first {
    order: -1;
  }
  
  .order-last {
    order: 13;
  }
  
  .order-0 {
    order: 0;
  }
  
  .order-1 {
    order: 1;
  }
  
  .order-2 {
    order: 2;
  }
  
  .order-3 {
    order: 3;
  }
  
  .order-4 {
    order: 4;
  }
  
  .order-5 {
    order: 5;
  }
  
  .order-6 {
    order: 6;
  }
  
  .order-7 {
    order: 7;
  }
  
  .order-8 {
    order: 8;
  }
  
  .order-9 {
    order: 9;
  }
  
  .order-10 {
    order: 10;
  }
  
  .order-11 {
    order: 11;
  }
  
  .order-12 {
    order: 12;
  }
  
  .offset-1 {
    margin-left: 8.33333%;
  }
  
  .offset-2 {
    margin-left: 16.66667%;
  }
  
  .offset-3 {
    margin-left: 25%;
  }
  
  .offset-4 {
    margin-left: 33.33333%;
  }
  
  .offset-5 {
    margin-left: 41.66667%;
  }
  
  .offset-6 {
    margin-left: 50%;
  }
  
  .offset-7 {
    margin-left: 58.33333%;
  }
  
  .offset-8 {
    margin-left: 66.66667%;
  }
  
  .offset-9 {
    margin-left: 75%;
  }
  
  .offset-10 {
    margin-left: 83.33333%;
  }
  
  .offset-11 {
    margin-left: 91.66667%;
  }
  
  @media (min-width: 576px) {
    .col-sm {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .row-cols-sm-1 > * {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .row-cols-sm-2 > * {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .row-cols-sm-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .row-cols-sm-4 > * {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .row-cols-sm-5 > * {
      flex: 0 0 20%;
      max-width: 20%;
    }
    .row-cols-sm-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-sm-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    .col-sm-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    .col-sm-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-sm-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-sm-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .col-sm-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .col-sm-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-sm-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    .col-sm-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    .col-sm-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-sm-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    .col-sm-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    .col-sm-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-sm-first {
      order: -1;
    }
    .order-sm-last {
      order: 13;
    }
    .order-sm-0 {
      order: 0;
    }
    .order-sm-1 {
      order: 1;
    }
    .order-sm-2 {
      order: 2;
    }
    .order-sm-3 {
      order: 3;
    }
    .order-sm-4 {
      order: 4;
    }
    .order-sm-5 {
      order: 5;
    }
    .order-sm-6 {
      order: 6;
    }
    .order-sm-7 {
      order: 7;
    }
    .order-sm-8 {
      order: 8;
    }
    .order-sm-9 {
      order: 9;
    }
    .order-sm-10 {
      order: 10;
    }
    .order-sm-11 {
      order: 11;
    }
    .order-sm-12 {
      order: 12;
    }
    .offset-sm-0 {
      margin-left: 0;
    }
    .offset-sm-1 {
      margin-left: 8.33333%;
    }
    .offset-sm-2 {
      margin-left: 16.66667%;
    }
    .offset-sm-3 {
      margin-left: 25%;
    }
    .offset-sm-4 {
      margin-left: 33.33333%;
    }
    .offset-sm-5 {
      margin-left: 41.66667%;
    }
    .offset-sm-6 {
      margin-left: 50%;
    }
    .offset-sm-7 {
      margin-left: 58.33333%;
    }
    .offset-sm-8 {
      margin-left: 66.66667%;
    }
    .offset-sm-9 {
      margin-left: 75%;
    }
    .offset-sm-10 {
      margin-left: 83.33333%;
    }
    .offset-sm-11 {
      margin-left: 91.66667%;
    }
  }
  
  @media (min-width: 768px) {
    .col-md {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .row-cols-md-1 > * {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .row-cols-md-2 > * {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .row-cols-md-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .row-cols-md-4 > * {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .row-cols-md-5 > * {
      flex: 0 0 20%;
      max-width: 20%;
    }
    .row-cols-md-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-md-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    .col-md-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    .col-md-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-md-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-md-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .col-md-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-md-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    .col-md-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    .col-md-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-md-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    .col-md-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    .col-md-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-md-first {
      order: -1;
    }
    .order-md-last {
      order: 13;
    }
    .order-md-0 {
      order: 0;
    }
    .order-md-1 {
      order: 1;
    }
    .order-md-2 {
      order: 2;
    }
    .order-md-3 {
      order: 3;
    }
    .order-md-4 {
      order: 4;
    }
    .order-md-5 {
      order: 5;
    }
    .order-md-6 {
      order: 6;
    }
    .order-md-7 {
      order: 7;
    }
    .order-md-8 {
      order: 8;
    }
    .order-md-9 {
      order: 9;
    }
    .order-md-10 {
      order: 10;
    }
    .order-md-11 {
      order: 11;
    }
    .order-md-12 {
      order: 12;
    }
    .offset-md-0 {
      margin-left: 0;
    }
    .offset-md-1 {
      margin-left: 8.33333%;
    }
    .offset-md-2 {
      margin-left: 16.66667%;
    }
    .offset-md-3 {
      margin-left: 25%;
    }
    .offset-md-4 {
      margin-left: 33.33333%;
    }
    .offset-md-5 {
      margin-left: 41.66667%;
    }
    .offset-md-6 {
      margin-left: 50%;
    }
    .offset-md-7 {
      margin-left: 58.33333%;
    }
    .offset-md-8 {
      margin-left: 66.66667%;
    }
    .offset-md-9 {
      margin-left: 75%;
    }
    .offset-md-10 {
      margin-left: 83.33333%;
    }
    .offset-md-11 {
      margin-left: 91.66667%;
    }
  }
  
  @media (min-width: 1024px) {
    .col-lg {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .row-cols-lg-1 > * {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .row-cols-lg-2 > * {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .row-cols-lg-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .row-cols-lg-4 > * {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .row-cols-lg-5 > * {
      flex: 0 0 20%;
      max-width: 20%;
    }
    .row-cols-lg-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-lg-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    .col-lg-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    .col-lg-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-lg-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-lg-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .col-lg-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .col-lg-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-lg-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    .col-lg-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    .col-lg-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-lg-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    .col-lg-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    .col-lg-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-lg-first {
      order: -1;
    }
    .order-lg-last {
      order: 13;
    }
    .order-lg-0 {
      order: 0;
    }
    .order-lg-1 {
      order: 1;
    }
    .order-lg-2 {
      order: 2;
    }
    .order-lg-3 {
      order: 3;
    }
    .order-lg-4 {
      order: 4;
    }
    .order-lg-5 {
      order: 5;
    }
    .order-lg-6 {
      order: 6;
    }
    .order-lg-7 {
      order: 7;
    }
    .order-lg-8 {
      order: 8;
    }
    .order-lg-9 {
      order: 9;
    }
    .order-lg-10 {
      order: 10;
    }
    .order-lg-11 {
      order: 11;
    }
    .order-lg-12 {
      order: 12;
    }
    .offset-lg-0 {
      margin-left: 0;
    }
    .offset-lg-1 {
      margin-left: 8.33333%;
    }
    .offset-lg-2 {
      margin-left: 16.66667%;
    }
    .offset-lg-3 {
      margin-left: 25%;
    }
    .offset-lg-4 {
      margin-left: 33.33333%;
    }
    .offset-lg-5 {
      margin-left: 41.66667%;
    }
    .offset-lg-6 {
      margin-left: 50%;
    }
    .offset-lg-7 {
      margin-left: 58.33333%;
    }
    .offset-lg-8 {
      margin-left: 66.66667%;
    }
    .offset-lg-9 {
      margin-left: 75%;
    }
    .offset-lg-10 {
      margin-left: 83.33333%;
    }
    .offset-lg-11 {
      margin-left: 91.66667%;
    }
  }
  
  @media (min-width: 1440px) {
    .col-xl {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .row-cols-xl-1 > * {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .row-cols-xl-2 > * {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .row-cols-xl-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .row-cols-xl-4 > * {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .row-cols-xl-5 > * {
      flex: 0 0 20%;
      max-width: 20%;
    }
    .row-cols-xl-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-xl-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    .col-xl-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    .col-xl-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-xl-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-xl-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .col-xl-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .col-xl-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-xl-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    .col-xl-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    .col-xl-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-xl-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    .col-xl-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    .col-xl-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-xl-first {
      order: -1;
    }
    .order-xl-last {
      order: 13;
    }
    .order-xl-0 {
      order: 0;
    }
    .order-xl-1 {
      order: 1;
    }
    .order-xl-2 {
      order: 2;
    }
    .order-xl-3 {
      order: 3;
    }
    .order-xl-4 {
      order: 4;
    }
    .order-xl-5 {
      order: 5;
    }
    .order-xl-6 {
      order: 6;
    }
    .order-xl-7 {
      order: 7;
    }
    .order-xl-8 {
      order: 8;
    }
    .order-xl-9 {
      order: 9;
    }
    .order-xl-10 {
      order: 10;
    }
    .order-xl-11 {
      order: 11;
    }
    .order-xl-12 {
      order: 12;
    }
    .offset-xl-0 {
      margin-left: 0;
    }
    .offset-xl-1 {
      margin-left: 8.33333%;
    }
    .offset-xl-2 {
      margin-left: 16.66667%;
    }
    .offset-xl-3 {
      margin-left: 25%;
    }
    .offset-xl-4 {
      margin-left: 33.33333%;
    }
    .offset-xl-5 {
      margin-left: 41.66667%;
    }
    .offset-xl-6 {
      margin-left: 50%;
    }
    .offset-xl-7 {
      margin-left: 58.33333%;
    }
    .offset-xl-8 {
      margin-left: 66.66667%;
    }
    .offset-xl-9 {
      margin-left: 75%;
    }
    .offset-xl-10 {
      margin-left: 83.33333%;
    }
    .offset-xl-11 {
      margin-left: 91.66667%;
    }
  }
  
  @media (min-width: 1680px) {
    .col-xxl {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .row-cols-xxl-1 > * {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .row-cols-xxl-2 > * {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .row-cols-xxl-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .row-cols-xxl-4 > * {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .row-cols-xxl-5 > * {
      flex: 0 0 20%;
      max-width: 20%;
    }
    .row-cols-xxl-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-xxl-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    .col-xxl-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    .col-xxl-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-xxl-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-xxl-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .col-xxl-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .col-xxl-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-xxl-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    .col-xxl-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    .col-xxl-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-xxl-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    .col-xxl-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    .col-xxl-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-xxl-first {
      order: -1;
    }
    .order-xxl-last {
      order: 13;
    }
    .order-xxl-0 {
      order: 0;
    }
    .order-xxl-1 {
      order: 1;
    }
    .order-xxl-2 {
      order: 2;
    }
    .order-xxl-3 {
      order: 3;
    }
    .order-xxl-4 {
      order: 4;
    }
    .order-xxl-5 {
      order: 5;
    }
    .order-xxl-6 {
      order: 6;
    }
    .order-xxl-7 {
      order: 7;
    }
    .order-xxl-8 {
      order: 8;
    }
    .order-xxl-9 {
      order: 9;
    }
    .order-xxl-10 {
      order: 10;
    }
    .order-xxl-11 {
      order: 11;
    }
    .order-xxl-12 {
      order: 12;
    }
    .offset-xxl-0 {
      margin-left: 0;
    }
    .offset-xxl-1 {
      margin-left: 8.33333%;
    }
    .offset-xxl-2 {
      margin-left: 16.66667%;
    }
    .offset-xxl-3 {
      margin-left: 25%;
    }
    .offset-xxl-4 {
      margin-left: 33.33333%;
    }
    .offset-xxl-5 {
      margin-left: 41.66667%;
    }
    .offset-xxl-6 {
      margin-left: 50%;
    }
    .offset-xxl-7 {
      margin-left: 58.33333%;
    }
    .offset-xxl-8 {
      margin-left: 66.66667%;
    }
    .offset-xxl-9 {
      margin-left: 75%;
    }
    .offset-xxl-10 {
      margin-left: 83.33333%;
    }
    .offset-xxl-11 {
      margin-left: 91.66667%;
    }
  }
  
  .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
  }
  
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }
  
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }
  
  .table tbody + tbody {
    border-top: 2px solid #dee2e6;
  }
  
  .table-sm th,
  .table-sm td {
    padding: 0.3rem;
  }
  
  .table-bordered {
    border: 1px solid #dee2e6;
  }
  
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6;
  }
  
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px;
  }
  
  .table-borderless th,
  .table-borderless td,
  .table-borderless thead th,
  .table-borderless tbody + tbody {
    border: 0;
  }
  
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .table-hover tbody tr:hover {
    color: #212529;
    background-color: rgba(0, 0, 0, 0.075);
  }
  
  .table-primary,
  .table-primary > th,
  .table-primary > td {
    background-color: #fef7c8;
  }
  
  .table-primary th,
  .table-primary td,
  .table-primary thead th,
  .table-primary tbody + tbody {
    border-color: #fcf198;
  }
  
  .table-hover .table-primary:hover {
    background-color: #fef3af;
  }
  
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #fef3af;
  }
  
  .table-secondary,
  .table-secondary > th,
  .table-secondary > td {
    background-color: #b8b8b8;
  }
  
  .table-secondary th,
  .table-secondary td,
  .table-secondary thead th,
  .table-secondary tbody + tbody {
    border-color: #7b7b7b;
  }
  
  .table-hover .table-secondary:hover {
    background-color: #ababab;
  }
  
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #ababab;
  }
  
  .table-success,
  .table-success > th,
  .table-success > td {
    background-color: #c3e6cb;
  }
  
  .table-success th,
  .table-success td,
  .table-success thead th,
  .table-success tbody + tbody {
    border-color: #8fd19e;
  }
  
  .table-hover .table-success:hover {
    background-color: #b1dfbb;
  }
  
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb;
  }
  
  .table-info,
  .table-info > th,
  .table-info > td {
    background-color: #bee5eb;
  }
  
  .table-info th,
  .table-info td,
  .table-info thead th,
  .table-info tbody + tbody {
    border-color: #86cfda;
  }
  
  .table-hover .table-info:hover {
    background-color: #abdde5;
  }
  
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5;
  }
  
  .table-warning,
  .table-warning > th,
  .table-warning > td {
    background-color: #ffeeba;
  }
  
  .table-warning th,
  .table-warning td,
  .table-warning thead th,
  .table-warning tbody + tbody {
    border-color: #ffdf7e;
  }
  
  .table-hover .table-warning:hover {
    background-color: #ffe8a1;
  }
  
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1;
  }
  
  .table-danger,
  .table-danger > th,
  .table-danger > td {
    background-color: #f5c6cb;
  }
  
  .table-danger th,
  .table-danger td,
  .table-danger thead th,
  .table-danger tbody + tbody {
    border-color: #ed969e;
  }
  
  .table-hover .table-danger:hover {
    background-color: #f1b0b7;
  }
  
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7;
  }
  
  .table-light,
  .table-light > th,
  .table-light > td {
    background-color: #fdfdfe;
  }
  
  .table-light th,
  .table-light td,
  .table-light thead th,
  .table-light tbody + tbody {
    border-color: #fbfcfc;
  }
  
  .table-hover .table-light:hover {
    background-color: #ececf6;
  }
  
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6;
  }
  
  .table-dark,
  .table-dark > th,
  .table-dark > td {
    background-color: #c7cacb;
  }
  
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #979c9f;
  }
  
  .table-hover .table-dark:hover {
    background-color: #babdbf;
  }
  
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #babdbf;
  }
  
  .table-blue,
  .table-blue > th,
  .table-blue > td {
    background-color: #b8daff;
  }
  
  .table-blue th,
  .table-blue td,
  .table-blue thead th,
  .table-blue tbody + tbody {
    border-color: #7abaff;
  }
  
  .table-hover .table-blue:hover {
    background-color: #9fcdff;
  }
  
  .table-hover .table-blue:hover > td,
  .table-hover .table-blue:hover > th {
    background-color: #9fcdff;
  }
  
  .table-active,
  .table-active > th,
  .table-active > td {
    background-color: rgba(0, 0, 0, 0.075);
  }
  
  .table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
  
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075);
  }
  
  .table .thead-dark th {
    color: #fff;
    background-color: #343a40;
    border-color: #454d55;
  }
  
  .table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
  }
  
  .table-dark {
    color: #fff;
    background-color: #343a40;
  }
  
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55;
  }
  
  .table-dark.table-bordered {
    border: 0;
  }
  
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
  }
  
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075);
  }
  
  @media (max-width: 575.98px) {
    .table-responsive-sm {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-sm > .table-bordered {
      border: 0;
    }
  }
  
  @media (max-width: 767.98px) {
    .table-responsive-md {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-md > .table-bordered {
      border: 0;
    }
  }
  
  @media (max-width: 1023.98px) {
    .table-responsive-lg {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-lg > .table-bordered {
      border: 0;
    }
  }
  
  @media (max-width: 1439.98px) {
    .table-responsive-xl {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-xl > .table-bordered {
      border: 0;
    }
  }
  
  @media (max-width: 1679.98px) {
    .table-responsive-xxl {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-xxl > .table-bordered {
      border: 0;
    }
  }
  
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  .table-responsive > .table-bordered {
    border: 0;
  }
  
  .form-control {
    display: block;
    width: 100%;
    height: calc(1.6875rem + 2rem + 2px);
    padding: 1rem 1.125rem;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #374047;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(55, 64, 71, 0.3);
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none;
    }
  }
  
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0;
  }
  
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #374047;
  }
  
  .form-control:focus {
    color: #495057;
    background-color: rgba(55, 64, 71, 0.1);
    border-color: rgba(250, 228, 57, 0.5);
    outline: 0;
    box-shadow: 0;
  }
  
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1;
  }
  
  .form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
  }
  
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1;
  }
  
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
  }
  
  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    -webkit-appearance: none;
            appearance: none;
  }
  
  select.form-control:focus::-ms-value {
    color: #374047;
    background-color: #fff;
  }
  
  .form-control-file,
  .form-control-range {
    display: block;
    width: 100%;
  }
  
  .col-form-label {
    padding-top: calc(1rem + 1px);
    padding-bottom: calc(1rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
  }
  
  .col-form-label-lg {
    padding-top: calc(1.25rem + 1px);
    padding-bottom: calc(1.25rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5;
  }
  
  .col-form-label-sm {
    padding-top: calc(0.875rem + 1px);
    padding-bottom: calc(0.875rem + 1px);
    font-size: 0.875rem;
    line-height: 1.5;
  }
  
  .form-control-plaintext {
    display: block;
    width: 100%;
    padding: 1rem 0;
    margin-bottom: 0;
    font-size: 1.125rem;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
  }
  
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0;
  }
  
  .form-control-sm {
    height: calc(1.3125rem + 1.75rem + 2px);
    padding: 0.875rem 1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  
  .form-control-lg {
    height: calc(1.875rem + 2.5rem + 2px);
    padding: 1.25rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
  }
  
  select.form-control[size], select.form-control[multiple] {
    height: auto;
  }
  
  textarea.form-control {
    height: auto;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-text {
    display: block;
    margin-top: 0.25rem;
  }
  
  .form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
  }
  
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  
  .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
  }
  
  .form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
  }
  
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d;
  }
  
  .form-check-label {
    margin-bottom: 0;
  }
  
  .form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
  }
  
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
  }
  
  .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #28a745;
  }
  
  .valid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(40, 167, 69, 0.9);
    border-radius: 0.25rem;
  }
  
  .was-validated :valid ~ .valid-feedback,
  .was-validated :valid ~ .valid-tooltip,
  .is-valid ~ .valid-feedback,
  .is-valid ~ .valid-tooltip {
    display: block;
  }
  
  .was-validated .form-control:valid, .form-control.is-valid {
    border-color: #28a745;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
  
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25);
  }
  
  .was-validated textarea.form-control:valid, textarea.form-control.is-valid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
  }
  
  .was-validated .custom-select:valid, .custom-select.is-valid {
    border-color: #28a745;
    padding-right: calc(0.75em + 2.3125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
  
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25);
  }
  
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #28a745;
  }
  
  .was-validated .form-check-input:valid ~ .valid-feedback,
  .was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
  .form-check-input.is-valid ~ .valid-tooltip {
    display: block;
  }
  
  .was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
    color: #28a745;
  }
  
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745;
  }
  
  .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
    border-color: #34ce57;
    background-color: #34ce57;
  }
  
  .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25);
  }
  
  .was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #28a745;
  }
  
  .was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
    border-color: #28a745;
  }
  
  .was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
    border-color: #28a745;
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25);
  }
  
  .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
  
  .invalid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.9);
    border-radius: 0.25rem;
  }
  
  .was-validated :invalid ~ .invalid-feedback,
  .was-validated :invalid ~ .invalid-tooltip,
  .is-invalid ~ .invalid-feedback,
  .is-invalid ~ .invalid-tooltip {
    display: block;
  }
  
  .was-validated .form-control:invalid, .form-control.is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
  
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25);
  }
  
  .was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
  }
  
  .was-validated .custom-select:invalid, .custom-select.is-invalid {
    border-color: #dc3545;
    padding-right: calc(0.75em + 2.3125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
  
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25);
  }
  
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #dc3545;
  }
  
  .was-validated .form-check-input:invalid ~ .invalid-feedback,
  .was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
  .form-check-input.is-invalid ~ .invalid-tooltip {
    display: block;
  }
  
  .was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
    color: #dc3545;
  }
  
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545;
  }
  
  .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
    border-color: #e4606d;
    background-color: #e4606d;
  }
  
  .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25);
  }
  
  .was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #dc3545;
  }
  
  .was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
    border-color: #dc3545;
  }
  
  .was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
    border-color: #dc3545;
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25);
  }
  
  .form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
  
  .form-inline .form-check {
    width: 100%;
  }
  
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
    }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0;
    }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }
    .form-inline .form-control-plaintext {
      display: inline-block;
    }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto;
    }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0;
    }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0;
    }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center;
    }
    .form-inline .custom-control-label {
      margin-bottom: 0;
    }
  }
  
  .btn {
    display: inline-block;
    font-weight: 700;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: transparent;
    border: 0 solid transparent;
    padding: 1rem 1.5rem;
    font-size: 1.125rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none;
    }
  }
  
  .btn:hover {
    color: #212529;
    text-decoration: none;
  }
  
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0;
  }
  
  .btn.disabled, .btn:disabled {
    opacity: 0.65;
  }
  
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  
  a.btn.disabled,
  fieldset:disabled a.btn {
    pointer-events: none;
  }
  
  .btn-primary {
    color: #212529;
    background-color: #fae439;
    border-color: #fae439;
  }
  
  .btn-primary:hover {
    color: #212529;
    background-color: #f9df14;
    border-color: #f9dd07;
  }
  
  .btn-primary:focus, .btn-primary.focus {
    color: #212529;
    background-color: #f9df14;
    border-color: #f9dd07;
    box-shadow: 0 0 0 0 rgba(217, 199, 55, 0.5);
  }
  
  .btn-primary.disabled, .btn-primary:disabled {
    color: #212529;
    background-color: #fae439;
    border-color: #fae439;
  }
  
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #212529;
    background-color: #f9dd07;
    border-color: #edd306;
  }
  
  .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(217, 199, 55, 0.5);
  }
  
  .btn-secondary {
    color: #fff;
    background-color: #010101;
    border-color: #010101;
  }
  
  .btn-secondary:hover {
    color: #fff;
    background-color: black;
    border-color: black;
  }
  
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: black;
    border-color: black;
    box-shadow: 0 0 0 0 rgba(39, 39, 39, 0.5);
  }
  
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #010101;
    border-color: #010101;
  }
  
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black;
  }
  
  .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(39, 39, 39, 0.5);
  }
  
  .btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
  }
  
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0 rgba(72, 180, 97, 0.5);
  }
  
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430;
  }
  
  .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
  .show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(72, 180, 97, 0.5);
  }
  
  .btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
  }
  
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5);
  }
  
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f;
  }
  
  .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
  .show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5);
  }
  
  .btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
  }
  
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0 rgba(222, 170, 12, 0.5);
  }
  
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500;
  }
  
  .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
  .show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(222, 170, 12, 0.5);
  }
  
  .btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
  }
  
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5);
  }
  
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d;
  }
  
  .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
  .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5);
  }
  
  .btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
  
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
  }
  
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0 rgba(216, 217, 219, 0.5);
  }
  
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
  
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df;
  }
  
  .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
  .show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(216, 217, 219, 0.5);
  }
  
  .btn-dark {
    color: #fff;
    background-color: #374047;
    border-color: #374047;
  }
  
  .btn-dark:hover {
    color: #fff;
    background-color: #262d31;
    border-color: #21262a;
  }
  
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #262d31;
    border-color: #21262a;
    box-shadow: 0 0 0 0 rgba(85, 93, 99, 0.5);
  }
  
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #374047;
    border-color: #374047;
  }
  
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #21262a;
    border-color: #1b2023;
  }
  
  .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
  .show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(85, 93, 99, 0.5);
  }
  
  .btn-blue {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-blue:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
  }
  
  .btn-blue:focus, .btn-blue.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0 rgba(38, 143, 255, 0.5);
  }
  
  .btn-blue.disabled, .btn-blue:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active,
  .show > .btn-blue.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
  }
  
  .btn-blue:not(:disabled):not(.disabled):active:focus, .btn-blue:not(:disabled):not(.disabled).active:focus,
  .show > .btn-blue.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(38, 143, 255, 0.5);
  }
  
  .btn-outline-primary {
    color: #fae439;
    border-color: #fae439;
  }
  
  .btn-outline-primary:hover {
    color: #212529;
    background-color: #fae439;
    border-color: #fae439;
  }
  
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0 rgba(250, 228, 57, 0.5);
  }
  
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #fae439;
    background-color: transparent;
  }
  
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #fae439;
    border-color: #fae439;
  }
  
  .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(250, 228, 57, 0.5);
  }
  
  .btn-outline-secondary {
    color: #010101;
    border-color: #010101;
  }
  
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #010101;
    border-color: #010101;
  }
  
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0 rgba(1, 1, 1, 0.5);
  }
  
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #010101;
    background-color: transparent;
  }
  
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #010101;
    border-color: #010101;
  }
  
  .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(1, 1, 1, 0.5);
  }
  
  .btn-outline-success {
    color: #28a745;
    border-color: #28a745;
  }
  
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5);
  }
  
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent;
  }
  
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5);
  }
  
  .btn-outline-info {
    color: #17a2b8;
    border-color: #17a2b8;
  }
  
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5);
  }
  
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent;
  }
  
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  
  .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5);
  }
  
  .btn-outline-warning {
    color: #ffc107;
    border-color: #ffc107;
  }
  
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5);
  }
  
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent;
  }
  
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  
  .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5);
  }
  
  .btn-outline-danger {
    color: #dc3545;
    border-color: #dc3545;
  }
  
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5);
  }
  
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent;
  }
  
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5);
  }
  
  .btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa;
  }
  
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
  
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0 rgba(248, 249, 250, 0.5);
  }
  
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent;
  }
  
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
  
  .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(248, 249, 250, 0.5);
  }
  
  .btn-outline-dark {
    color: #374047;
    border-color: #374047;
  }
  
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #374047;
    border-color: #374047;
  }
  
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0 rgba(55, 64, 71, 0.5);
  }
  
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #374047;
    background-color: transparent;
  }
  
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #374047;
    border-color: #374047;
  }
  
  .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(55, 64, 71, 0.5);
  }
  
  .btn-outline-blue {
    color: #007bff;
    border-color: #007bff;
  }
  
  .btn-outline-blue:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-outline-blue:focus, .btn-outline-blue.focus {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.5);
  }
  
  .btn-outline-blue.disabled, .btn-outline-blue:disabled {
    color: #007bff;
    background-color: transparent;
  }
  
  .btn-outline-blue:not(:disabled):not(.disabled):active, .btn-outline-blue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-blue.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-outline-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-blue:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-blue.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.5);
  }
  
  .btn-link {
    font-weight: 400;
    color: #007bff;
    text-decoration: none;
  }
  
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
  }
  
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none;
  }
  
  .btn-lg, .btn-group-lg > .btn {
    padding: 1.25rem 2rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
  }
  
  .btn-sm, .btn-group-sm > .btn {
    padding: 0.875rem 1.25rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  
  .btn-block {
    display: block;
    width: 100%;
  }
  
  .btn-block + .btn-block {
    margin-top: 0.5rem;
  }
  
  input[type="submit"].btn-block,
  input[type="reset"].btn-block,
  input[type="button"].btn-block {
    width: 100%;
  }
  
  .fade {
    transition: opacity 0.15s linear;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none;
    }
  }
  
  .fade:not(.show) {
    opacity: 0;
  }
  
  .collapse:not(.show) {
    display: none;
  }
  
  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none;
    }
  }
  
  .dropup,
  .dropright,
  .dropdown,
  .dropleft {
    position: relative;
  }
  
  .dropdown-toggle {
    white-space: nowrap;
  }
  
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  
  .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0 0;
    margin: 0.125rem 0 0;
    font-size: 1.5625rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
  }
  
  .dropdown-menu-left {
    right: auto;
    left: 0;
  }
  
  .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  
  @media (min-width: 576px) {
    .dropdown-menu-sm-left {
      right: auto;
      left: 0;
    }
    .dropdown-menu-sm-right {
      right: 0;
      left: auto;
    }
  }
  
  @media (min-width: 768px) {
    .dropdown-menu-md-left {
      right: auto;
      left: 0;
    }
    .dropdown-menu-md-right {
      right: 0;
      left: auto;
    }
  }
  
  @media (min-width: 1024px) {
    .dropdown-menu-lg-left {
      right: auto;
      left: 0;
    }
    .dropdown-menu-lg-right {
      right: 0;
      left: auto;
    }
  }
  
  @media (min-width: 1440px) {
    .dropdown-menu-xl-left {
      right: auto;
      left: 0;
    }
    .dropdown-menu-xl-right {
      right: 0;
      left: auto;
    }
  }
  
  @media (min-width: 1680px) {
    .dropdown-menu-xxl-left {
      right: auto;
      left: 0;
    }
    .dropdown-menu-xxl-right {
      right: 0;
      left: auto;
    }
  }
  
  .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
  }
  
  .dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
  }
  
  .dropup .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  
  .dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
  }
  
  .dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
  }
  
  .dropright .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  
  .dropright .dropdown-toggle::after {
    vertical-align: 0;
  }
  
  .dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
  }
  
  .dropleft .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
  }
  
  .dropleft .dropdown-toggle::after {
    display: none;
  }
  
  .dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
  }
  
  .dropleft .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  
  .dropleft .dropdown-toggle::before {
    vertical-align: 0;
  }
  
  .dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
    right: auto;
    bottom: auto;
  }
  
  .dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
  }
  
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.33333rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #000;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  
  .dropdown-item:first-child {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
  }
  
  .dropdown-item:last-child {
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
  }
  
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
  }
  
  .dropdown-item.active, .dropdown-item:active {
    color: #000;
    text-decoration: none;
    background-color: #fae439;
  }
  
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent;
  }
  
  .dropdown-menu.show {
    display: block;
  }
  
  .dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #6c757d;
    white-space: nowrap;
  }
  
  .dropdown-item-text {
    display: block;
    padding: 0.33333rem 1.5rem;
    color: #000;
  }
  
  .btn-group,
  .btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
  }
  
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto;
  }
  
  .btn-group > .btn:hover,
  .btn-group-vertical > .btn:hover {
    z-index: 1;
  }
  
  .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1;
  }
  
  .btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  
  .btn-toolbar .input-group {
    width: auto;
  }
  
  .btn-group > .btn:not(:first-child),
  .btn-group > .btn-group:not(:first-child) {
    margin-left: 0;
  }
  
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .btn-group > .btn:not(:first-child),
  .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .dropdown-toggle-split {
    padding-right: 1.125rem;
    padding-left: 1.125rem;
  }
  
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0;
  }
  
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0;
  }
  
  .btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
  
  .btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  
  .btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%;
  }
  
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: 0;
  }
  
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .btn-group-toggle > .btn,
  .btn-group-toggle > .btn-group > .btn {
    margin-bottom: 0;
  }
  
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
  }
  
  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
  }
  
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
  }
  
  .input-group > .form-control + .form-control,
  .input-group > .form-control + .custom-select,
  .input-group > .form-control + .custom-file,
  .input-group > .form-control-plaintext + .form-control,
  .input-group > .form-control-plaintext + .custom-select,
  .input-group > .form-control-plaintext + .custom-file,
  .input-group > .custom-select + .form-control,
  .input-group > .custom-select + .custom-select,
  .input-group > .custom-select + .custom-file,
  .input-group > .custom-file + .form-control,
  .input-group > .custom-file + .custom-select,
  .input-group > .custom-file + .custom-file {
    margin-left: -1px;
  }
  
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3;
  }
  
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4;
  }
  
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .input-group > .custom-file {
    display: flex;
    align-items: center;
  }
  
  .input-group > .custom-file:not(:last-child) .custom-file-label,
  .input-group > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .input-group > .custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .input-group-prepend,
  .input-group-append {
    display: flex;
  }
  
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2;
  }
  
  .input-group-prepend .btn:focus,
  .input-group-append .btn:focus {
    z-index: 3;
  }
  
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px;
  }
  
  .input-group-prepend {
    margin-right: -1px;
  }
  
  .input-group-append {
    margin-left: -1px;
  }
  
  .input-group-text {
    display: flex;
    align-items: center;
    padding: 1rem 1.125rem;
    margin-bottom: 0;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0;
  }
  
  .input-group-lg > .form-control:not(textarea),
  .input-group-lg > .custom-select {
    height: calc(1.875rem + 2.5rem + 2px);
  }
  
  .input-group-lg > .form-control,
  .input-group-lg > .custom-select,
  .input-group-lg > .input-group-prepend > .input-group-text,
  .input-group-lg > .input-group-append > .input-group-text,
  .input-group-lg > .input-group-prepend > .btn,
  .input-group-lg > .input-group-append > .btn {
    padding: 1.25rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
  }
  
  .input-group-sm > .form-control:not(textarea),
  .input-group-sm > .custom-select {
    height: calc(1.3125rem + 1.75rem + 2px);
  }
  
  .input-group-sm > .form-control,
  .input-group-sm > .custom-select,
  .input-group-sm > .input-group-prepend > .input-group-text,
  .input-group-sm > .input-group-append > .input-group-text,
  .input-group-sm > .input-group-prepend > .btn,
  .input-group-sm > .input-group-append > .btn {
    padding: 0.875rem 1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  
  .input-group-lg > .custom-select,
  .input-group-sm > .custom-select {
    padding-right: 1.75rem;
  }
  
  .input-group > .input-group-prepend > .btn,
  .input-group > .input-group-prepend > .input-group-text,
  .input-group > .input-group-append:not(:last-child) > .btn,
  .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .input-group > .input-group-append > .btn,
  .input-group > .input-group-append > .input-group-text,
  .input-group > .input-group-prepend:not(:first-child) > .btn,
  .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
  .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    -webkit-print-color-adjust: exact;
            color-adjust: exact;
  }
  
  .custom-control-inline {
    display: inline-flex;
    margin-right: 1rem;
  }
  
  .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
  }
  
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
  }
  
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff;
  }
  
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff;
  }
  
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d;
  }
  
  .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
    background-color: #e9ecef;
  }
  
  .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
  }
  
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
  }
  
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%;
  }
  
  .custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
  }
  
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
  }
  
  .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    border-color: #007bff;
    background-color: #007bff;
  }
  
  .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
  }
  
  .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
  }
  
  .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
  }
  
  .custom-radio .custom-control-label::before {
    border-radius: 50%;
  }
  
  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  }
  
  .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
  }
  
  .custom-switch {
    padding-left: 2.25rem;
  }
  
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem;
  }
  
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .custom-switch .custom-control-label::after {
      transition: none;
    }
  }
  
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem);
  }
  
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
  }
  
  .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
            appearance: none;
  }
  
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .custom-select:focus::-ms-value {
    color: #374047;
    background-color: #fff;
  }
  
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none;
  }
  
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef;
  }
  
  .custom-select::-ms-expand {
    display: none;
  }
  
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057;
  }
  
  .custom-select-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.875rem;
  }
  
  .custom-select-lg {
    height: calc(1.5em + 1rem + 2px);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1.25rem;
  }
  
  .custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin-bottom: 0;
  }
  
  .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin: 0;
    opacity: 0;
  }
  
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef;
  }
  
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse";
  }
  
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse);
  }
  
  .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0;
  }
  
  .custom-range {
    width: 100%;
    height: 1.4rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
            appearance: none;
  }
  
  .custom-range:focus {
    outline: none;
  }
  
  .custom-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .custom-range::-moz-focus-outer {
    border: 0;
  }
  
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .custom-range::-webkit-slider-thumb {
      -webkit-transition: none;
      transition: none;
    }
  }
  
  .custom-range::-webkit-slider-thumb:active {
    background-color: #b3d7ff;
  }
  
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
  }
  
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .custom-range::-moz-range-thumb {
      -moz-transition: none;
      transition: none;
    }
  }
  
  .custom-range::-moz-range-thumb:active {
    background-color: #b3d7ff;
  }
  
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
  }
  
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .custom-range::-ms-thumb {
      -ms-transition: none;
      transition: none;
    }
  }
  
  .custom-range::-ms-thumb:active {
    background-color: #b3d7ff;
  }
  
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
  }
  
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem;
  }
  
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem;
  }
  
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd;
  }
  
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default;
  }
  
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd;
  }
  
  .custom-range:disabled::-moz-range-track {
    cursor: default;
  }
  
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd;
  }
  
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none;
    }
  }
  
  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  
  .nav-link {
    display: block;
    padding: 0 0;
  }
  
  .nav-link:hover, .nav-link:focus {
    text-decoration: none;
  }
  
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default;
  }
  
  .nav-tabs {
    border-bottom: 1px solid #dee2e6;
  }
  
  .nav-tabs .nav-item {
    margin-bottom: -1px;
  }
  
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  
  .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
  }
  
  .nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
  }
  
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
  }
  
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .nav-pills .nav-link {
    border-radius: 0.25rem;
  }
  
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #007bff;
  }
  
  .nav-fill > .nav-link,
  .nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }
  
  .nav-justified > .nav-link,
  .nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
  
  .tab-content > .tab-pane {
    display: none;
  }
  
  .tab-content > .active {
    display: block;
  }
  
  .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 0;
  }
  
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl, .navbar .container-xxl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  
  .navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 0;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
  }
  
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none;
  }
  
  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  
  .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  
  .navbar-text {
    display: inline-block;
    padding-top: 0;
    padding-bottom: 0;
  }
  
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
  }
  
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none;
  }
  
  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
  }
  
  @media (max-width: 575.98px) {
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-xxl {
      padding-right: 0;
      padding-left: 0;
    }
  }
  
  @media (min-width: 576px) {
    .navbar-expand-sm {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-sm .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-xxl {
      flex-wrap: nowrap;
    }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-sm .navbar-toggler {
      display: none;
    }
  }
  
  @media (max-width: 767.98px) {
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl, .navbar-expand-md > .container-xxl {
      padding-right: 0;
      padding-left: 0;
    }
  }
  
  @media (min-width: 768px) {
    .navbar-expand-md {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-md .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-md .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl, .navbar-expand-md > .container-xxl {
      flex-wrap: nowrap;
    }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-md .navbar-toggler {
      display: none;
    }
  }
  
  @media (max-width: 1023.98px) {
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-xxl {
      padding-right: 0;
      padding-left: 0;
    }
  }
  
  @media (min-width: 1024px) {
    .navbar-expand-lg {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-xxl {
      flex-wrap: nowrap;
    }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-lg .navbar-toggler {
      display: none;
    }
  }
  
  @media (max-width: 1439.98px) {
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-xxl {
      padding-right: 0;
      padding-left: 0;
    }
  }
  
  @media (min-width: 1440px) {
    .navbar-expand-xl {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-xxl {
      flex-wrap: nowrap;
    }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-xl .navbar-toggler {
      display: none;
    }
  }
  
  @media (max-width: 1679.98px) {
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl, .navbar-expand-xxl > .container-xxl {
      padding-right: 0;
      padding-left: 0;
    }
  }
  
  @media (min-width: 1680px) {
    .navbar-expand-xxl {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-xxl .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-xxl .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl, .navbar-expand-xxl > .container-xxl {
      flex-wrap: nowrap;
    }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-xxl .navbar-toggler {
      display: none;
    }
  }
  
  .navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl, .navbar-expand > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
  
  .navbar-expand .navbar-nav {
    flex-direction: row;
  }
  
  .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
  }
  
  .navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl, .navbar-expand > .container-xxl {
    flex-wrap: nowrap;
  }
  
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  
  .navbar-expand .navbar-toggler {
    display: none;
  }
  
  .navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5);
  }
  
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7);
  }
  
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
  }
  
  .navbar-light .navbar-nav .show > .nav-link,
  .navbar-light .navbar-nav .active > .nav-link,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
  }
  
  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
  
  .navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5);
  }
  
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-dark .navbar-brand {
    color: #fff;
  }
  
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff;
  }
  
  .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
  }
  
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
  }
  
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
  }
  
  .navbar-dark .navbar-nav .show > .nav-link,
  .navbar-dark .navbar-nav .active > .nav-link,
  .navbar-dark .navbar-nav .nav-link.show,
  .navbar-dark .navbar-nav .nav-link.active {
    color: #fff;
  }
  
  .navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
  }
  
  .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
  
  .navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5);
  }
  
  .navbar-dark .navbar-text a {
    color: #fff;
  }
  
  .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
    color: #fff;
  }
  
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
  }
  
  .card > hr {
    margin-right: 0;
    margin-left: 0;
  }
  
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit;
  }
  
  .card > .list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
  }
  
  .card > .list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
  }
  
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0;
  }
  
  .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
  }
  
  .card-title {
    margin-bottom: 0.75rem;
  }
  
  .card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
  }
  
  .card-text:last-child {
    margin-bottom: 0;
  }
  
  .card-link:hover {
    text-decoration: none;
  }
  
  .card-link + .card-link {
    margin-left: 1.25rem;
  }
  
  .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  }
  
  .card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
  
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
  }
  
  .card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
  }
  
  .card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
  
  .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
    border-radius: calc(0.25rem - 1px);
  }
  
  .card-img,
  .card-img-top,
  .card-img-bottom {
    flex-shrink: 0;
    width: 100%;
  }
  
  .card-img,
  .card-img-top {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
  }
  
  .card-img,
  .card-img-bottom {
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
  }
  
  .card-deck .card {
    margin-bottom: 15px;
  }
  
  @media (min-width: 576px) {
    .card-deck {
      display: flex;
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px;
    }
    .card-deck .card {
      flex: 1 0;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px;
    }
  }
  
  .card-group > .card {
    margin-bottom: 15px;
  }
  
  @media (min-width: 576px) {
    .card-group {
      display: flex;
      flex-flow: row wrap;
    }
    .card-group > .card {
      flex: 1 0;
      margin-bottom: 0;
    }
    .card-group > .card + .card {
      margin-left: 0;
      border-left: 0;
    }
    .card-group > .card:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-img-top,
    .card-group > .card:not(:last-child) .card-header {
      border-top-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-img-bottom,
    .card-group > .card:not(:last-child) .card-footer {
      border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-img-top,
    .card-group > .card:not(:first-child) .card-header {
      border-top-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-img-bottom,
    .card-group > .card:not(:first-child) .card-footer {
      border-bottom-left-radius: 0;
    }
  }
  
  .card-columns .card {
    margin-bottom: 0.75rem;
  }
  
  @media (min-width: 576px) {
    .card-columns {
      -webkit-column-count: 3;
              column-count: 3;
      -webkit-column-gap: 1.25rem;
              grid-column-gap: 1.25rem;
              column-gap: 1.25rem;
      orphans: 1;
      widows: 1;
    }
    .card-columns .card {
      display: inline-block;
      width: 100%;
    }
  }
  
  .accordion {
    overflow-anchor: none;
  }
  
  .accordion > .card {
    overflow: hidden;
  }
  
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px;
  }
  
  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: 0.25rem;
  }
  
  .breadcrumb-item {
    display: flex;
  }
  
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem;
  }
  
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/";
  }
  
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline;
  }
  
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none;
  }
  
  .breadcrumb-item.active {
    color: #6c757d;
  }
  
  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
  }
  
  .page-link {
    position: relative;
    display: block;
    padding: 0.625rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #1E282E;
    background-color: #fff;
    border: 1px solid #F0F2F7;
  }
  
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
  }
  
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  
  .page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  
  .page-item.active .page-link {
    z-index: 3;
    color: #1E282E;
    background-color: #F0F2F7;
    border-color: #F0F2F7;
  }
  
  .page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
  }
  
  .pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
  }
  
  .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
  }
  
  .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
  
  .pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }
  
  .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
  }
  
  .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }
  
  .badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none;
    }
  }
  
  a.badge:hover, a.badge:focus {
    text-decoration: none;
  }
  
  .badge:empty {
    display: none;
  }
  
  .btn .badge {
    position: relative;
    top: -1px;
  }
  
  .badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
  }
  
  .badge-primary {
    color: #212529;
    background-color: #fae439;
  }
  
  a.badge-primary:hover, a.badge-primary:focus {
    color: #212529;
    background-color: #f9dd07;
  }
  
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(250, 228, 57, 0.5);
  }
  
  .badge-secondary {
    color: #fff;
    background-color: #010101;
  }
  
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: black;
  }
  
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(1, 1, 1, 0.5);
  }
  
  .badge-success {
    color: #fff;
    background-color: #28a745;
  }
  
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34;
  }
  
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  }
  
  .badge-info {
    color: #fff;
    background-color: #17a2b8;
  }
  
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b;
  }
  
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  }
  
  .badge-warning {
    color: #212529;
    background-color: #ffc107;
  }
  
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00;
  }
  
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  }
  
  .badge-danger {
    color: #fff;
    background-color: #dc3545;
  }
  
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130;
  }
  
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  }
  
  .badge-light {
    color: #212529;
    background-color: #f8f9fa;
  }
  
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5;
  }
  
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  }
  
  .badge-dark {
    color: #fff;
    background-color: #374047;
  }
  
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #21262a;
  }
  
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(55, 64, 71, 0.5);
  }
  
  .badge-blue {
    color: #fff;
    background-color: #007bff;
  }
  
  a.badge-blue:hover, a.badge-blue:focus {
    color: #fff;
    background-color: #0062cc;
  }
  
  a.badge-blue:focus, a.badge-blue.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  }
  
  .jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: 0.3rem;
  }
  
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem;
    }
  }
  
  .jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
  }
  
  .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  
  .alert-heading {
    color: inherit;
  }
  
  .alert-link {
    font-weight: 700;
  }
  
  .alert-dismissible {
    padding-right: 4rem;
  }
  
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit;
  }
  
  .alert-primary {
    color: #82771e;
    background-color: #fefad7;
    border-color: #fef7c8;
  }
  
  .alert-primary hr {
    border-top-color: #fef3af;
  }
  
  .alert-primary .alert-link {
    color: #595114;
  }
  
  .alert-secondary {
    color: #010101;
    background-color: #cccccc;
    border-color: #b8b8b8;
  }
  
  .alert-secondary hr {
    border-top-color: #ababab;
  }
  
  .alert-secondary .alert-link {
    color: black;
  }
  
  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
  
  .alert-success hr {
    border-top-color: #b1dfbb;
  }
  
  .alert-success .alert-link {
    color: #0b2e13;
  }
  
  .alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
  }
  
  .alert-info hr {
    border-top-color: #abdde5;
  }
  
  .alert-info .alert-link {
    color: #062c33;
  }
  
  .alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
  
  .alert-warning hr {
    border-top-color: #ffe8a1;
  }
  
  .alert-warning .alert-link {
    color: #533f03;
  }
  
  .alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
  
  .alert-danger hr {
    border-top-color: #f1b0b7;
  }
  
  .alert-danger .alert-link {
    color: #491217;
  }
  
  .alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe;
  }
  
  .alert-light hr {
    border-top-color: #ececf6;
  }
  
  .alert-light .alert-link {
    color: #686868;
  }
  
  .alert-dark {
    color: #1d2125;
    background-color: #d7d9da;
    border-color: #c7cacb;
  }
  
  .alert-dark hr {
    border-top-color: #babdbf;
  }
  
  .alert-dark .alert-link {
    color: #070808;
  }
  
  .alert-blue {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
  }
  
  .alert-blue hr {
    border-top-color: #9fcdff;
  }
  
  .alert-blue .alert-link {
    color: #002752;
  }
  
  @-webkit-keyframes progress-bar-stripes {
    from {
      background-position: 1rem 0;
    }
    to {
      background-position: 0 0;
    }
  }
  
  @keyframes progress-bar-stripes {
    from {
      background-position: 1rem 0;
    }
    to {
      background-position: 0 0;
    }
  }
  
  .progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
  }
  
  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #007bff;
    transition: width 0.6s ease;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none;
    }
  }
  
  .progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
  }
  
  .progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
            animation: progress-bar-stripes 1s linear infinite;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none;
    }
  }
  
  .media {
    display: flex;
    align-items: flex-start;
  }
  
  .media-body {
    flex: 1 1;
  }
  
  .list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0.25rem;
  }
  
  .list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit;
  }
  
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa;
  }
  
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef;
  }
  
  .list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
  
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
  
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }
  
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff;
  }
  
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .list-group-item + .list-group-item {
    border-top-width: 0;
  }
  
  .list-group-item + .list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px;
  }
  
  .list-group-horizontal {
    flex-direction: row;
  }
  
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0;
  }
  
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  
  .list-group-horizontal > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
  
  @media (min-width: 576px) {
    .list-group-horizontal-sm {
      flex-direction: row;
    }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
    }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0;
    }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
    }
    .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
    }
  }
  
  @media (min-width: 768px) {
    .list-group-horizontal-md {
      flex-direction: row;
    }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
    }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0;
    }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
    }
    .list-group-horizontal-md > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
    }
  }
  
  @media (min-width: 1024px) {
    .list-group-horizontal-lg {
      flex-direction: row;
    }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
    }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0;
    }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
    }
    .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
    }
  }
  
  @media (min-width: 1440px) {
    .list-group-horizontal-xl {
      flex-direction: row;
    }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
    }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0;
    }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
    }
    .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
    }
  }
  
  @media (min-width: 1680px) {
    .list-group-horizontal-xxl {
      flex-direction: row;
    }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
    }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0;
    }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
    }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
    }
  }
  
  .list-group-flush {
    border-radius: 0;
  }
  
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px;
  }
  
  .list-group-flush > .list-group-item:last-child {
    border-bottom-width: 0;
  }
  
  .list-group-item-primary {
    color: #82771e;
    background-color: #fef7c8;
  }
  
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #82771e;
    background-color: #fef3af;
  }
  
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #82771e;
    border-color: #82771e;
  }
  
  .list-group-item-secondary {
    color: #010101;
    background-color: #b8b8b8;
  }
  
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #010101;
    background-color: #ababab;
  }
  
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #010101;
    border-color: #010101;
  }
  
  .list-group-item-success {
    color: #155724;
    background-color: #c3e6cb;
  }
  
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb;
  }
  
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724;
  }
  
  .list-group-item-info {
    color: #0c5460;
    background-color: #bee5eb;
  }
  
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5;
  }
  
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460;
  }
  
  .list-group-item-warning {
    color: #856404;
    background-color: #ffeeba;
  }
  
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1;
  }
  
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404;
  }
  
  .list-group-item-danger {
    color: #721c24;
    background-color: #f5c6cb;
  }
  
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7;
  }
  
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24;
  }
  
  .list-group-item-light {
    color: #818182;
    background-color: #fdfdfe;
  }
  
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6;
  }
  
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182;
  }
  
  .list-group-item-dark {
    color: #1d2125;
    background-color: #c7cacb;
  }
  
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1d2125;
    background-color: #babdbf;
  }
  
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1d2125;
    border-color: #1d2125;
  }
  
  .list-group-item-blue {
    color: #004085;
    background-color: #b8daff;
  }
  
  .list-group-item-blue.list-group-item-action:hover, .list-group-item-blue.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff;
  }
  
  .list-group-item-blue.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085;
  }
  
  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
  }
  
  .close:hover {
    color: #000;
    text-decoration: none;
  }
  
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75;
  }
  
  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
  }
  
  a.close.disabled {
    pointer-events: none;
  }
  
  .toast {
    flex-basis: 350px;
    max-width: 350px;
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    opacity: 0;
    border-radius: 0.25rem;
  }
  
  .toast:not(:last-child) {
    margin-bottom: 0.75rem;
  }
  
  .toast.showing {
    opacity: 1;
  }
  
  .toast.show {
    display: block;
    opacity: 1;
  }
  
  .toast.hide {
    display: none;
  }
  
  .toast-header {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    color: #6c757d;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
  }
  
  .toast-body {
    padding: 0.75rem;
  }
  
  .modal-open {
    overflow: hidden;
  }
  
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
  }
  
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
  }
  
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px);
  }
  
  @media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
      transition: none;
    }
  }
  
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none;
  }
  
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
  }
  
  .modal-dialog-scrollable {
    display: flex;
    max-height: calc(100% - 1rem);
  }
  
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden;
  }
  
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0;
  }
  
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto;
  }
  
  .modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
  }
  
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: min-content;
    content: "";
  }
  
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  
  .modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none;
  }
  
  .modal-dialog-centered.modal-dialog-scrollable::before {
    content: none;
  }
  
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
  }
  
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
  }
  
  .modal-backdrop.fade {
    opacity: 0;
  }
  
  .modal-backdrop.show {
    opacity: 0.5;
  }
  
  .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
  }
  
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
  }
  
  .modal-title {
    margin-bottom: 0;
    line-height: 1.5;
  }
  
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
  }
  
  .modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
  }
  
  .modal-footer > * {
    margin: 0.25rem;
  }
  
  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }
  
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto;
    }
    .modal-dialog-scrollable {
      max-height: calc(100% - 3.5rem);
    }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem);
    }
    .modal-dialog-centered {
      min-height: calc(100% - 3.5rem);
    }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: min-content;
    }
    .modal-sm {
      max-width: 300px;
    }
  }
  
  @media (min-width: 1024px) {
    .modal-lg,
    .modal-xl {
      max-width: 800px;
    }
  }
  
  @media (min-width: 1440px) {
    .modal-xl {
      max-width: 1140px;
    }
  }
  
  .tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0;
  }
  
  .tooltip.show {
    opacity: 0.9;
  }
  
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
  }
  
  .tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
  }
  
  .bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
    padding: 0.4rem 0;
  }
  
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0;
  }
  
  .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000;
  }
  
  .bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
    padding: 0 0.4rem;
  }
  
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
  }
  
  .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000;
  }
  
  .bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
    padding: 0.4rem 0;
  }
  
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0;
  }
  
  .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000;
  }
  
  .bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
    padding: 0 0.4rem;
  }
  
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
  }
  
  .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000;
  }
  
  .tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem;
  }
  
  .popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
  }
  
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
  }
  
  .popover .arrow::before, .popover .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
  }
  
  .bs-popover-top, .bs-popover-auto[x-placement^="top"] {
    margin-bottom: 0.5rem;
  }
  
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px);
  }
  
  .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(0, 0, 0, 0.25);
  }
  
  .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff;
  }
  
  .bs-popover-right, .bs-popover-auto[x-placement^="right"] {
    margin-left: 0.5rem;
  }
  
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
  }
  
  .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.25);
  }
  
  .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff;
  }
  
  .bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.5rem;
  }
  
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px);
  }
  
  .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(0, 0, 0, 0.25);
  }
  
  .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff;
  }
  
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7;
  }
  
  .bs-popover-left, .bs-popover-auto[x-placement^="left"] {
    margin-right: 0.5rem;
  }
  
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
  }
  
  .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(0, 0, 0, 0.25);
  }
  
  .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff;
  }
  
  .popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
  }
  
  .popover-header:empty {
    display: none;
  }
  
  .popover-body {
    padding: 0.5rem 0.75rem;
    color: #212529;
  }
  
  .carousel {
    position: relative;
  }
  
  .carousel.pointer-event {
    touch-action: pan-y;
  }
  
  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .carousel-inner::after {
    display: block;
    clear: both;
    content: "";
  }
  
  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none;
    }
  }
  
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    display: block;
  }
  
  .carousel-item-next:not(.carousel-item-left),
  .active.carousel-item-right {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  
  .carousel-item-prev:not(.carousel-item-right),
  .active.carousel-item-left {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  
  .carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    -webkit-transform: none;
            transform: none;
  }
  
  .carousel-fade .carousel-item.active,
  .carousel-fade .carousel-item-next.carousel-item-left,
  .carousel-fade .carousel-item-prev.carousel-item-right {
    z-index: 1;
    opacity: 1;
  }
  
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s 0.6s;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none;
    }
  }
  
  .carousel-control-prev,
  .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none;
    }
  }
  
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
  }
  
  .carousel-control-prev {
    left: 0;
  }
  
  .carousel-control-next {
    right: 0;
  }
  
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50% / 100% 100%;
  }
  
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
  }
  
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
  }
  
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
  }
  
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .carousel-indicators li {
      transition: none;
    }
  }
  
  .carousel-indicators .active {
    opacity: 1;
  }
  
  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
  }
  
  @-webkit-keyframes spinner-border {
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes spinner-border {
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
            animation: spinner-border .75s linear infinite;
  }
  
  .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
  }
  
  @-webkit-keyframes spinner-grow {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    50% {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  
  @keyframes spinner-grow {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    50% {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  
  .spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow .75s linear infinite;
            animation: spinner-grow .75s linear infinite;
  }
  
  .spinner-grow-sm {
    width: 1rem;
    height: 1rem;
  }
  
  .align-baseline {
    vertical-align: baseline !important;
  }
  
  .align-top {
    vertical-align: top !important;
  }
  
  .align-middle {
    vertical-align: middle !important;
  }
  
  .align-bottom {
    vertical-align: bottom !important;
  }
  
  .align-text-bottom {
    vertical-align: text-bottom !important;
  }
  
  .align-text-top {
    vertical-align: text-top !important;
  }
  
  .bg-primary {
    background-color: #fae439 !important;
  }
  
  a.bg-primary:hover, a.bg-primary:focus,
  button.bg-primary:hover,
  button.bg-primary:focus {
    background-color: #f9dd07 !important;
  }
  
  .bg-secondary {
    background-color: #010101 !important;
  }
  
  a.bg-secondary:hover, a.bg-secondary:focus,
  button.bg-secondary:hover,
  button.bg-secondary:focus {
    background-color: black !important;
  }
  
  .bg-success {
    background-color: #28a745 !important;
  }
  
  a.bg-success:hover, a.bg-success:focus,
  button.bg-success:hover,
  button.bg-success:focus {
    background-color: #1e7e34 !important;
  }
  
  .bg-info {
    background-color: #17a2b8 !important;
  }
  
  a.bg-info:hover, a.bg-info:focus,
  button.bg-info:hover,
  button.bg-info:focus {
    background-color: #117a8b !important;
  }
  
  .bg-warning {
    background-color: #ffc107 !important;
  }
  
  a.bg-warning:hover, a.bg-warning:focus,
  button.bg-warning:hover,
  button.bg-warning:focus {
    background-color: #d39e00 !important;
  }
  
  .bg-danger {
    background-color: #dc3545 !important;
  }
  
  a.bg-danger:hover, a.bg-danger:focus,
  button.bg-danger:hover,
  button.bg-danger:focus {
    background-color: #bd2130 !important;
  }
  
  .bg-light {
    background-color: #f8f9fa !important;
  }
  
  a.bg-light:hover, a.bg-light:focus,
  button.bg-light:hover,
  button.bg-light:focus {
    background-color: #dae0e5 !important;
  }
  
  .bg-dark {
    background-color: #374047 !important;
  }
  
  a.bg-dark:hover, a.bg-dark:focus,
  button.bg-dark:hover,
  button.bg-dark:focus {
    background-color: #21262a !important;
  }
  
  .bg-blue {
    background-color: #007bff !important;
  }
  
  a.bg-blue:hover, a.bg-blue:focus,
  button.bg-blue:hover,
  button.bg-blue:focus {
    background-color: #0062cc !important;
  }
  
  .bg-white {
    background-color: #fff !important;
  }
  
  .bg-transparent {
    background-color: transparent !important;
  }
  
  .border {
    border: 1px solid #dee2e6 !important;
  }
  
  .border-top {
    border-top: 1px solid #dee2e6 !important;
  }
  
  .border-right {
    border-right: 1px solid #dee2e6 !important;
  }
  
  .border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
  }
  
  .border-left {
    border-left: 1px solid #dee2e6 !important;
  }
  
  .border-0 {
    border: 0 !important;
  }
  
  .border-top-0 {
    border-top: 0 !important;
  }
  
  .border-right-0 {
    border-right: 0 !important;
  }
  
  .border-bottom-0 {
    border-bottom: 0 !important;
  }
  
  .border-left-0 {
    border-left: 0 !important;
  }
  
  .border-primary {
    border-color: #fae439 !important;
  }
  
  .border-secondary {
    border-color: #010101 !important;
  }
  
  .border-success {
    border-color: #28a745 !important;
  }
  
  .border-info {
    border-color: #17a2b8 !important;
  }
  
  .border-warning {
    border-color: #ffc107 !important;
  }
  
  .border-danger {
    border-color: #dc3545 !important;
  }
  
  .border-light {
    border-color: #f8f9fa !important;
  }
  
  .border-dark {
    border-color: #374047 !important;
  }
  
  .border-blue {
    border-color: #007bff !important;
  }
  
  .border-white {
    border-color: #fff !important;
  }
  
  .rounded-sm {
    border-radius: 0.2rem !important;
  }
  
  .rounded {
    border-radius: 0.25rem !important;
  }
  
  .rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  
  .rounded-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  
  .rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  
  .rounded-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  
  .rounded-lg {
    border-radius: 0.3rem !important;
  }
  
  .rounded-circle {
    border-radius: 50% !important;
  }
  
  .rounded-pill {
    border-radius: 50rem !important;
  }
  
  .rounded-0 {
    border-radius: 0 !important;
  }
  
  .clearfix::after {
    display: block;
    clear: both;
    content: "";
  }
  
  .d-none {
    display: none !important;
  }
  
  .d-inline {
    display: inline !important;
  }
  
  .d-inline-block {
    display: inline-block !important;
  }
  
  .d-block {
    display: block !important;
  }
  
  .d-table {
    display: table !important;
  }
  
  .d-table-row {
    display: table-row !important;
  }
  
  .d-table-cell {
    display: table-cell !important;
  }
  
  .d-flex {
    display: flex !important;
  }
  
  .d-inline-flex {
    display: inline-flex !important;
  }
  
  @media (min-width: 576px) {
    .d-sm-none {
      display: none !important;
    }
    .d-sm-inline {
      display: inline !important;
    }
    .d-sm-inline-block {
      display: inline-block !important;
    }
    .d-sm-block {
      display: block !important;
    }
    .d-sm-table {
      display: table !important;
    }
    .d-sm-table-row {
      display: table-row !important;
    }
    .d-sm-table-cell {
      display: table-cell !important;
    }
    .d-sm-flex {
      display: flex !important;
    }
    .d-sm-inline-flex {
      display: inline-flex !important;
    }
  }
  
  @media (min-width: 768px) {
    .d-md-none {
      display: none !important;
    }
    .d-md-inline {
      display: inline !important;
    }
    .d-md-inline-block {
      display: inline-block !important;
    }
    .d-md-block {
      display: block !important;
    }
    .d-md-table {
      display: table !important;
    }
    .d-md-table-row {
      display: table-row !important;
    }
    .d-md-table-cell {
      display: table-cell !important;
    }
    .d-md-flex {
      display: flex !important;
    }
    .d-md-inline-flex {
      display: inline-flex !important;
    }
  }
  
  @media (min-width: 1024px) {
    .d-lg-none {
      display: none !important;
    }
    .d-lg-inline {
      display: inline !important;
    }
    .d-lg-inline-block {
      display: inline-block !important;
    }
    .d-lg-block {
      display: block !important;
    }
    .d-lg-table {
      display: table !important;
    }
    .d-lg-table-row {
      display: table-row !important;
    }
    .d-lg-table-cell {
      display: table-cell !important;
    }
    .d-lg-flex {
      display: flex !important;
    }
    .d-lg-inline-flex {
      display: inline-flex !important;
    }
  }
  
  @media (min-width: 1440px) {
    .d-xl-none {
      display: none !important;
    }
    .d-xl-inline {
      display: inline !important;
    }
    .d-xl-inline-block {
      display: inline-block !important;
    }
    .d-xl-block {
      display: block !important;
    }
    .d-xl-table {
      display: table !important;
    }
    .d-xl-table-row {
      display: table-row !important;
    }
    .d-xl-table-cell {
      display: table-cell !important;
    }
    .d-xl-flex {
      display: flex !important;
    }
    .d-xl-inline-flex {
      display: inline-flex !important;
    }
  }
  
  @media (min-width: 1680px) {
    .d-xxl-none {
      display: none !important;
    }
    .d-xxl-inline {
      display: inline !important;
    }
    .d-xxl-inline-block {
      display: inline-block !important;
    }
    .d-xxl-block {
      display: block !important;
    }
    .d-xxl-table {
      display: table !important;
    }
    .d-xxl-table-row {
      display: table-row !important;
    }
    .d-xxl-table-cell {
      display: table-cell !important;
    }
    .d-xxl-flex {
      display: flex !important;
    }
    .d-xxl-inline-flex {
      display: inline-flex !important;
    }
  }
  
  @media print {
    .d-print-none {
      display: none !important;
    }
    .d-print-inline {
      display: inline !important;
    }
    .d-print-inline-block {
      display: inline-block !important;
    }
    .d-print-block {
      display: block !important;
    }
    .d-print-table {
      display: table !important;
    }
    .d-print-table-row {
      display: table-row !important;
    }
    .d-print-table-cell {
      display: table-cell !important;
    }
    .d-print-flex {
      display: flex !important;
    }
    .d-print-inline-flex {
      display: inline-flex !important;
    }
  }
  
  .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
  }
  
  .embed-responsive::before {
    display: block;
    content: "";
  }
  
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  
  .embed-responsive-21by9::before {
    padding-top: 42.85714%;
  }
  
  .embed-responsive-16by9::before {
    padding-top: 56.25%;
  }
  
  .embed-responsive-4by3::before {
    padding-top: 75%;
  }
  
  .embed-responsive-1by1::before {
    padding-top: 100%;
  }
  
  .embed-responsive-21by9::before {
    padding-top: 42.85714%;
  }
  
  .embed-responsive-16by9::before {
    padding-top: 56.25%;
  }
  
  .embed-responsive-4by3::before {
    padding-top: 75%;
  }
  
  .embed-responsive-1by1::before {
    padding-top: 100%;
  }
  
  .flex-row {
    flex-direction: row !important;
  }
  
  .flex-column {
    flex-direction: column !important;
  }
  
  .flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  
  .flex-column-reverse {
    flex-direction: column-reverse !important;
  }
  
  .flex-wrap {
    flex-wrap: wrap !important;
  }
  
  .flex-nowrap {
    flex-wrap: nowrap !important;
  }
  
  .flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  
  .flex-fill {
    flex: 1 1 auto !important;
  }
  
  .flex-grow-0 {
    flex-grow: 0 !important;
  }
  
  .flex-grow-1 {
    flex-grow: 1 !important;
  }
  
  .flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  
  .flex-shrink-1 {
    flex-shrink: 1 !important;
  }
  
  .justify-content-start {
    justify-content: flex-start !important;
  }
  
  .justify-content-end {
    justify-content: flex-end !important;
  }
  
  .justify-content-center {
    justify-content: center !important;
  }
  
  .justify-content-between {
    justify-content: space-between !important;
  }
  
  .justify-content-around {
    justify-content: space-around !important;
  }
  
  .align-items-start {
    align-items: flex-start !important;
  }
  
  .align-items-end {
    align-items: flex-end !important;
  }
  
  .align-items-center {
    align-items: center !important;
  }
  
  .align-items-baseline {
    align-items: baseline !important;
  }
  
  .align-items-stretch {
    align-items: stretch !important;
  }
  
  .align-content-start {
    align-content: flex-start !important;
  }
  
  .align-content-end {
    align-content: flex-end !important;
  }
  
  .align-content-center {
    align-content: center !important;
  }
  
  .align-content-between {
    align-content: space-between !important;
  }
  
  .align-content-around {
    align-content: space-around !important;
  }
  
  .align-content-stretch {
    align-content: stretch !important;
  }
  
  .align-self-auto {
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
  }
  
  .align-self-start {
    align-self: flex-start !important;
  }
  
  .align-self-end {
    align-self: flex-end !important;
  }
  
  .align-self-center {
    -ms-grid-row-align: center !important;
    align-self: center !important;
  }
  
  .align-self-baseline {
    align-self: baseline !important;
  }
  
  .align-self-stretch {
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
  }
  
  @media (min-width: 576px) {
    .flex-sm-row {
      flex-direction: row !important;
    }
    .flex-sm-column {
      flex-direction: column !important;
    }
    .flex-sm-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-sm-wrap {
      flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex-sm-fill {
      flex: 1 1 auto !important;
    }
    .flex-sm-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-sm-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-sm-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1 {
      flex-shrink: 1 !important;
    }
    .justify-content-sm-start {
      justify-content: flex-start !important;
    }
    .justify-content-sm-end {
      justify-content: flex-end !important;
    }
    .justify-content-sm-center {
      justify-content: center !important;
    }
    .justify-content-sm-between {
      justify-content: space-between !important;
    }
    .justify-content-sm-around {
      justify-content: space-around !important;
    }
    .align-items-sm-start {
      align-items: flex-start !important;
    }
    .align-items-sm-end {
      align-items: flex-end !important;
    }
    .align-items-sm-center {
      align-items: center !important;
    }
    .align-items-sm-baseline {
      align-items: baseline !important;
    }
    .align-items-sm-stretch {
      align-items: stretch !important;
    }
    .align-content-sm-start {
      align-content: flex-start !important;
    }
    .align-content-sm-end {
      align-content: flex-end !important;
    }
    .align-content-sm-center {
      align-content: center !important;
    }
    .align-content-sm-between {
      align-content: space-between !important;
    }
    .align-content-sm-around {
      align-content: space-around !important;
    }
    .align-content-sm-stretch {
      align-content: stretch !important;
    }
    .align-self-sm-auto {
      -ms-grid-row-align: auto !important;
      align-self: auto !important;
    }
    .align-self-sm-start {
      align-self: flex-start !important;
    }
    .align-self-sm-end {
      align-self: flex-end !important;
    }
    .align-self-sm-center {
      -ms-grid-row-align: center !important;
      align-self: center !important;
    }
    .align-self-sm-baseline {
      align-self: baseline !important;
    }
    .align-self-sm-stretch {
      -ms-grid-row-align: stretch !important;
      align-self: stretch !important;
    }
  }
  
  @media (min-width: 768px) {
    .flex-md-row {
      flex-direction: row !important;
    }
    .flex-md-column {
      flex-direction: column !important;
    }
    .flex-md-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-md-wrap {
      flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex-md-fill {
      flex: 1 1 auto !important;
    }
    .flex-md-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-md-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-md-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-md-shrink-1 {
      flex-shrink: 1 !important;
    }
    .justify-content-md-start {
      justify-content: flex-start !important;
    }
    .justify-content-md-end {
      justify-content: flex-end !important;
    }
    .justify-content-md-center {
      justify-content: center !important;
    }
    .justify-content-md-between {
      justify-content: space-between !important;
    }
    .justify-content-md-around {
      justify-content: space-around !important;
    }
    .align-items-md-start {
      align-items: flex-start !important;
    }
    .align-items-md-end {
      align-items: flex-end !important;
    }
    .align-items-md-center {
      align-items: center !important;
    }
    .align-items-md-baseline {
      align-items: baseline !important;
    }
    .align-items-md-stretch {
      align-items: stretch !important;
    }
    .align-content-md-start {
      align-content: flex-start !important;
    }
    .align-content-md-end {
      align-content: flex-end !important;
    }
    .align-content-md-center {
      align-content: center !important;
    }
    .align-content-md-between {
      align-content: space-between !important;
    }
    .align-content-md-around {
      align-content: space-around !important;
    }
    .align-content-md-stretch {
      align-content: stretch !important;
    }
    .align-self-md-auto {
      -ms-grid-row-align: auto !important;
      align-self: auto !important;
    }
    .align-self-md-start {
      align-self: flex-start !important;
    }
    .align-self-md-end {
      align-self: flex-end !important;
    }
    .align-self-md-center {
      -ms-grid-row-align: center !important;
      align-self: center !important;
    }
    .align-self-md-baseline {
      align-self: baseline !important;
    }
    .align-self-md-stretch {
      -ms-grid-row-align: stretch !important;
      align-self: stretch !important;
    }
  }
  
  @media (min-width: 1024px) {
    .flex-lg-row {
      flex-direction: row !important;
    }
    .flex-lg-column {
      flex-direction: column !important;
    }
    .flex-lg-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-lg-wrap {
      flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex-lg-fill {
      flex: 1 1 auto !important;
    }
    .flex-lg-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-lg-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-lg-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1 {
      flex-shrink: 1 !important;
    }
    .justify-content-lg-start {
      justify-content: flex-start !important;
    }
    .justify-content-lg-end {
      justify-content: flex-end !important;
    }
    .justify-content-lg-center {
      justify-content: center !important;
    }
    .justify-content-lg-between {
      justify-content: space-between !important;
    }
    .justify-content-lg-around {
      justify-content: space-around !important;
    }
    .align-items-lg-start {
      align-items: flex-start !important;
    }
    .align-items-lg-end {
      align-items: flex-end !important;
    }
    .align-items-lg-center {
      align-items: center !important;
    }
    .align-items-lg-baseline {
      align-items: baseline !important;
    }
    .align-items-lg-stretch {
      align-items: stretch !important;
    }
    .align-content-lg-start {
      align-content: flex-start !important;
    }
    .align-content-lg-end {
      align-content: flex-end !important;
    }
    .align-content-lg-center {
      align-content: center !important;
    }
    .align-content-lg-between {
      align-content: space-between !important;
    }
    .align-content-lg-around {
      align-content: space-around !important;
    }
    .align-content-lg-stretch {
      align-content: stretch !important;
    }
    .align-self-lg-auto {
      -ms-grid-row-align: auto !important;
      align-self: auto !important;
    }
    .align-self-lg-start {
      align-self: flex-start !important;
    }
    .align-self-lg-end {
      align-self: flex-end !important;
    }
    .align-self-lg-center {
      -ms-grid-row-align: center !important;
      align-self: center !important;
    }
    .align-self-lg-baseline {
      align-self: baseline !important;
    }
    .align-self-lg-stretch {
      -ms-grid-row-align: stretch !important;
      align-self: stretch !important;
    }
  }
  
  @media (min-width: 1440px) {
    .flex-xl-row {
      flex-direction: row !important;
    }
    .flex-xl-column {
      flex-direction: column !important;
    }
    .flex-xl-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-xl-wrap {
      flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex-xl-fill {
      flex: 1 1 auto !important;
    }
    .flex-xl-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-xl-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-xl-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1 {
      flex-shrink: 1 !important;
    }
    .justify-content-xl-start {
      justify-content: flex-start !important;
    }
    .justify-content-xl-end {
      justify-content: flex-end !important;
    }
    .justify-content-xl-center {
      justify-content: center !important;
    }
    .justify-content-xl-between {
      justify-content: space-between !important;
    }
    .justify-content-xl-around {
      justify-content: space-around !important;
    }
    .align-items-xl-start {
      align-items: flex-start !important;
    }
    .align-items-xl-end {
      align-items: flex-end !important;
    }
    .align-items-xl-center {
      align-items: center !important;
    }
    .align-items-xl-baseline {
      align-items: baseline !important;
    }
    .align-items-xl-stretch {
      align-items: stretch !important;
    }
    .align-content-xl-start {
      align-content: flex-start !important;
    }
    .align-content-xl-end {
      align-content: flex-end !important;
    }
    .align-content-xl-center {
      align-content: center !important;
    }
    .align-content-xl-between {
      align-content: space-between !important;
    }
    .align-content-xl-around {
      align-content: space-around !important;
    }
    .align-content-xl-stretch {
      align-content: stretch !important;
    }
    .align-self-xl-auto {
      -ms-grid-row-align: auto !important;
      align-self: auto !important;
    }
    .align-self-xl-start {
      align-self: flex-start !important;
    }
    .align-self-xl-end {
      align-self: flex-end !important;
    }
    .align-self-xl-center {
      -ms-grid-row-align: center !important;
      align-self: center !important;
    }
    .align-self-xl-baseline {
      align-self: baseline !important;
    }
    .align-self-xl-stretch {
      -ms-grid-row-align: stretch !important;
      align-self: stretch !important;
    }
  }
  
  @media (min-width: 1680px) {
    .flex-xxl-row {
      flex-direction: row !important;
    }
    .flex-xxl-column {
      flex-direction: column !important;
    }
    .flex-xxl-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-xxl-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-xxl-wrap {
      flex-wrap: wrap !important;
    }
    .flex-xxl-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-xxl-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex-xxl-fill {
      flex: 1 1 auto !important;
    }
    .flex-xxl-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-xxl-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-xxl-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-xxl-shrink-1 {
      flex-shrink: 1 !important;
    }
    .justify-content-xxl-start {
      justify-content: flex-start !important;
    }
    .justify-content-xxl-end {
      justify-content: flex-end !important;
    }
    .justify-content-xxl-center {
      justify-content: center !important;
    }
    .justify-content-xxl-between {
      justify-content: space-between !important;
    }
    .justify-content-xxl-around {
      justify-content: space-around !important;
    }
    .align-items-xxl-start {
      align-items: flex-start !important;
    }
    .align-items-xxl-end {
      align-items: flex-end !important;
    }
    .align-items-xxl-center {
      align-items: center !important;
    }
    .align-items-xxl-baseline {
      align-items: baseline !important;
    }
    .align-items-xxl-stretch {
      align-items: stretch !important;
    }
    .align-content-xxl-start {
      align-content: flex-start !important;
    }
    .align-content-xxl-end {
      align-content: flex-end !important;
    }
    .align-content-xxl-center {
      align-content: center !important;
    }
    .align-content-xxl-between {
      align-content: space-between !important;
    }
    .align-content-xxl-around {
      align-content: space-around !important;
    }
    .align-content-xxl-stretch {
      align-content: stretch !important;
    }
    .align-self-xxl-auto {
      -ms-grid-row-align: auto !important;
      align-self: auto !important;
    }
    .align-self-xxl-start {
      align-self: flex-start !important;
    }
    .align-self-xxl-end {
      align-self: flex-end !important;
    }
    .align-self-xxl-center {
      -ms-grid-row-align: center !important;
      align-self: center !important;
    }
    .align-self-xxl-baseline {
      align-self: baseline !important;
    }
    .align-self-xxl-stretch {
      -ms-grid-row-align: stretch !important;
      align-self: stretch !important;
    }
  }
  
  .float-left {
    float: left !important;
  }
  
  .float-right {
    float: right !important;
  }
  
  .float-none {
    float: none !important;
  }
  
  @media (min-width: 576px) {
    .float-sm-left {
      float: left !important;
    }
    .float-sm-right {
      float: right !important;
    }
    .float-sm-none {
      float: none !important;
    }
  }
  
  @media (min-width: 768px) {
    .float-md-left {
      float: left !important;
    }
    .float-md-right {
      float: right !important;
    }
    .float-md-none {
      float: none !important;
    }
  }
  
  @media (min-width: 1024px) {
    .float-lg-left {
      float: left !important;
    }
    .float-lg-right {
      float: right !important;
    }
    .float-lg-none {
      float: none !important;
    }
  }
  
  @media (min-width: 1440px) {
    .float-xl-left {
      float: left !important;
    }
    .float-xl-right {
      float: right !important;
    }
    .float-xl-none {
      float: none !important;
    }
  }
  
  @media (min-width: 1680px) {
    .float-xxl-left {
      float: left !important;
    }
    .float-xxl-right {
      float: right !important;
    }
    .float-xxl-none {
      float: none !important;
    }
  }
  
  .user-select-all {
    -webkit-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important;
  }
  
  .user-select-auto {
    -webkit-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important;
  }
  
  .user-select-none {
    -webkit-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
  }
  
  .overflow-auto {
    overflow: auto !important;
  }
  
  .overflow-hidden {
    overflow: hidden !important;
  }
  
  .position-static {
    position: static !important;
  }
  
  .position-relative {
    position: relative !important;
  }
  
  .position-absolute {
    position: absolute !important;
  }
  
  .position-fixed {
    position: fixed !important;
  }
  
  .position-sticky {
    position: sticky !important;
  }
  
  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  
  .fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }
  
  @supports (position: sticky) {
    .sticky-top {
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
  
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  
  .sr-only-focusable:active, .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  
  .shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  
  .shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  
  .shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  
  .shadow-none {
    box-shadow: none !important;
  }
  
  .w-25 {
    width: 25% !important;
  }
  
  .w-50 {
    width: 50% !important;
  }
  
  .w-75 {
    width: 75% !important;
  }
  
  .w-100 {
    width: 100% !important;
  }
  
  .w-auto {
    width: auto !important;
  }
  
  .h-25 {
    height: 25% !important;
  }
  
  .h-50 {
    height: 50% !important;
  }
  
  .h-75 {
    height: 75% !important;
  }
  
  .h-100 {
    height: 100% !important;
  }
  
  .h-auto {
    height: auto !important;
  }
  
  .mw-100 {
    max-width: 100% !important;
  }
  
  .mh-100 {
    max-height: 100% !important;
  }
  
  .min-vw-100 {
    min-width: 100vw !important;
  }
  
  .min-vh-100 {
    min-height: 100vh !important;
  }
  
  .vw-100 {
    width: 100vw !important;
  }
  
  .vh-100 {
    height: 100vh !important;
  }
  
  .m-0 {
    margin: 0 !important;
  }
  
  .mt-0,
  .my-0 {
    margin-top: 0 !important;
  }
  
  .mr-0,
  .mx-0 {
    margin-right: 0 !important;
  }
  
  .mb-0,
  .my-0 {
    margin-bottom: 0 !important;
  }
  
  .ml-0,
  .mx-0 {
    margin-left: 0 !important;
  }
  
  .m-1 {
    margin: 0.25rem !important;
  }
  
  .mt-1,
  .my-1 {
    margin-top: 0.25rem !important;
  }
  
  .mr-1,
  .mx-1 {
    margin-right: 0.25rem !important;
  }
  
  .mb-1,
  .my-1 {
    margin-bottom: 0.25rem !important;
  }
  
  .ml-1,
  .mx-1 {
    margin-left: 0.25rem !important;
  }
  
  .m-2 {
    margin: 0.5rem !important;
  }
  
  .mt-2,
  .my-2 {
    margin-top: 0.5rem !important;
  }
  
  .mr-2,
  .mx-2 {
    margin-right: 0.5rem !important;
  }
  
  .mb-2,
  .my-2 {
    margin-bottom: 0.5rem !important;
  }
  
  .ml-2,
  .mx-2 {
    margin-left: 0.5rem !important;
  }
  
  .m-3 {
    margin: 1rem !important;
  }
  
  .mt-3,
  .my-3 {
    margin-top: 1rem !important;
  }
  
  .mr-3,
  .mx-3 {
    margin-right: 1rem !important;
  }
  
  .mb-3,
  .my-3 {
    margin-bottom: 1rem !important;
  }
  
  .ml-3,
  .mx-3 {
    margin-left: 1rem !important;
  }
  
  .m-4 {
    margin: 1.5rem !important;
  }
  
  .mt-4,
  .my-4 {
    margin-top: 1.5rem !important;
  }
  
  .mr-4,
  .mx-4 {
    margin-right: 1.5rem !important;
  }
  
  .mb-4,
  .my-4 {
    margin-bottom: 1.5rem !important;
  }
  
  .ml-4,
  .mx-4 {
    margin-left: 1.5rem !important;
  }
  
  .m-5 {
    margin: 3rem !important;
  }
  
  .mt-5,
  .my-5 {
    margin-top: 3rem !important;
  }
  
  .mr-5,
  .mx-5 {
    margin-right: 3rem !important;
  }
  
  .mb-5,
  .my-5 {
    margin-bottom: 3rem !important;
  }
  
  .ml-5,
  .mx-5 {
    margin-left: 3rem !important;
  }
  
  .m-6 {
    margin: 5rem !important;
  }
  
  .mt-6,
  .my-6 {
    margin-top: 5rem !important;
  }
  
  .mr-6,
  .mx-6 {
    margin-right: 5rem !important;
  }
  
  .mb-6,
  .my-6 {
    margin-bottom: 5rem !important;
  }
  
  .ml-6,
  .mx-6 {
    margin-left: 5rem !important;
  }
  
  .m-7 {
    margin: 6rem !important;
  }
  
  .mt-7,
  .my-7 {
    margin-top: 6rem !important;
  }
  
  .mr-7,
  .mx-7 {
    margin-right: 6rem !important;
  }
  
  .mb-7,
  .my-7 {
    margin-bottom: 6rem !important;
  }
  
  .ml-7,
  .mx-7 {
    margin-left: 6rem !important;
  }
  
  .m-8 {
    margin: 8rem !important;
  }
  
  .mt-8,
  .my-8 {
    margin-top: 8rem !important;
  }
  
  .mr-8,
  .mx-8 {
    margin-right: 8rem !important;
  }
  
  .mb-8,
  .my-8 {
    margin-bottom: 8rem !important;
  }
  
  .ml-8,
  .mx-8 {
    margin-left: 8rem !important;
  }
  
  .m-9 {
    margin: 9rem !important;
  }
  
  .mt-9,
  .my-9 {
    margin-top: 9rem !important;
  }
  
  .mr-9,
  .mx-9 {
    margin-right: 9rem !important;
  }
  
  .mb-9,
  .my-9 {
    margin-bottom: 9rem !important;
  }
  
  .ml-9,
  .mx-9 {
    margin-left: 9rem !important;
  }
  
  .p-0 {
    padding: 0 !important;
  }
  
  .pt-0,
  .py-0 {
    padding-top: 0 !important;
  }
  
  .pr-0,
  .px-0 {
    padding-right: 0 !important;
  }
  
  .pb-0,
  .py-0 {
    padding-bottom: 0 !important;
  }
  
  .pl-0,
  .px-0 {
    padding-left: 0 !important;
  }
  
  .p-1 {
    padding: 0.25rem !important;
  }
  
  .pt-1,
  .py-1 {
    padding-top: 0.25rem !important;
  }
  
  .pr-1,
  .px-1 {
    padding-right: 0.25rem !important;
  }
  
  .pb-1,
  .py-1 {
    padding-bottom: 0.25rem !important;
  }
  
  .pl-1,
  .px-1 {
    padding-left: 0.25rem !important;
  }
  
  .p-2 {
    padding: 0.5rem !important;
  }
  
  .pt-2,
  .py-2 {
    padding-top: 0.5rem !important;
  }
  
  .pr-2,
  .px-2 {
    padding-right: 0.5rem !important;
  }
  
  .pb-2,
  .py-2 {
    padding-bottom: 0.5rem !important;
  }
  
  .pl-2,
  .px-2 {
    padding-left: 0.5rem !important;
  }
  
  .p-3 {
    padding: 1rem !important;
  }
  
  .pt-3,
  .py-3 {
    padding-top: 1rem !important;
  }
  
  .pr-3,
  .px-3 {
    padding-right: 1rem !important;
  }
  
  .pb-3,
  .py-3 {
    padding-bottom: 1rem !important;
  }
  
  .pl-3,
  .px-3 {
    padding-left: 1rem !important;
  }
  
  .p-4 {
    padding: 1.5rem !important;
  }
  
  .pt-4,
  .py-4 {
    padding-top: 1.5rem !important;
  }
  
  .pr-4,
  .px-4 {
    padding-right: 1.5rem !important;
  }
  
  .pb-4,
  .py-4 {
    padding-bottom: 1.5rem !important;
  }
  
  .pl-4,
  .px-4 {
    padding-left: 1.5rem !important;
  }
  
  .p-5 {
    padding: 3rem !important;
  }
  
  .pt-5,
  .py-5 {
    padding-top: 3rem !important;
  }
  
  .pr-5,
  .px-5 {
    padding-right: 3rem !important;
  }
  
  .pb-5,
  .py-5 {
    padding-bottom: 3rem !important;
  }
  
  .pl-5,
  .px-5 {
    padding-left: 3rem !important;
  }
  
  .p-6 {
    padding: 5rem !important;
  }
  
  .pt-6,
  .py-6 {
    padding-top: 5rem !important;
  }
  
  .pr-6,
  .px-6 {
    padding-right: 5rem !important;
  }
  
  .pb-6,
  .py-6 {
    padding-bottom: 5rem !important;
  }
  
  .pl-6,
  .px-6 {
    padding-left: 5rem !important;
  }
  
  .p-7 {
    padding: 6rem !important;
  }
  
  .pt-7,
  .py-7 {
    padding-top: 6rem !important;
  }
  
  .pr-7,
  .px-7 {
    padding-right: 6rem !important;
  }
  
  .pb-7,
  .py-7 {
    padding-bottom: 6rem !important;
  }
  
  .pl-7,
  .px-7 {
    padding-left: 6rem !important;
  }
  
  .p-8 {
    padding: 8rem !important;
  }
  
  .pt-8,
  .py-8 {
    padding-top: 8rem !important;
  }
  
  .pr-8,
  .px-8 {
    padding-right: 8rem !important;
  }
  
  .pb-8,
  .py-8 {
    padding-bottom: 8rem !important;
  }
  
  .pl-8,
  .px-8 {
    padding-left: 8rem !important;
  }
  
  .p-9 {
    padding: 9rem !important;
  }
  
  .pt-9,
  .py-9 {
    padding-top: 9rem !important;
  }
  
  .pr-9,
  .px-9 {
    padding-right: 9rem !important;
  }
  
  .pb-9,
  .py-9 {
    padding-bottom: 9rem !important;
  }
  
  .pl-9,
  .px-9 {
    padding-left: 9rem !important;
  }
  
  .m-n1 {
    margin: -0.25rem !important;
  }
  
  .mt-n1,
  .my-n1 {
    margin-top: -0.25rem !important;
  }
  
  .mr-n1,
  .mx-n1 {
    margin-right: -0.25rem !important;
  }
  
  .mb-n1,
  .my-n1 {
    margin-bottom: -0.25rem !important;
  }
  
  .ml-n1,
  .mx-n1 {
    margin-left: -0.25rem !important;
  }
  
  .m-n2 {
    margin: -0.5rem !important;
  }
  
  .mt-n2,
  .my-n2 {
    margin-top: -0.5rem !important;
  }
  
  .mr-n2,
  .mx-n2 {
    margin-right: -0.5rem !important;
  }
  
  .mb-n2,
  .my-n2 {
    margin-bottom: -0.5rem !important;
  }
  
  .ml-n2,
  .mx-n2 {
    margin-left: -0.5rem !important;
  }
  
  .m-n3 {
    margin: -1rem !important;
  }
  
  .mt-n3,
  .my-n3 {
    margin-top: -1rem !important;
  }
  
  .mr-n3,
  .mx-n3 {
    margin-right: -1rem !important;
  }
  
  .mb-n3,
  .my-n3 {
    margin-bottom: -1rem !important;
  }
  
  .ml-n3,
  .mx-n3 {
    margin-left: -1rem !important;
  }
  
  .m-n4 {
    margin: -1.5rem !important;
  }
  
  .mt-n4,
  .my-n4 {
    margin-top: -1.5rem !important;
  }
  
  .mr-n4,
  .mx-n4 {
    margin-right: -1.5rem !important;
  }
  
  .mb-n4,
  .my-n4 {
    margin-bottom: -1.5rem !important;
  }
  
  .ml-n4,
  .mx-n4 {
    margin-left: -1.5rem !important;
  }
  
  .m-n5 {
    margin: -3rem !important;
  }
  
  .mt-n5,
  .my-n5 {
    margin-top: -3rem !important;
  }
  
  .mr-n5,
  .mx-n5 {
    margin-right: -3rem !important;
  }
  
  .mb-n5,
  .my-n5 {
    margin-bottom: -3rem !important;
  }
  
  .ml-n5,
  .mx-n5 {
    margin-left: -3rem !important;
  }
  
  .m-n6 {
    margin: -5rem !important;
  }
  
  .mt-n6,
  .my-n6 {
    margin-top: -5rem !important;
  }
  
  .mr-n6,
  .mx-n6 {
    margin-right: -5rem !important;
  }
  
  .mb-n6,
  .my-n6 {
    margin-bottom: -5rem !important;
  }
  
  .ml-n6,
  .mx-n6 {
    margin-left: -5rem !important;
  }
  
  .m-n7 {
    margin: -6rem !important;
  }
  
  .mt-n7,
  .my-n7 {
    margin-top: -6rem !important;
  }
  
  .mr-n7,
  .mx-n7 {
    margin-right: -6rem !important;
  }
  
  .mb-n7,
  .my-n7 {
    margin-bottom: -6rem !important;
  }
  
  .ml-n7,
  .mx-n7 {
    margin-left: -6rem !important;
  }
  
  .m-n8 {
    margin: -8rem !important;
  }
  
  .mt-n8,
  .my-n8 {
    margin-top: -8rem !important;
  }
  
  .mr-n8,
  .mx-n8 {
    margin-right: -8rem !important;
  }
  
  .mb-n8,
  .my-n8 {
    margin-bottom: -8rem !important;
  }
  
  .ml-n8,
  .mx-n8 {
    margin-left: -8rem !important;
  }
  
  .m-n9 {
    margin: -9rem !important;
  }
  
  .mt-n9,
  .my-n9 {
    margin-top: -9rem !important;
  }
  
  .mr-n9,
  .mx-n9 {
    margin-right: -9rem !important;
  }
  
  .mb-n9,
  .my-n9 {
    margin-bottom: -9rem !important;
  }
  
  .ml-n9,
  .mx-n9 {
    margin-left: -9rem !important;
  }
  
  .m-auto {
    margin: auto !important;
  }
  
  .mt-auto,
  .my-auto {
    margin-top: auto !important;
  }
  
  .mr-auto,
  .mx-auto {
    margin-right: auto !important;
  }
  
  .mb-auto,
  .my-auto {
    margin-bottom: auto !important;
  }
  
  .ml-auto,
  .mx-auto {
    margin-left: auto !important;
  }
  
  @media (min-width: 576px) {
    .m-sm-0 {
      margin: 0 !important;
    }
    .mt-sm-0,
    .my-sm-0 {
      margin-top: 0 !important;
    }
    .mr-sm-0,
    .mx-sm-0 {
      margin-right: 0 !important;
    }
    .mb-sm-0,
    .my-sm-0 {
      margin-bottom: 0 !important;
    }
    .ml-sm-0,
    .mx-sm-0 {
      margin-left: 0 !important;
    }
    .m-sm-1 {
      margin: 0.25rem !important;
    }
    .mt-sm-1,
    .my-sm-1 {
      margin-top: 0.25rem !important;
    }
    .mr-sm-1,
    .mx-sm-1 {
      margin-right: 0.25rem !important;
    }
    .mb-sm-1,
    .my-sm-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-sm-1,
    .mx-sm-1 {
      margin-left: 0.25rem !important;
    }
    .m-sm-2 {
      margin: 0.5rem !important;
    }
    .mt-sm-2,
    .my-sm-2 {
      margin-top: 0.5rem !important;
    }
    .mr-sm-2,
    .mx-sm-2 {
      margin-right: 0.5rem !important;
    }
    .mb-sm-2,
    .my-sm-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-sm-2,
    .mx-sm-2 {
      margin-left: 0.5rem !important;
    }
    .m-sm-3 {
      margin: 1rem !important;
    }
    .mt-sm-3,
    .my-sm-3 {
      margin-top: 1rem !important;
    }
    .mr-sm-3,
    .mx-sm-3 {
      margin-right: 1rem !important;
    }
    .mb-sm-3,
    .my-sm-3 {
      margin-bottom: 1rem !important;
    }
    .ml-sm-3,
    .mx-sm-3 {
      margin-left: 1rem !important;
    }
    .m-sm-4 {
      margin: 1.5rem !important;
    }
    .mt-sm-4,
    .my-sm-4 {
      margin-top: 1.5rem !important;
    }
    .mr-sm-4,
    .mx-sm-4 {
      margin-right: 1.5rem !important;
    }
    .mb-sm-4,
    .my-sm-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-sm-4,
    .mx-sm-4 {
      margin-left: 1.5rem !important;
    }
    .m-sm-5 {
      margin: 3rem !important;
    }
    .mt-sm-5,
    .my-sm-5 {
      margin-top: 3rem !important;
    }
    .mr-sm-5,
    .mx-sm-5 {
      margin-right: 3rem !important;
    }
    .mb-sm-5,
    .my-sm-5 {
      margin-bottom: 3rem !important;
    }
    .ml-sm-5,
    .mx-sm-5 {
      margin-left: 3rem !important;
    }
    .m-sm-6 {
      margin: 5rem !important;
    }
    .mt-sm-6,
    .my-sm-6 {
      margin-top: 5rem !important;
    }
    .mr-sm-6,
    .mx-sm-6 {
      margin-right: 5rem !important;
    }
    .mb-sm-6,
    .my-sm-6 {
      margin-bottom: 5rem !important;
    }
    .ml-sm-6,
    .mx-sm-6 {
      margin-left: 5rem !important;
    }
    .m-sm-7 {
      margin: 6rem !important;
    }
    .mt-sm-7,
    .my-sm-7 {
      margin-top: 6rem !important;
    }
    .mr-sm-7,
    .mx-sm-7 {
      margin-right: 6rem !important;
    }
    .mb-sm-7,
    .my-sm-7 {
      margin-bottom: 6rem !important;
    }
    .ml-sm-7,
    .mx-sm-7 {
      margin-left: 6rem !important;
    }
    .m-sm-8 {
      margin: 8rem !important;
    }
    .mt-sm-8,
    .my-sm-8 {
      margin-top: 8rem !important;
    }
    .mr-sm-8,
    .mx-sm-8 {
      margin-right: 8rem !important;
    }
    .mb-sm-8,
    .my-sm-8 {
      margin-bottom: 8rem !important;
    }
    .ml-sm-8,
    .mx-sm-8 {
      margin-left: 8rem !important;
    }
    .m-sm-9 {
      margin: 9rem !important;
    }
    .mt-sm-9,
    .my-sm-9 {
      margin-top: 9rem !important;
    }
    .mr-sm-9,
    .mx-sm-9 {
      margin-right: 9rem !important;
    }
    .mb-sm-9,
    .my-sm-9 {
      margin-bottom: 9rem !important;
    }
    .ml-sm-9,
    .mx-sm-9 {
      margin-left: 9rem !important;
    }
    .p-sm-0 {
      padding: 0 !important;
    }
    .pt-sm-0,
    .py-sm-0 {
      padding-top: 0 !important;
    }
    .pr-sm-0,
    .px-sm-0 {
      padding-right: 0 !important;
    }
    .pb-sm-0,
    .py-sm-0 {
      padding-bottom: 0 !important;
    }
    .pl-sm-0,
    .px-sm-0 {
      padding-left: 0 !important;
    }
    .p-sm-1 {
      padding: 0.25rem !important;
    }
    .pt-sm-1,
    .py-sm-1 {
      padding-top: 0.25rem !important;
    }
    .pr-sm-1,
    .px-sm-1 {
      padding-right: 0.25rem !important;
    }
    .pb-sm-1,
    .py-sm-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-sm-1,
    .px-sm-1 {
      padding-left: 0.25rem !important;
    }
    .p-sm-2 {
      padding: 0.5rem !important;
    }
    .pt-sm-2,
    .py-sm-2 {
      padding-top: 0.5rem !important;
    }
    .pr-sm-2,
    .px-sm-2 {
      padding-right: 0.5rem !important;
    }
    .pb-sm-2,
    .py-sm-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-sm-2,
    .px-sm-2 {
      padding-left: 0.5rem !important;
    }
    .p-sm-3 {
      padding: 1rem !important;
    }
    .pt-sm-3,
    .py-sm-3 {
      padding-top: 1rem !important;
    }
    .pr-sm-3,
    .px-sm-3 {
      padding-right: 1rem !important;
    }
    .pb-sm-3,
    .py-sm-3 {
      padding-bottom: 1rem !important;
    }
    .pl-sm-3,
    .px-sm-3 {
      padding-left: 1rem !important;
    }
    .p-sm-4 {
      padding: 1.5rem !important;
    }
    .pt-sm-4,
    .py-sm-4 {
      padding-top: 1.5rem !important;
    }
    .pr-sm-4,
    .px-sm-4 {
      padding-right: 1.5rem !important;
    }
    .pb-sm-4,
    .py-sm-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-sm-4,
    .px-sm-4 {
      padding-left: 1.5rem !important;
    }
    .p-sm-5 {
      padding: 3rem !important;
    }
    .pt-sm-5,
    .py-sm-5 {
      padding-top: 3rem !important;
    }
    .pr-sm-5,
    .px-sm-5 {
      padding-right: 3rem !important;
    }
    .pb-sm-5,
    .py-sm-5 {
      padding-bottom: 3rem !important;
    }
    .pl-sm-5,
    .px-sm-5 {
      padding-left: 3rem !important;
    }
    .p-sm-6 {
      padding: 5rem !important;
    }
    .pt-sm-6,
    .py-sm-6 {
      padding-top: 5rem !important;
    }
    .pr-sm-6,
    .px-sm-6 {
      padding-right: 5rem !important;
    }
    .pb-sm-6,
    .py-sm-6 {
      padding-bottom: 5rem !important;
    }
    .pl-sm-6,
    .px-sm-6 {
      padding-left: 5rem !important;
    }
    .p-sm-7 {
      padding: 6rem !important;
    }
    .pt-sm-7,
    .py-sm-7 {
      padding-top: 6rem !important;
    }
    .pr-sm-7,
    .px-sm-7 {
      padding-right: 6rem !important;
    }
    .pb-sm-7,
    .py-sm-7 {
      padding-bottom: 6rem !important;
    }
    .pl-sm-7,
    .px-sm-7 {
      padding-left: 6rem !important;
    }
    .p-sm-8 {
      padding: 8rem !important;
    }
    .pt-sm-8,
    .py-sm-8 {
      padding-top: 8rem !important;
    }
    .pr-sm-8,
    .px-sm-8 {
      padding-right: 8rem !important;
    }
    .pb-sm-8,
    .py-sm-8 {
      padding-bottom: 8rem !important;
    }
    .pl-sm-8,
    .px-sm-8 {
      padding-left: 8rem !important;
    }
    .p-sm-9 {
      padding: 9rem !important;
    }
    .pt-sm-9,
    .py-sm-9 {
      padding-top: 9rem !important;
    }
    .pr-sm-9,
    .px-sm-9 {
      padding-right: 9rem !important;
    }
    .pb-sm-9,
    .py-sm-9 {
      padding-bottom: 9rem !important;
    }
    .pl-sm-9,
    .px-sm-9 {
      padding-left: 9rem !important;
    }
    .m-sm-n1 {
      margin: -0.25rem !important;
    }
    .mt-sm-n1,
    .my-sm-n1 {
      margin-top: -0.25rem !important;
    }
    .mr-sm-n1,
    .mx-sm-n1 {
      margin-right: -0.25rem !important;
    }
    .mb-sm-n1,
    .my-sm-n1 {
      margin-bottom: -0.25rem !important;
    }
    .ml-sm-n1,
    .mx-sm-n1 {
      margin-left: -0.25rem !important;
    }
    .m-sm-n2 {
      margin: -0.5rem !important;
    }
    .mt-sm-n2,
    .my-sm-n2 {
      margin-top: -0.5rem !important;
    }
    .mr-sm-n2,
    .mx-sm-n2 {
      margin-right: -0.5rem !important;
    }
    .mb-sm-n2,
    .my-sm-n2 {
      margin-bottom: -0.5rem !important;
    }
    .ml-sm-n2,
    .mx-sm-n2 {
      margin-left: -0.5rem !important;
    }
    .m-sm-n3 {
      margin: -1rem !important;
    }
    .mt-sm-n3,
    .my-sm-n3 {
      margin-top: -1rem !important;
    }
    .mr-sm-n3,
    .mx-sm-n3 {
      margin-right: -1rem !important;
    }
    .mb-sm-n3,
    .my-sm-n3 {
      margin-bottom: -1rem !important;
    }
    .ml-sm-n3,
    .mx-sm-n3 {
      margin-left: -1rem !important;
    }
    .m-sm-n4 {
      margin: -1.5rem !important;
    }
    .mt-sm-n4,
    .my-sm-n4 {
      margin-top: -1.5rem !important;
    }
    .mr-sm-n4,
    .mx-sm-n4 {
      margin-right: -1.5rem !important;
    }
    .mb-sm-n4,
    .my-sm-n4 {
      margin-bottom: -1.5rem !important;
    }
    .ml-sm-n4,
    .mx-sm-n4 {
      margin-left: -1.5rem !important;
    }
    .m-sm-n5 {
      margin: -3rem !important;
    }
    .mt-sm-n5,
    .my-sm-n5 {
      margin-top: -3rem !important;
    }
    .mr-sm-n5,
    .mx-sm-n5 {
      margin-right: -3rem !important;
    }
    .mb-sm-n5,
    .my-sm-n5 {
      margin-bottom: -3rem !important;
    }
    .ml-sm-n5,
    .mx-sm-n5 {
      margin-left: -3rem !important;
    }
    .m-sm-n6 {
      margin: -5rem !important;
    }
    .mt-sm-n6,
    .my-sm-n6 {
      margin-top: -5rem !important;
    }
    .mr-sm-n6,
    .mx-sm-n6 {
      margin-right: -5rem !important;
    }
    .mb-sm-n6,
    .my-sm-n6 {
      margin-bottom: -5rem !important;
    }
    .ml-sm-n6,
    .mx-sm-n6 {
      margin-left: -5rem !important;
    }
    .m-sm-n7 {
      margin: -6rem !important;
    }
    .mt-sm-n7,
    .my-sm-n7 {
      margin-top: -6rem !important;
    }
    .mr-sm-n7,
    .mx-sm-n7 {
      margin-right: -6rem !important;
    }
    .mb-sm-n7,
    .my-sm-n7 {
      margin-bottom: -6rem !important;
    }
    .ml-sm-n7,
    .mx-sm-n7 {
      margin-left: -6rem !important;
    }
    .m-sm-n8 {
      margin: -8rem !important;
    }
    .mt-sm-n8,
    .my-sm-n8 {
      margin-top: -8rem !important;
    }
    .mr-sm-n8,
    .mx-sm-n8 {
      margin-right: -8rem !important;
    }
    .mb-sm-n8,
    .my-sm-n8 {
      margin-bottom: -8rem !important;
    }
    .ml-sm-n8,
    .mx-sm-n8 {
      margin-left: -8rem !important;
    }
    .m-sm-n9 {
      margin: -9rem !important;
    }
    .mt-sm-n9,
    .my-sm-n9 {
      margin-top: -9rem !important;
    }
    .mr-sm-n9,
    .mx-sm-n9 {
      margin-right: -9rem !important;
    }
    .mb-sm-n9,
    .my-sm-n9 {
      margin-bottom: -9rem !important;
    }
    .ml-sm-n9,
    .mx-sm-n9 {
      margin-left: -9rem !important;
    }
    .m-sm-auto {
      margin: auto !important;
    }
    .mt-sm-auto,
    .my-sm-auto {
      margin-top: auto !important;
    }
    .mr-sm-auto,
    .mx-sm-auto {
      margin-right: auto !important;
    }
    .mb-sm-auto,
    .my-sm-auto {
      margin-bottom: auto !important;
    }
    .ml-sm-auto,
    .mx-sm-auto {
      margin-left: auto !important;
    }
  }
  
  @media (min-width: 768px) {
    .m-md-0 {
      margin: 0 !important;
    }
    .mt-md-0,
    .my-md-0 {
      margin-top: 0 !important;
    }
    .mr-md-0,
    .mx-md-0 {
      margin-right: 0 !important;
    }
    .mb-md-0,
    .my-md-0 {
      margin-bottom: 0 !important;
    }
    .ml-md-0,
    .mx-md-0 {
      margin-left: 0 !important;
    }
    .m-md-1 {
      margin: 0.25rem !important;
    }
    .mt-md-1,
    .my-md-1 {
      margin-top: 0.25rem !important;
    }
    .mr-md-1,
    .mx-md-1 {
      margin-right: 0.25rem !important;
    }
    .mb-md-1,
    .my-md-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-md-1,
    .mx-md-1 {
      margin-left: 0.25rem !important;
    }
    .m-md-2 {
      margin: 0.5rem !important;
    }
    .mt-md-2,
    .my-md-2 {
      margin-top: 0.5rem !important;
    }
    .mr-md-2,
    .mx-md-2 {
      margin-right: 0.5rem !important;
    }
    .mb-md-2,
    .my-md-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-md-2,
    .mx-md-2 {
      margin-left: 0.5rem !important;
    }
    .m-md-3 {
      margin: 1rem !important;
    }
    .mt-md-3,
    .my-md-3 {
      margin-top: 1rem !important;
    }
    .mr-md-3,
    .mx-md-3 {
      margin-right: 1rem !important;
    }
    .mb-md-3,
    .my-md-3 {
      margin-bottom: 1rem !important;
    }
    .ml-md-3,
    .mx-md-3 {
      margin-left: 1rem !important;
    }
    .m-md-4 {
      margin: 1.5rem !important;
    }
    .mt-md-4,
    .my-md-4 {
      margin-top: 1.5rem !important;
    }
    .mr-md-4,
    .mx-md-4 {
      margin-right: 1.5rem !important;
    }
    .mb-md-4,
    .my-md-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-md-4,
    .mx-md-4 {
      margin-left: 1.5rem !important;
    }
    .m-md-5 {
      margin: 3rem !important;
    }
    .mt-md-5,
    .my-md-5 {
      margin-top: 3rem !important;
    }
    .mr-md-5,
    .mx-md-5 {
      margin-right: 3rem !important;
    }
    .mb-md-5,
    .my-md-5 {
      margin-bottom: 3rem !important;
    }
    .ml-md-5,
    .mx-md-5 {
      margin-left: 3rem !important;
    }
    .m-md-6 {
      margin: 5rem !important;
    }
    .mt-md-6,
    .my-md-6 {
      margin-top: 5rem !important;
    }
    .mr-md-6,
    .mx-md-6 {
      margin-right: 5rem !important;
    }
    .mb-md-6,
    .my-md-6 {
      margin-bottom: 5rem !important;
    }
    .ml-md-6,
    .mx-md-6 {
      margin-left: 5rem !important;
    }
    .m-md-7 {
      margin: 6rem !important;
    }
    .mt-md-7,
    .my-md-7 {
      margin-top: 6rem !important;
    }
    .mr-md-7,
    .mx-md-7 {
      margin-right: 6rem !important;
    }
    .mb-md-7,
    .my-md-7 {
      margin-bottom: 6rem !important;
    }
    .ml-md-7,
    .mx-md-7 {
      margin-left: 6rem !important;
    }
    .m-md-8 {
      margin: 8rem !important;
    }
    .mt-md-8,
    .my-md-8 {
      margin-top: 8rem !important;
    }
    .mr-md-8,
    .mx-md-8 {
      margin-right: 8rem !important;
    }
    .mb-md-8,
    .my-md-8 {
      margin-bottom: 8rem !important;
    }
    .ml-md-8,
    .mx-md-8 {
      margin-left: 8rem !important;
    }
    .m-md-9 {
      margin: 9rem !important;
    }
    .mt-md-9,
    .my-md-9 {
      margin-top: 9rem !important;
    }
    .mr-md-9,
    .mx-md-9 {
      margin-right: 9rem !important;
    }
    .mb-md-9,
    .my-md-9 {
      margin-bottom: 9rem !important;
    }
    .ml-md-9,
    .mx-md-9 {
      margin-left: 9rem !important;
    }
    .p-md-0 {
      padding: 0 !important;
    }
    .pt-md-0,
    .py-md-0 {
      padding-top: 0 !important;
    }
    .pr-md-0,
    .px-md-0 {
      padding-right: 0 !important;
    }
    .pb-md-0,
    .py-md-0 {
      padding-bottom: 0 !important;
    }
    .pl-md-0,
    .px-md-0 {
      padding-left: 0 !important;
    }
    .p-md-1 {
      padding: 0.25rem !important;
    }
    .pt-md-1,
    .py-md-1 {
      padding-top: 0.25rem !important;
    }
    .pr-md-1,
    .px-md-1 {
      padding-right: 0.25rem !important;
    }
    .pb-md-1,
    .py-md-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-md-1,
    .px-md-1 {
      padding-left: 0.25rem !important;
    }
    .p-md-2 {
      padding: 0.5rem !important;
    }
    .pt-md-2,
    .py-md-2 {
      padding-top: 0.5rem !important;
    }
    .pr-md-2,
    .px-md-2 {
      padding-right: 0.5rem !important;
    }
    .pb-md-2,
    .py-md-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-md-2,
    .px-md-2 {
      padding-left: 0.5rem !important;
    }
    .p-md-3 {
      padding: 1rem !important;
    }
    .pt-md-3,
    .py-md-3 {
      padding-top: 1rem !important;
    }
    .pr-md-3,
    .px-md-3 {
      padding-right: 1rem !important;
    }
    .pb-md-3,
    .py-md-3 {
      padding-bottom: 1rem !important;
    }
    .pl-md-3,
    .px-md-3 {
      padding-left: 1rem !important;
    }
    .p-md-4 {
      padding: 1.5rem !important;
    }
    .pt-md-4,
    .py-md-4 {
      padding-top: 1.5rem !important;
    }
    .pr-md-4,
    .px-md-4 {
      padding-right: 1.5rem !important;
    }
    .pb-md-4,
    .py-md-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-md-4,
    .px-md-4 {
      padding-left: 1.5rem !important;
    }
    .p-md-5 {
      padding: 3rem !important;
    }
    .pt-md-5,
    .py-md-5 {
      padding-top: 3rem !important;
    }
    .pr-md-5,
    .px-md-5 {
      padding-right: 3rem !important;
    }
    .pb-md-5,
    .py-md-5 {
      padding-bottom: 3rem !important;
    }
    .pl-md-5,
    .px-md-5 {
      padding-left: 3rem !important;
    }
    .p-md-6 {
      padding: 5rem !important;
    }
    .pt-md-6,
    .py-md-6 {
      padding-top: 5rem !important;
    }
    .pr-md-6,
    .px-md-6 {
      padding-right: 5rem !important;
    }
    .pb-md-6,
    .py-md-6 {
      padding-bottom: 5rem !important;
    }
    .pl-md-6,
    .px-md-6 {
      padding-left: 5rem !important;
    }
    .p-md-7 {
      padding: 6rem !important;
    }
    .pt-md-7,
    .py-md-7 {
      padding-top: 6rem !important;
    }
    .pr-md-7,
    .px-md-7 {
      padding-right: 6rem !important;
    }
    .pb-md-7,
    .py-md-7 {
      padding-bottom: 6rem !important;
    }
    .pl-md-7,
    .px-md-7 {
      padding-left: 6rem !important;
    }
    .p-md-8 {
      padding: 8rem !important;
    }
    .pt-md-8,
    .py-md-8 {
      padding-top: 8rem !important;
    }
    .pr-md-8,
    .px-md-8 {
      padding-right: 8rem !important;
    }
    .pb-md-8,
    .py-md-8 {
      padding-bottom: 8rem !important;
    }
    .pl-md-8,
    .px-md-8 {
      padding-left: 8rem !important;
    }
    .p-md-9 {
      padding: 9rem !important;
    }
    .pt-md-9,
    .py-md-9 {
      padding-top: 9rem !important;
    }
    .pr-md-9,
    .px-md-9 {
      padding-right: 9rem !important;
    }
    .pb-md-9,
    .py-md-9 {
      padding-bottom: 9rem !important;
    }
    .pl-md-9,
    .px-md-9 {
      padding-left: 9rem !important;
    }
    .m-md-n1 {
      margin: -0.25rem !important;
    }
    .mt-md-n1,
    .my-md-n1 {
      margin-top: -0.25rem !important;
    }
    .mr-md-n1,
    .mx-md-n1 {
      margin-right: -0.25rem !important;
    }
    .mb-md-n1,
    .my-md-n1 {
      margin-bottom: -0.25rem !important;
    }
    .ml-md-n1,
    .mx-md-n1 {
      margin-left: -0.25rem !important;
    }
    .m-md-n2 {
      margin: -0.5rem !important;
    }
    .mt-md-n2,
    .my-md-n2 {
      margin-top: -0.5rem !important;
    }
    .mr-md-n2,
    .mx-md-n2 {
      margin-right: -0.5rem !important;
    }
    .mb-md-n2,
    .my-md-n2 {
      margin-bottom: -0.5rem !important;
    }
    .ml-md-n2,
    .mx-md-n2 {
      margin-left: -0.5rem !important;
    }
    .m-md-n3 {
      margin: -1rem !important;
    }
    .mt-md-n3,
    .my-md-n3 {
      margin-top: -1rem !important;
    }
    .mr-md-n3,
    .mx-md-n3 {
      margin-right: -1rem !important;
    }
    .mb-md-n3,
    .my-md-n3 {
      margin-bottom: -1rem !important;
    }
    .ml-md-n3,
    .mx-md-n3 {
      margin-left: -1rem !important;
    }
    .m-md-n4 {
      margin: -1.5rem !important;
    }
    .mt-md-n4,
    .my-md-n4 {
      margin-top: -1.5rem !important;
    }
    .mr-md-n4,
    .mx-md-n4 {
      margin-right: -1.5rem !important;
    }
    .mb-md-n4,
    .my-md-n4 {
      margin-bottom: -1.5rem !important;
    }
    .ml-md-n4,
    .mx-md-n4 {
      margin-left: -1.5rem !important;
    }
    .m-md-n5 {
      margin: -3rem !important;
    }
    .mt-md-n5,
    .my-md-n5 {
      margin-top: -3rem !important;
    }
    .mr-md-n5,
    .mx-md-n5 {
      margin-right: -3rem !important;
    }
    .mb-md-n5,
    .my-md-n5 {
      margin-bottom: -3rem !important;
    }
    .ml-md-n5,
    .mx-md-n5 {
      margin-left: -3rem !important;
    }
    .m-md-n6 {
      margin: -5rem !important;
    }
    .mt-md-n6,
    .my-md-n6 {
      margin-top: -5rem !important;
    }
    .mr-md-n6,
    .mx-md-n6 {
      margin-right: -5rem !important;
    }
    .mb-md-n6,
    .my-md-n6 {
      margin-bottom: -5rem !important;
    }
    .ml-md-n6,
    .mx-md-n6 {
      margin-left: -5rem !important;
    }
    .m-md-n7 {
      margin: -6rem !important;
    }
    .mt-md-n7,
    .my-md-n7 {
      margin-top: -6rem !important;
    }
    .mr-md-n7,
    .mx-md-n7 {
      margin-right: -6rem !important;
    }
    .mb-md-n7,
    .my-md-n7 {
      margin-bottom: -6rem !important;
    }
    .ml-md-n7,
    .mx-md-n7 {
      margin-left: -6rem !important;
    }
    .m-md-n8 {
      margin: -8rem !important;
    }
    .mt-md-n8,
    .my-md-n8 {
      margin-top: -8rem !important;
    }
    .mr-md-n8,
    .mx-md-n8 {
      margin-right: -8rem !important;
    }
    .mb-md-n8,
    .my-md-n8 {
      margin-bottom: -8rem !important;
    }
    .ml-md-n8,
    .mx-md-n8 {
      margin-left: -8rem !important;
    }
    .m-md-n9 {
      margin: -9rem !important;
    }
    .mt-md-n9,
    .my-md-n9 {
      margin-top: -9rem !important;
    }
    .mr-md-n9,
    .mx-md-n9 {
      margin-right: -9rem !important;
    }
    .mb-md-n9,
    .my-md-n9 {
      margin-bottom: -9rem !important;
    }
    .ml-md-n9,
    .mx-md-n9 {
      margin-left: -9rem !important;
    }
    .m-md-auto {
      margin: auto !important;
    }
    .mt-md-auto,
    .my-md-auto {
      margin-top: auto !important;
    }
    .mr-md-auto,
    .mx-md-auto {
      margin-right: auto !important;
    }
    .mb-md-auto,
    .my-md-auto {
      margin-bottom: auto !important;
    }
    .ml-md-auto,
    .mx-md-auto {
      margin-left: auto !important;
    }
  }
  
  @media (min-width: 1024px) {
    .m-lg-0 {
      margin: 0 !important;
    }
    .mt-lg-0,
    .my-lg-0 {
      margin-top: 0 !important;
    }
    .mr-lg-0,
    .mx-lg-0 {
      margin-right: 0 !important;
    }
    .mb-lg-0,
    .my-lg-0 {
      margin-bottom: 0 !important;
    }
    .ml-lg-0,
    .mx-lg-0 {
      margin-left: 0 !important;
    }
    .m-lg-1 {
      margin: 0.25rem !important;
    }
    .mt-lg-1,
    .my-lg-1 {
      margin-top: 0.25rem !important;
    }
    .mr-lg-1,
    .mx-lg-1 {
      margin-right: 0.25rem !important;
    }
    .mb-lg-1,
    .my-lg-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-lg-1,
    .mx-lg-1 {
      margin-left: 0.25rem !important;
    }
    .m-lg-2 {
      margin: 0.5rem !important;
    }
    .mt-lg-2,
    .my-lg-2 {
      margin-top: 0.5rem !important;
    }
    .mr-lg-2,
    .mx-lg-2 {
      margin-right: 0.5rem !important;
    }
    .mb-lg-2,
    .my-lg-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-lg-2,
    .mx-lg-2 {
      margin-left: 0.5rem !important;
    }
    .m-lg-3 {
      margin: 1rem !important;
    }
    .mt-lg-3,
    .my-lg-3 {
      margin-top: 1rem !important;
    }
    .mr-lg-3,
    .mx-lg-3 {
      margin-right: 1rem !important;
    }
    .mb-lg-3,
    .my-lg-3 {
      margin-bottom: 1rem !important;
    }
    .ml-lg-3,
    .mx-lg-3 {
      margin-left: 1rem !important;
    }
    .m-lg-4 {
      margin: 1.5rem !important;
    }
    .mt-lg-4,
    .my-lg-4 {
      margin-top: 1.5rem !important;
    }
    .mr-lg-4,
    .mx-lg-4 {
      margin-right: 1.5rem !important;
    }
    .mb-lg-4,
    .my-lg-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-lg-4,
    .mx-lg-4 {
      margin-left: 1.5rem !important;
    }
    .m-lg-5 {
      margin: 3rem !important;
    }
    .mt-lg-5,
    .my-lg-5 {
      margin-top: 3rem !important;
    }
    .mr-lg-5,
    .mx-lg-5 {
      margin-right: 3rem !important;
    }
    .mb-lg-5,
    .my-lg-5 {
      margin-bottom: 3rem !important;
    }
    .ml-lg-5,
    .mx-lg-5 {
      margin-left: 3rem !important;
    }
    .m-lg-6 {
      margin: 5rem !important;
    }
    .mt-lg-6,
    .my-lg-6 {
      margin-top: 5rem !important;
    }
    .mr-lg-6,
    .mx-lg-6 {
      margin-right: 5rem !important;
    }
    .mb-lg-6,
    .my-lg-6 {
      margin-bottom: 5rem !important;
    }
    .ml-lg-6,
    .mx-lg-6 {
      margin-left: 5rem !important;
    }
    .m-lg-7 {
      margin: 6rem !important;
    }
    .mt-lg-7,
    .my-lg-7 {
      margin-top: 6rem !important;
    }
    .mr-lg-7,
    .mx-lg-7 {
      margin-right: 6rem !important;
    }
    .mb-lg-7,
    .my-lg-7 {
      margin-bottom: 6rem !important;
    }
    .ml-lg-7,
    .mx-lg-7 {
      margin-left: 6rem !important;
    }
    .m-lg-8 {
      margin: 8rem !important;
    }
    .mt-lg-8,
    .my-lg-8 {
      margin-top: 8rem !important;
    }
    .mr-lg-8,
    .mx-lg-8 {
      margin-right: 8rem !important;
    }
    .mb-lg-8,
    .my-lg-8 {
      margin-bottom: 8rem !important;
    }
    .ml-lg-8,
    .mx-lg-8 {
      margin-left: 8rem !important;
    }
    .m-lg-9 {
      margin: 9rem !important;
    }
    .mt-lg-9,
    .my-lg-9 {
      margin-top: 9rem !important;
    }
    .mr-lg-9,
    .mx-lg-9 {
      margin-right: 9rem !important;
    }
    .mb-lg-9,
    .my-lg-9 {
      margin-bottom: 9rem !important;
    }
    .ml-lg-9,
    .mx-lg-9 {
      margin-left: 9rem !important;
    }
    .p-lg-0 {
      padding: 0 !important;
    }
    .pt-lg-0,
    .py-lg-0 {
      padding-top: 0 !important;
    }
    .pr-lg-0,
    .px-lg-0 {
      padding-right: 0 !important;
    }
    .pb-lg-0,
    .py-lg-0 {
      padding-bottom: 0 !important;
    }
    .pl-lg-0,
    .px-lg-0 {
      padding-left: 0 !important;
    }
    .p-lg-1 {
      padding: 0.25rem !important;
    }
    .pt-lg-1,
    .py-lg-1 {
      padding-top: 0.25rem !important;
    }
    .pr-lg-1,
    .px-lg-1 {
      padding-right: 0.25rem !important;
    }
    .pb-lg-1,
    .py-lg-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-lg-1,
    .px-lg-1 {
      padding-left: 0.25rem !important;
    }
    .p-lg-2 {
      padding: 0.5rem !important;
    }
    .pt-lg-2,
    .py-lg-2 {
      padding-top: 0.5rem !important;
    }
    .pr-lg-2,
    .px-lg-2 {
      padding-right: 0.5rem !important;
    }
    .pb-lg-2,
    .py-lg-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-lg-2,
    .px-lg-2 {
      padding-left: 0.5rem !important;
    }
    .p-lg-3 {
      padding: 1rem !important;
    }
    .pt-lg-3,
    .py-lg-3 {
      padding-top: 1rem !important;
    }
    .pr-lg-3,
    .px-lg-3 {
      padding-right: 1rem !important;
    }
    .pb-lg-3,
    .py-lg-3 {
      padding-bottom: 1rem !important;
    }
    .pl-lg-3,
    .px-lg-3 {
      padding-left: 1rem !important;
    }
    .p-lg-4 {
      padding: 1.5rem !important;
    }
    .pt-lg-4,
    .py-lg-4 {
      padding-top: 1.5rem !important;
    }
    .pr-lg-4,
    .px-lg-4 {
      padding-right: 1.5rem !important;
    }
    .pb-lg-4,
    .py-lg-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-lg-4,
    .px-lg-4 {
      padding-left: 1.5rem !important;
    }
    .p-lg-5 {
      padding: 3rem !important;
    }
    .pt-lg-5,
    .py-lg-5 {
      padding-top: 3rem !important;
    }
    .pr-lg-5,
    .px-lg-5 {
      padding-right: 3rem !important;
    }
    .pb-lg-5,
    .py-lg-5 {
      padding-bottom: 3rem !important;
    }
    .pl-lg-5,
    .px-lg-5 {
      padding-left: 3rem !important;
    }
    .p-lg-6 {
      padding: 5rem !important;
    }
    .pt-lg-6,
    .py-lg-6 {
      padding-top: 5rem !important;
    }
    .pr-lg-6,
    .px-lg-6 {
      padding-right: 5rem !important;
    }
    .pb-lg-6,
    .py-lg-6 {
      padding-bottom: 5rem !important;
    }
    .pl-lg-6,
    .px-lg-6 {
      padding-left: 5rem !important;
    }
    .p-lg-7 {
      padding: 6rem !important;
    }
    .pt-lg-7,
    .py-lg-7 {
      padding-top: 6rem !important;
    }
    .pr-lg-7,
    .px-lg-7 {
      padding-right: 6rem !important;
    }
    .pb-lg-7,
    .py-lg-7 {
      padding-bottom: 6rem !important;
    }
    .pl-lg-7,
    .px-lg-7 {
      padding-left: 6rem !important;
    }
    .p-lg-8 {
      padding: 8rem !important;
    }
    .pt-lg-8,
    .py-lg-8 {
      padding-top: 8rem !important;
    }
    .pr-lg-8,
    .px-lg-8 {
      padding-right: 8rem !important;
    }
    .pb-lg-8,
    .py-lg-8 {
      padding-bottom: 8rem !important;
    }
    .pl-lg-8,
    .px-lg-8 {
      padding-left: 8rem !important;
    }
    .p-lg-9 {
      padding: 9rem !important;
    }
    .pt-lg-9,
    .py-lg-9 {
      padding-top: 9rem !important;
    }
    .pr-lg-9,
    .px-lg-9 {
      padding-right: 9rem !important;
    }
    .pb-lg-9,
    .py-lg-9 {
      padding-bottom: 9rem !important;
    }
    .pl-lg-9,
    .px-lg-9 {
      padding-left: 9rem !important;
    }
    .m-lg-n1 {
      margin: -0.25rem !important;
    }
    .mt-lg-n1,
    .my-lg-n1 {
      margin-top: -0.25rem !important;
    }
    .mr-lg-n1,
    .mx-lg-n1 {
      margin-right: -0.25rem !important;
    }
    .mb-lg-n1,
    .my-lg-n1 {
      margin-bottom: -0.25rem !important;
    }
    .ml-lg-n1,
    .mx-lg-n1 {
      margin-left: -0.25rem !important;
    }
    .m-lg-n2 {
      margin: -0.5rem !important;
    }
    .mt-lg-n2,
    .my-lg-n2 {
      margin-top: -0.5rem !important;
    }
    .mr-lg-n2,
    .mx-lg-n2 {
      margin-right: -0.5rem !important;
    }
    .mb-lg-n2,
    .my-lg-n2 {
      margin-bottom: -0.5rem !important;
    }
    .ml-lg-n2,
    .mx-lg-n2 {
      margin-left: -0.5rem !important;
    }
    .m-lg-n3 {
      margin: -1rem !important;
    }
    .mt-lg-n3,
    .my-lg-n3 {
      margin-top: -1rem !important;
    }
    .mr-lg-n3,
    .mx-lg-n3 {
      margin-right: -1rem !important;
    }
    .mb-lg-n3,
    .my-lg-n3 {
      margin-bottom: -1rem !important;
    }
    .ml-lg-n3,
    .mx-lg-n3 {
      margin-left: -1rem !important;
    }
    .m-lg-n4 {
      margin: -1.5rem !important;
    }
    .mt-lg-n4,
    .my-lg-n4 {
      margin-top: -1.5rem !important;
    }
    .mr-lg-n4,
    .mx-lg-n4 {
      margin-right: -1.5rem !important;
    }
    .mb-lg-n4,
    .my-lg-n4 {
      margin-bottom: -1.5rem !important;
    }
    .ml-lg-n4,
    .mx-lg-n4 {
      margin-left: -1.5rem !important;
    }
    .m-lg-n5 {
      margin: -3rem !important;
    }
    .mt-lg-n5,
    .my-lg-n5 {
      margin-top: -3rem !important;
    }
    .mr-lg-n5,
    .mx-lg-n5 {
      margin-right: -3rem !important;
    }
    .mb-lg-n5,
    .my-lg-n5 {
      margin-bottom: -3rem !important;
    }
    .ml-lg-n5,
    .mx-lg-n5 {
      margin-left: -3rem !important;
    }
    .m-lg-n6 {
      margin: -5rem !important;
    }
    .mt-lg-n6,
    .my-lg-n6 {
      margin-top: -5rem !important;
    }
    .mr-lg-n6,
    .mx-lg-n6 {
      margin-right: -5rem !important;
    }
    .mb-lg-n6,
    .my-lg-n6 {
      margin-bottom: -5rem !important;
    }
    .ml-lg-n6,
    .mx-lg-n6 {
      margin-left: -5rem !important;
    }
    .m-lg-n7 {
      margin: -6rem !important;
    }
    .mt-lg-n7,
    .my-lg-n7 {
      margin-top: -6rem !important;
    }
    .mr-lg-n7,
    .mx-lg-n7 {
      margin-right: -6rem !important;
    }
    .mb-lg-n7,
    .my-lg-n7 {
      margin-bottom: -6rem !important;
    }
    .ml-lg-n7,
    .mx-lg-n7 {
      margin-left: -6rem !important;
    }
    .m-lg-n8 {
      margin: -8rem !important;
    }
    .mt-lg-n8,
    .my-lg-n8 {
      margin-top: -8rem !important;
    }
    .mr-lg-n8,
    .mx-lg-n8 {
      margin-right: -8rem !important;
    }
    .mb-lg-n8,
    .my-lg-n8 {
      margin-bottom: -8rem !important;
    }
    .ml-lg-n8,
    .mx-lg-n8 {
      margin-left: -8rem !important;
    }
    .m-lg-n9 {
      margin: -9rem !important;
    }
    .mt-lg-n9,
    .my-lg-n9 {
      margin-top: -9rem !important;
    }
    .mr-lg-n9,
    .mx-lg-n9 {
      margin-right: -9rem !important;
    }
    .mb-lg-n9,
    .my-lg-n9 {
      margin-bottom: -9rem !important;
    }
    .ml-lg-n9,
    .mx-lg-n9 {
      margin-left: -9rem !important;
    }
    .m-lg-auto {
      margin: auto !important;
    }
    .mt-lg-auto,
    .my-lg-auto {
      margin-top: auto !important;
    }
    .mr-lg-auto,
    .mx-lg-auto {
      margin-right: auto !important;
    }
    .mb-lg-auto,
    .my-lg-auto {
      margin-bottom: auto !important;
    }
    .ml-lg-auto,
    .mx-lg-auto {
      margin-left: auto !important;
    }
  }
  
  @media (min-width: 1440px) {
    .m-xl-0 {
      margin: 0 !important;
    }
    .mt-xl-0,
    .my-xl-0 {
      margin-top: 0 !important;
    }
    .mr-xl-0,
    .mx-xl-0 {
      margin-right: 0 !important;
    }
    .mb-xl-0,
    .my-xl-0 {
      margin-bottom: 0 !important;
    }
    .ml-xl-0,
    .mx-xl-0 {
      margin-left: 0 !important;
    }
    .m-xl-1 {
      margin: 0.25rem !important;
    }
    .mt-xl-1,
    .my-xl-1 {
      margin-top: 0.25rem !important;
    }
    .mr-xl-1,
    .mx-xl-1 {
      margin-right: 0.25rem !important;
    }
    .mb-xl-1,
    .my-xl-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-xl-1,
    .mx-xl-1 {
      margin-left: 0.25rem !important;
    }
    .m-xl-2 {
      margin: 0.5rem !important;
    }
    .mt-xl-2,
    .my-xl-2 {
      margin-top: 0.5rem !important;
    }
    .mr-xl-2,
    .mx-xl-2 {
      margin-right: 0.5rem !important;
    }
    .mb-xl-2,
    .my-xl-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-xl-2,
    .mx-xl-2 {
      margin-left: 0.5rem !important;
    }
    .m-xl-3 {
      margin: 1rem !important;
    }
    .mt-xl-3,
    .my-xl-3 {
      margin-top: 1rem !important;
    }
    .mr-xl-3,
    .mx-xl-3 {
      margin-right: 1rem !important;
    }
    .mb-xl-3,
    .my-xl-3 {
      margin-bottom: 1rem !important;
    }
    .ml-xl-3,
    .mx-xl-3 {
      margin-left: 1rem !important;
    }
    .m-xl-4 {
      margin: 1.5rem !important;
    }
    .mt-xl-4,
    .my-xl-4 {
      margin-top: 1.5rem !important;
    }
    .mr-xl-4,
    .mx-xl-4 {
      margin-right: 1.5rem !important;
    }
    .mb-xl-4,
    .my-xl-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-xl-4,
    .mx-xl-4 {
      margin-left: 1.5rem !important;
    }
    .m-xl-5 {
      margin: 3rem !important;
    }
    .mt-xl-5,
    .my-xl-5 {
      margin-top: 3rem !important;
    }
    .mr-xl-5,
    .mx-xl-5 {
      margin-right: 3rem !important;
    }
    .mb-xl-5,
    .my-xl-5 {
      margin-bottom: 3rem !important;
    }
    .ml-xl-5,
    .mx-xl-5 {
      margin-left: 3rem !important;
    }
    .m-xl-6 {
      margin: 5rem !important;
    }
    .mt-xl-6,
    .my-xl-6 {
      margin-top: 5rem !important;
    }
    .mr-xl-6,
    .mx-xl-6 {
      margin-right: 5rem !important;
    }
    .mb-xl-6,
    .my-xl-6 {
      margin-bottom: 5rem !important;
    }
    .ml-xl-6,
    .mx-xl-6 {
      margin-left: 5rem !important;
    }
    .m-xl-7 {
      margin: 6rem !important;
    }
    .mt-xl-7,
    .my-xl-7 {
      margin-top: 6rem !important;
    }
    .mr-xl-7,
    .mx-xl-7 {
      margin-right: 6rem !important;
    }
    .mb-xl-7,
    .my-xl-7 {
      margin-bottom: 6rem !important;
    }
    .ml-xl-7,
    .mx-xl-7 {
      margin-left: 6rem !important;
    }
    .m-xl-8 {
      margin: 8rem !important;
    }
    .mt-xl-8,
    .my-xl-8 {
      margin-top: 8rem !important;
    }
    .mr-xl-8,
    .mx-xl-8 {
      margin-right: 8rem !important;
    }
    .mb-xl-8,
    .my-xl-8 {
      margin-bottom: 8rem !important;
    }
    .ml-xl-8,
    .mx-xl-8 {
      margin-left: 8rem !important;
    }
    .m-xl-9 {
      margin: 9rem !important;
    }
    .mt-xl-9,
    .my-xl-9 {
      margin-top: 9rem !important;
    }
    .mr-xl-9,
    .mx-xl-9 {
      margin-right: 9rem !important;
    }
    .mb-xl-9,
    .my-xl-9 {
      margin-bottom: 9rem !important;
    }
    .ml-xl-9,
    .mx-xl-9 {
      margin-left: 9rem !important;
    }
    .p-xl-0 {
      padding: 0 !important;
    }
    .pt-xl-0,
    .py-xl-0 {
      padding-top: 0 !important;
    }
    .pr-xl-0,
    .px-xl-0 {
      padding-right: 0 !important;
    }
    .pb-xl-0,
    .py-xl-0 {
      padding-bottom: 0 !important;
    }
    .pl-xl-0,
    .px-xl-0 {
      padding-left: 0 !important;
    }
    .p-xl-1 {
      padding: 0.25rem !important;
    }
    .pt-xl-1,
    .py-xl-1 {
      padding-top: 0.25rem !important;
    }
    .pr-xl-1,
    .px-xl-1 {
      padding-right: 0.25rem !important;
    }
    .pb-xl-1,
    .py-xl-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-xl-1,
    .px-xl-1 {
      padding-left: 0.25rem !important;
    }
    .p-xl-2 {
      padding: 0.5rem !important;
    }
    .pt-xl-2,
    .py-xl-2 {
      padding-top: 0.5rem !important;
    }
    .pr-xl-2,
    .px-xl-2 {
      padding-right: 0.5rem !important;
    }
    .pb-xl-2,
    .py-xl-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-xl-2,
    .px-xl-2 {
      padding-left: 0.5rem !important;
    }
    .p-xl-3 {
      padding: 1rem !important;
    }
    .pt-xl-3,
    .py-xl-3 {
      padding-top: 1rem !important;
    }
    .pr-xl-3,
    .px-xl-3 {
      padding-right: 1rem !important;
    }
    .pb-xl-3,
    .py-xl-3 {
      padding-bottom: 1rem !important;
    }
    .pl-xl-3,
    .px-xl-3 {
      padding-left: 1rem !important;
    }
    .p-xl-4 {
      padding: 1.5rem !important;
    }
    .pt-xl-4,
    .py-xl-4 {
      padding-top: 1.5rem !important;
    }
    .pr-xl-4,
    .px-xl-4 {
      padding-right: 1.5rem !important;
    }
    .pb-xl-4,
    .py-xl-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-xl-4,
    .px-xl-4 {
      padding-left: 1.5rem !important;
    }
    .p-xl-5 {
      padding: 3rem !important;
    }
    .pt-xl-5,
    .py-xl-5 {
      padding-top: 3rem !important;
    }
    .pr-xl-5,
    .px-xl-5 {
      padding-right: 3rem !important;
    }
    .pb-xl-5,
    .py-xl-5 {
      padding-bottom: 3rem !important;
    }
    .pl-xl-5,
    .px-xl-5 {
      padding-left: 3rem !important;
    }
    .p-xl-6 {
      padding: 5rem !important;
    }
    .pt-xl-6,
    .py-xl-6 {
      padding-top: 5rem !important;
    }
    .pr-xl-6,
    .px-xl-6 {
      padding-right: 5rem !important;
    }
    .pb-xl-6,
    .py-xl-6 {
      padding-bottom: 5rem !important;
    }
    .pl-xl-6,
    .px-xl-6 {
      padding-left: 5rem !important;
    }
    .p-xl-7 {
      padding: 6rem !important;
    }
    .pt-xl-7,
    .py-xl-7 {
      padding-top: 6rem !important;
    }
    .pr-xl-7,
    .px-xl-7 {
      padding-right: 6rem !important;
    }
    .pb-xl-7,
    .py-xl-7 {
      padding-bottom: 6rem !important;
    }
    .pl-xl-7,
    .px-xl-7 {
      padding-left: 6rem !important;
    }
    .p-xl-8 {
      padding: 8rem !important;
    }
    .pt-xl-8,
    .py-xl-8 {
      padding-top: 8rem !important;
    }
    .pr-xl-8,
    .px-xl-8 {
      padding-right: 8rem !important;
    }
    .pb-xl-8,
    .py-xl-8 {
      padding-bottom: 8rem !important;
    }
    .pl-xl-8,
    .px-xl-8 {
      padding-left: 8rem !important;
    }
    .p-xl-9 {
      padding: 9rem !important;
    }
    .pt-xl-9,
    .py-xl-9 {
      padding-top: 9rem !important;
    }
    .pr-xl-9,
    .px-xl-9 {
      padding-right: 9rem !important;
    }
    .pb-xl-9,
    .py-xl-9 {
      padding-bottom: 9rem !important;
    }
    .pl-xl-9,
    .px-xl-9 {
      padding-left: 9rem !important;
    }
    .m-xl-n1 {
      margin: -0.25rem !important;
    }
    .mt-xl-n1,
    .my-xl-n1 {
      margin-top: -0.25rem !important;
    }
    .mr-xl-n1,
    .mx-xl-n1 {
      margin-right: -0.25rem !important;
    }
    .mb-xl-n1,
    .my-xl-n1 {
      margin-bottom: -0.25rem !important;
    }
    .ml-xl-n1,
    .mx-xl-n1 {
      margin-left: -0.25rem !important;
    }
    .m-xl-n2 {
      margin: -0.5rem !important;
    }
    .mt-xl-n2,
    .my-xl-n2 {
      margin-top: -0.5rem !important;
    }
    .mr-xl-n2,
    .mx-xl-n2 {
      margin-right: -0.5rem !important;
    }
    .mb-xl-n2,
    .my-xl-n2 {
      margin-bottom: -0.5rem !important;
    }
    .ml-xl-n2,
    .mx-xl-n2 {
      margin-left: -0.5rem !important;
    }
    .m-xl-n3 {
      margin: -1rem !important;
    }
    .mt-xl-n3,
    .my-xl-n3 {
      margin-top: -1rem !important;
    }
    .mr-xl-n3,
    .mx-xl-n3 {
      margin-right: -1rem !important;
    }
    .mb-xl-n3,
    .my-xl-n3 {
      margin-bottom: -1rem !important;
    }
    .ml-xl-n3,
    .mx-xl-n3 {
      margin-left: -1rem !important;
    }
    .m-xl-n4 {
      margin: -1.5rem !important;
    }
    .mt-xl-n4,
    .my-xl-n4 {
      margin-top: -1.5rem !important;
    }
    .mr-xl-n4,
    .mx-xl-n4 {
      margin-right: -1.5rem !important;
    }
    .mb-xl-n4,
    .my-xl-n4 {
      margin-bottom: -1.5rem !important;
    }
    .ml-xl-n4,
    .mx-xl-n4 {
      margin-left: -1.5rem !important;
    }
    .m-xl-n5 {
      margin: -3rem !important;
    }
    .mt-xl-n5,
    .my-xl-n5 {
      margin-top: -3rem !important;
    }
    .mr-xl-n5,
    .mx-xl-n5 {
      margin-right: -3rem !important;
    }
    .mb-xl-n5,
    .my-xl-n5 {
      margin-bottom: -3rem !important;
    }
    .ml-xl-n5,
    .mx-xl-n5 {
      margin-left: -3rem !important;
    }
    .m-xl-n6 {
      margin: -5rem !important;
    }
    .mt-xl-n6,
    .my-xl-n6 {
      margin-top: -5rem !important;
    }
    .mr-xl-n6,
    .mx-xl-n6 {
      margin-right: -5rem !important;
    }
    .mb-xl-n6,
    .my-xl-n6 {
      margin-bottom: -5rem !important;
    }
    .ml-xl-n6,
    .mx-xl-n6 {
      margin-left: -5rem !important;
    }
    .m-xl-n7 {
      margin: -6rem !important;
    }
    .mt-xl-n7,
    .my-xl-n7 {
      margin-top: -6rem !important;
    }
    .mr-xl-n7,
    .mx-xl-n7 {
      margin-right: -6rem !important;
    }
    .mb-xl-n7,
    .my-xl-n7 {
      margin-bottom: -6rem !important;
    }
    .ml-xl-n7,
    .mx-xl-n7 {
      margin-left: -6rem !important;
    }
    .m-xl-n8 {
      margin: -8rem !important;
    }
    .mt-xl-n8,
    .my-xl-n8 {
      margin-top: -8rem !important;
    }
    .mr-xl-n8,
    .mx-xl-n8 {
      margin-right: -8rem !important;
    }
    .mb-xl-n8,
    .my-xl-n8 {
      margin-bottom: -8rem !important;
    }
    .ml-xl-n8,
    .mx-xl-n8 {
      margin-left: -8rem !important;
    }
    .m-xl-n9 {
      margin: -9rem !important;
    }
    .mt-xl-n9,
    .my-xl-n9 {
      margin-top: -9rem !important;
    }
    .mr-xl-n9,
    .mx-xl-n9 {
      margin-right: -9rem !important;
    }
    .mb-xl-n9,
    .my-xl-n9 {
      margin-bottom: -9rem !important;
    }
    .ml-xl-n9,
    .mx-xl-n9 {
      margin-left: -9rem !important;
    }
    .m-xl-auto {
      margin: auto !important;
    }
    .mt-xl-auto,
    .my-xl-auto {
      margin-top: auto !important;
    }
    .mr-xl-auto,
    .mx-xl-auto {
      margin-right: auto !important;
    }
    .mb-xl-auto,
    .my-xl-auto {
      margin-bottom: auto !important;
    }
    .ml-xl-auto,
    .mx-xl-auto {
      margin-left: auto !important;
    }
  }
  
  @media (min-width: 1680px) {
    .m-xxl-0 {
      margin: 0 !important;
    }
    .mt-xxl-0,
    .my-xxl-0 {
      margin-top: 0 !important;
    }
    .mr-xxl-0,
    .mx-xxl-0 {
      margin-right: 0 !important;
    }
    .mb-xxl-0,
    .my-xxl-0 {
      margin-bottom: 0 !important;
    }
    .ml-xxl-0,
    .mx-xxl-0 {
      margin-left: 0 !important;
    }
    .m-xxl-1 {
      margin: 0.25rem !important;
    }
    .mt-xxl-1,
    .my-xxl-1 {
      margin-top: 0.25rem !important;
    }
    .mr-xxl-1,
    .mx-xxl-1 {
      margin-right: 0.25rem !important;
    }
    .mb-xxl-1,
    .my-xxl-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-xxl-1,
    .mx-xxl-1 {
      margin-left: 0.25rem !important;
    }
    .m-xxl-2 {
      margin: 0.5rem !important;
    }
    .mt-xxl-2,
    .my-xxl-2 {
      margin-top: 0.5rem !important;
    }
    .mr-xxl-2,
    .mx-xxl-2 {
      margin-right: 0.5rem !important;
    }
    .mb-xxl-2,
    .my-xxl-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-xxl-2,
    .mx-xxl-2 {
      margin-left: 0.5rem !important;
    }
    .m-xxl-3 {
      margin: 1rem !important;
    }
    .mt-xxl-3,
    .my-xxl-3 {
      margin-top: 1rem !important;
    }
    .mr-xxl-3,
    .mx-xxl-3 {
      margin-right: 1rem !important;
    }
    .mb-xxl-3,
    .my-xxl-3 {
      margin-bottom: 1rem !important;
    }
    .ml-xxl-3,
    .mx-xxl-3 {
      margin-left: 1rem !important;
    }
    .m-xxl-4 {
      margin: 1.5rem !important;
    }
    .mt-xxl-4,
    .my-xxl-4 {
      margin-top: 1.5rem !important;
    }
    .mr-xxl-4,
    .mx-xxl-4 {
      margin-right: 1.5rem !important;
    }
    .mb-xxl-4,
    .my-xxl-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-xxl-4,
    .mx-xxl-4 {
      margin-left: 1.5rem !important;
    }
    .m-xxl-5 {
      margin: 3rem !important;
    }
    .mt-xxl-5,
    .my-xxl-5 {
      margin-top: 3rem !important;
    }
    .mr-xxl-5,
    .mx-xxl-5 {
      margin-right: 3rem !important;
    }
    .mb-xxl-5,
    .my-xxl-5 {
      margin-bottom: 3rem !important;
    }
    .ml-xxl-5,
    .mx-xxl-5 {
      margin-left: 3rem !important;
    }
    .m-xxl-6 {
      margin: 5rem !important;
    }
    .mt-xxl-6,
    .my-xxl-6 {
      margin-top: 5rem !important;
    }
    .mr-xxl-6,
    .mx-xxl-6 {
      margin-right: 5rem !important;
    }
    .mb-xxl-6,
    .my-xxl-6 {
      margin-bottom: 5rem !important;
    }
    .ml-xxl-6,
    .mx-xxl-6 {
      margin-left: 5rem !important;
    }
    .m-xxl-7 {
      margin: 6rem !important;
    }
    .mt-xxl-7,
    .my-xxl-7 {
      margin-top: 6rem !important;
    }
    .mr-xxl-7,
    .mx-xxl-7 {
      margin-right: 6rem !important;
    }
    .mb-xxl-7,
    .my-xxl-7 {
      margin-bottom: 6rem !important;
    }
    .ml-xxl-7,
    .mx-xxl-7 {
      margin-left: 6rem !important;
    }
    .m-xxl-8 {
      margin: 8rem !important;
    }
    .mt-xxl-8,
    .my-xxl-8 {
      margin-top: 8rem !important;
    }
    .mr-xxl-8,
    .mx-xxl-8 {
      margin-right: 8rem !important;
    }
    .mb-xxl-8,
    .my-xxl-8 {
      margin-bottom: 8rem !important;
    }
    .ml-xxl-8,
    .mx-xxl-8 {
      margin-left: 8rem !important;
    }
    .m-xxl-9 {
      margin: 9rem !important;
    }
    .mt-xxl-9,
    .my-xxl-9 {
      margin-top: 9rem !important;
    }
    .mr-xxl-9,
    .mx-xxl-9 {
      margin-right: 9rem !important;
    }
    .mb-xxl-9,
    .my-xxl-9 {
      margin-bottom: 9rem !important;
    }
    .ml-xxl-9,
    .mx-xxl-9 {
      margin-left: 9rem !important;
    }
    .p-xxl-0 {
      padding: 0 !important;
    }
    .pt-xxl-0,
    .py-xxl-0 {
      padding-top: 0 !important;
    }
    .pr-xxl-0,
    .px-xxl-0 {
      padding-right: 0 !important;
    }
    .pb-xxl-0,
    .py-xxl-0 {
      padding-bottom: 0 !important;
    }
    .pl-xxl-0,
    .px-xxl-0 {
      padding-left: 0 !important;
    }
    .p-xxl-1 {
      padding: 0.25rem !important;
    }
    .pt-xxl-1,
    .py-xxl-1 {
      padding-top: 0.25rem !important;
    }
    .pr-xxl-1,
    .px-xxl-1 {
      padding-right: 0.25rem !important;
    }
    .pb-xxl-1,
    .py-xxl-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-xxl-1,
    .px-xxl-1 {
      padding-left: 0.25rem !important;
    }
    .p-xxl-2 {
      padding: 0.5rem !important;
    }
    .pt-xxl-2,
    .py-xxl-2 {
      padding-top: 0.5rem !important;
    }
    .pr-xxl-2,
    .px-xxl-2 {
      padding-right: 0.5rem !important;
    }
    .pb-xxl-2,
    .py-xxl-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-xxl-2,
    .px-xxl-2 {
      padding-left: 0.5rem !important;
    }
    .p-xxl-3 {
      padding: 1rem !important;
    }
    .pt-xxl-3,
    .py-xxl-3 {
      padding-top: 1rem !important;
    }
    .pr-xxl-3,
    .px-xxl-3 {
      padding-right: 1rem !important;
    }
    .pb-xxl-3,
    .py-xxl-3 {
      padding-bottom: 1rem !important;
    }
    .pl-xxl-3,
    .px-xxl-3 {
      padding-left: 1rem !important;
    }
    .p-xxl-4 {
      padding: 1.5rem !important;
    }
    .pt-xxl-4,
    .py-xxl-4 {
      padding-top: 1.5rem !important;
    }
    .pr-xxl-4,
    .px-xxl-4 {
      padding-right: 1.5rem !important;
    }
    .pb-xxl-4,
    .py-xxl-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-xxl-4,
    .px-xxl-4 {
      padding-left: 1.5rem !important;
    }
    .p-xxl-5 {
      padding: 3rem !important;
    }
    .pt-xxl-5,
    .py-xxl-5 {
      padding-top: 3rem !important;
    }
    .pr-xxl-5,
    .px-xxl-5 {
      padding-right: 3rem !important;
    }
    .pb-xxl-5,
    .py-xxl-5 {
      padding-bottom: 3rem !important;
    }
    .pl-xxl-5,
    .px-xxl-5 {
      padding-left: 3rem !important;
    }
    .p-xxl-6 {
      padding: 5rem !important;
    }
    .pt-xxl-6,
    .py-xxl-6 {
      padding-top: 5rem !important;
    }
    .pr-xxl-6,
    .px-xxl-6 {
      padding-right: 5rem !important;
    }
    .pb-xxl-6,
    .py-xxl-6 {
      padding-bottom: 5rem !important;
    }
    .pl-xxl-6,
    .px-xxl-6 {
      padding-left: 5rem !important;
    }
    .p-xxl-7 {
      padding: 6rem !important;
    }
    .pt-xxl-7,
    .py-xxl-7 {
      padding-top: 6rem !important;
    }
    .pr-xxl-7,
    .px-xxl-7 {
      padding-right: 6rem !important;
    }
    .pb-xxl-7,
    .py-xxl-7 {
      padding-bottom: 6rem !important;
    }
    .pl-xxl-7,
    .px-xxl-7 {
      padding-left: 6rem !important;
    }
    .p-xxl-8 {
      padding: 8rem !important;
    }
    .pt-xxl-8,
    .py-xxl-8 {
      padding-top: 8rem !important;
    }
    .pr-xxl-8,
    .px-xxl-8 {
      padding-right: 8rem !important;
    }
    .pb-xxl-8,
    .py-xxl-8 {
      padding-bottom: 8rem !important;
    }
    .pl-xxl-8,
    .px-xxl-8 {
      padding-left: 8rem !important;
    }
    .p-xxl-9 {
      padding: 9rem !important;
    }
    .pt-xxl-9,
    .py-xxl-9 {
      padding-top: 9rem !important;
    }
    .pr-xxl-9,
    .px-xxl-9 {
      padding-right: 9rem !important;
    }
    .pb-xxl-9,
    .py-xxl-9 {
      padding-bottom: 9rem !important;
    }
    .pl-xxl-9,
    .px-xxl-9 {
      padding-left: 9rem !important;
    }
    .m-xxl-n1 {
      margin: -0.25rem !important;
    }
    .mt-xxl-n1,
    .my-xxl-n1 {
      margin-top: -0.25rem !important;
    }
    .mr-xxl-n1,
    .mx-xxl-n1 {
      margin-right: -0.25rem !important;
    }
    .mb-xxl-n1,
    .my-xxl-n1 {
      margin-bottom: -0.25rem !important;
    }
    .ml-xxl-n1,
    .mx-xxl-n1 {
      margin-left: -0.25rem !important;
    }
    .m-xxl-n2 {
      margin: -0.5rem !important;
    }
    .mt-xxl-n2,
    .my-xxl-n2 {
      margin-top: -0.5rem !important;
    }
    .mr-xxl-n2,
    .mx-xxl-n2 {
      margin-right: -0.5rem !important;
    }
    .mb-xxl-n2,
    .my-xxl-n2 {
      margin-bottom: -0.5rem !important;
    }
    .ml-xxl-n2,
    .mx-xxl-n2 {
      margin-left: -0.5rem !important;
    }
    .m-xxl-n3 {
      margin: -1rem !important;
    }
    .mt-xxl-n3,
    .my-xxl-n3 {
      margin-top: -1rem !important;
    }
    .mr-xxl-n3,
    .mx-xxl-n3 {
      margin-right: -1rem !important;
    }
    .mb-xxl-n3,
    .my-xxl-n3 {
      margin-bottom: -1rem !important;
    }
    .ml-xxl-n3,
    .mx-xxl-n3 {
      margin-left: -1rem !important;
    }
    .m-xxl-n4 {
      margin: -1.5rem !important;
    }
    .mt-xxl-n4,
    .my-xxl-n4 {
      margin-top: -1.5rem !important;
    }
    .mr-xxl-n4,
    .mx-xxl-n4 {
      margin-right: -1.5rem !important;
    }
    .mb-xxl-n4,
    .my-xxl-n4 {
      margin-bottom: -1.5rem !important;
    }
    .ml-xxl-n4,
    .mx-xxl-n4 {
      margin-left: -1.5rem !important;
    }
    .m-xxl-n5 {
      margin: -3rem !important;
    }
    .mt-xxl-n5,
    .my-xxl-n5 {
      margin-top: -3rem !important;
    }
    .mr-xxl-n5,
    .mx-xxl-n5 {
      margin-right: -3rem !important;
    }
    .mb-xxl-n5,
    .my-xxl-n5 {
      margin-bottom: -3rem !important;
    }
    .ml-xxl-n5,
    .mx-xxl-n5 {
      margin-left: -3rem !important;
    }
    .m-xxl-n6 {
      margin: -5rem !important;
    }
    .mt-xxl-n6,
    .my-xxl-n6 {
      margin-top: -5rem !important;
    }
    .mr-xxl-n6,
    .mx-xxl-n6 {
      margin-right: -5rem !important;
    }
    .mb-xxl-n6,
    .my-xxl-n6 {
      margin-bottom: -5rem !important;
    }
    .ml-xxl-n6,
    .mx-xxl-n6 {
      margin-left: -5rem !important;
    }
    .m-xxl-n7 {
      margin: -6rem !important;
    }
    .mt-xxl-n7,
    .my-xxl-n7 {
      margin-top: -6rem !important;
    }
    .mr-xxl-n7,
    .mx-xxl-n7 {
      margin-right: -6rem !important;
    }
    .mb-xxl-n7,
    .my-xxl-n7 {
      margin-bottom: -6rem !important;
    }
    .ml-xxl-n7,
    .mx-xxl-n7 {
      margin-left: -6rem !important;
    }
    .m-xxl-n8 {
      margin: -8rem !important;
    }
    .mt-xxl-n8,
    .my-xxl-n8 {
      margin-top: -8rem !important;
    }
    .mr-xxl-n8,
    .mx-xxl-n8 {
      margin-right: -8rem !important;
    }
    .mb-xxl-n8,
    .my-xxl-n8 {
      margin-bottom: -8rem !important;
    }
    .ml-xxl-n8,
    .mx-xxl-n8 {
      margin-left: -8rem !important;
    }
    .m-xxl-n9 {
      margin: -9rem !important;
    }
    .mt-xxl-n9,
    .my-xxl-n9 {
      margin-top: -9rem !important;
    }
    .mr-xxl-n9,
    .mx-xxl-n9 {
      margin-right: -9rem !important;
    }
    .mb-xxl-n9,
    .my-xxl-n9 {
      margin-bottom: -9rem !important;
    }
    .ml-xxl-n9,
    .mx-xxl-n9 {
      margin-left: -9rem !important;
    }
    .m-xxl-auto {
      margin: auto !important;
    }
    .mt-xxl-auto,
    .my-xxl-auto {
      margin-top: auto !important;
    }
    .mr-xxl-auto,
    .mx-xxl-auto {
      margin-right: auto !important;
    }
    .mb-xxl-auto,
    .my-xxl-auto {
      margin-bottom: auto !important;
    }
    .ml-xxl-auto,
    .mx-xxl-auto {
      margin-left: auto !important;
    }
  }
  
  .stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0);
  }
  
  .text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  }
  
  .text-justify {
    text-align: justify !important;
  }
  
  .text-wrap {
    white-space: normal !important;
  }
  
  .text-nowrap {
    white-space: nowrap !important;
  }
  
  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .text-left {
    text-align: left !important;
  }
  
  .text-right {
    text-align: right !important;
  }
  
  .text-center {
    text-align: center !important;
  }
  
  @media (min-width: 576px) {
    .text-sm-left {
      text-align: left !important;
    }
    .text-sm-right {
      text-align: right !important;
    }
    .text-sm-center {
      text-align: center !important;
    }
  }
  
  @media (min-width: 768px) {
    .text-md-left {
      text-align: left !important;
    }
    .text-md-right {
      text-align: right !important;
    }
    .text-md-center {
      text-align: center !important;
    }
  }
  
  @media (min-width: 1024px) {
    .text-lg-left {
      text-align: left !important;
    }
    .text-lg-right {
      text-align: right !important;
    }
    .text-lg-center {
      text-align: center !important;
    }
  }
  
  @media (min-width: 1440px) {
    .text-xl-left {
      text-align: left !important;
    }
    .text-xl-right {
      text-align: right !important;
    }
    .text-xl-center {
      text-align: center !important;
    }
  }
  
  @media (min-width: 1680px) {
    .text-xxl-left {
      text-align: left !important;
    }
    .text-xxl-right {
      text-align: right !important;
    }
    .text-xxl-center {
      text-align: center !important;
    }
  }
  
  .text-lowercase {
    text-transform: lowercase !important;
  }
  
  .text-uppercase {
    text-transform: uppercase !important;
  }
  
  .text-capitalize {
    text-transform: capitalize !important;
  }
  
  .font-weight-light {
    font-weight: 300 !important;
  }
  
  .font-weight-lighter {
    font-weight: lighter !important;
  }
  
  .font-weight-normal {
    font-weight: 400 !important;
  }
  
  .font-weight-bold {
    font-weight: 700 !important;
  }
  
  .font-weight-bolder {
    font-weight: bolder !important;
  }
  
  .font-italic {
    font-style: italic !important;
  }
  
  .text-white {
    color: #fff !important;
  }
  
  .text-primary {
    color: #fae439 !important;
  }
  
  a.text-primary:hover, a.text-primary:focus {
    color: #e1c806 !important;
  }
  
  .text-secondary {
    color: #010101 !important;
  }
  
  a.text-secondary:hover, a.text-secondary:focus {
    color: black !important;
  }
  
  .text-success {
    color: #28a745 !important;
  }
  
  a.text-success:hover, a.text-success:focus {
    color: #19692c !important;
  }
  
  .text-info {
    color: #17a2b8 !important;
  }
  
  a.text-info:hover, a.text-info:focus {
    color: #0f6674 !important;
  }
  
  .text-warning {
    color: #ffc107 !important;
  }
  
  a.text-warning:hover, a.text-warning:focus {
    color: #ba8b00 !important;
  }
  
  .text-danger {
    color: #dc3545 !important;
  }
  
  a.text-danger:hover, a.text-danger:focus {
    color: #a71d2a !important;
  }
  
  .text-light {
    color: #f8f9fa !important;
  }
  
  a.text-light:hover, a.text-light:focus {
    color: #cbd3da !important;
  }
  
  .text-dark {
    color: #374047 !important;
  }
  
  a.text-dark:hover, a.text-dark:focus {
    color: #16191c !important;
  }
  
  .text-blue {
    color: #007bff !important;
  }
  
  a.text-blue:hover, a.text-blue:focus {
    color: #0056b3 !important;
  }
  
  .text-body {
    color: #212529 !important;
  }
  
  .text-muted {
    color: #6c757d !important;
  }
  
  .text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important;
  }
  
  .text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
  }
  
  .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  
  .text-decoration-none {
    text-decoration: none !important;
  }
  
  .text-break {
    word-break: break-word !important;
    word-wrap: break-word !important;
  }
  
  .text-reset {
    color: inherit !important;
  }
  
  .visible {
    visibility: visible !important;
  }
  
  .invisible {
    visibility: hidden !important;
  }
  
  @media print {
    *,
    *::before,
    *::after {
      text-shadow: none !important;
      box-shadow: none !important;
    }
    a:not(.btn) {
      text-decoration: underline;
    }
    abbr[title]::after {
      content: " (" attr(title) ")";
    }
    pre {
      white-space: pre-wrap !important;
    }
    pre,
    blockquote {
      border: 1px solid #adb5bd;
      page-break-inside: avoid;
    }
    thead {
      display: table-header-group;
    }
    tr,
    img {
      page-break-inside: avoid;
    }
    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }
    h2,
    h3 {
      page-break-after: avoid;
    }
    @page {
      size: a3;
    }
    body {
      min-width: 992px !important;
    }
    .container {
      min-width: 992px !important;
    }
    .navbar {
      display: none;
    }
    .badge {
      border: 1px solid #000;
    }
    .table {
      border-collapse: collapse !important;
    }
    .table td,
    .table th {
      background-color: #fff !important;
    }
    .table-bordered th,
    .table-bordered td {
      border: 1px solid #dee2e6 !important;
    }
    .table-dark {
      color: inherit;
    }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6;
    }
    .table .thead-dark th {
      color: inherit;
      border-color: #dee2e6;
    }
  }
  
  /*********************
  IMPORT OWL CAROUSEL - START
  *********************/
  /*
   *  Owl Carousel - Core
   */
  .owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    /* position relative and z-index fix webkit rendering fonts issue */
    position: relative;
    z-index: 1;
  }
  
  .owl-carousel .owl-stage {
    position: relative;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
    /* fix firefox animation glitch */
  }
  
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }
  
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  
  .owl-carousel .owl-wrapper,
  .owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
  }
  
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }
  
  .owl-carousel .owl-item img {
    display: block;
    width: 100%;
  }
  
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none;
  }
  
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel button.owl-dot {
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
  }
  
  .owl-carousel.owl-loaded {
    display: block;
  }
  
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block;
  }
  
  .owl-carousel.owl-hidden {
    opacity: 0;
  }
  
  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden;
  }
  
  .owl-carousel.owl-drag .owl-item {
    touch-action: pan-y;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab;
  }
  
  .owl-carousel.owl-rtl {
    direction: rtl;
  }
  
  .owl-carousel.owl-rtl .owl-item {
    float: right;
  }
  
  /* No Js */
  .no-js .owl-carousel {
    display: block;
  }
  
  /*
   *  Owl Carousel - Animate Plugin
   */
  .owl-carousel .animated {
    -webkit-animation-duration: 1000ms;
            animation-duration: 1000ms;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
  }
  
  .owl-carousel .owl-animated-in {
    z-index: 0;
  }
  
  .owl-carousel .owl-animated-out {
    z-index: 1;
  }
  
  .owl-carousel .fadeOut {
    -webkit-animation-name: fadeOut;
            animation-name: fadeOut;
  }
  
  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  /*
   * 	Owl Carousel - Auto Height Plugin
   */
  .owl-height {
    transition: height 500ms ease-in-out;
  }
  
  /*
   * 	Owl Carousel - Lazy Load Plugin
   */
  .owl-carousel .owl-item {
    /**
              This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
              calculation of the height of the owl-item that breaks page layouts
           */
  }
  
  .owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease;
  }
  
  .owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
    max-height: 0;
  }
  
  .owl-carousel .owl-item img.owl-lazy {
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
  }
  
  /*
   * 	Owl Carousel - Video Plugin
   */
  .owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000;
  }
  
  .owl-carousel .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    /* background: url("owl.video.play.png") no-repeat; */
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    transition: -webkit-transform 100ms ease;
    transition: transform 100ms ease;
    transition: transform 100ms ease, -webkit-transform 100ms ease;
  }
  
  .owl-carousel .owl-video-play-icon:hover {
    -webkit-transform: scale(1.3, 1.3);
            transform: scale(1.3, 1.3);
  }
  
  .owl-carousel .owl-video-playing .owl-video-tn,
  .owl-carousel .owl-video-playing .owl-video-play-icon {
    display: none;
  }
  
  .owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: opacity 400ms ease;
  }
  
  .owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
  }
  
  /*
   * 	Default theme - Owl Carousel CSS File
   */
  .owl-theme .owl-nav {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
  }
  
  .owl-theme .owl-nav [class*='owl-'] {
    color: #FFF;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #D6D6D6;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
  }
  
  .owl-theme .owl-nav [class*='owl-']:hover {
    background: #869791;
    color: #FFF;
    text-decoration: none;
  }
  
  .owl-theme .owl-nav .disabled {
    opacity: 0.5;
    cursor: default;
  }
  
  .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 10px;
  }
  
  .owl-theme .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
  }
  
  .owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline;
  }
  
  .owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #D6D6D6;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity 200ms ease;
    border-radius: 30px;
  }
  
  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #869791;
  }
  
  .owl-carousel .owl-item img {
    display: inline-block;
    width: auto !important;
  }
  
  .owl-theme .owl-dots {
    text-align: inherit;
  }
  
  .owl-theme .owl-dots .owl-dot {
    outline: none;
  }
  
  .owl-theme .owl-dots .owl-dot span {
    height: 11px;
    width: 11px;
    background-color: rgba(41, 49, 56, 0.4);
    border: none;
    border-radius: 11px;
    margin: 3px;
    margin-bottom: 24px;
    transition: 0.3s all;
  }
  
  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background-color: #293138;
  }
  
  .owl-theme .owl-dots .owl-dot.active span {
    width: 44px;
  }
  
  .owl-theme .owl-dots .owl-dot:not(:last-child) {
    margin-right: rem-calc(9);
  }
  
  .owl-theme.owl-dots-centered .owl-dots {
    text-align: center;
  }
  
  /*********************
  IMPORT OWL CAROUSEL - END
  *********************/
  body {
    overflow-x: hidden;
  }
  
  .container {
    max-width: 1600px;
  }
  
  .w-90 {
    max-width: 100%;
    padding: 25px;
  }
  
  .font-90 {
    font-size: 90%;
  }
  
  @media (min-width: 576px) {
    .show-inline-container .show-inline {
      width: auto;
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 0 !important;
    }
    .show-inline-container .show-inline + .show-inline {
      margin-left: 15px;
    }
  }
  
  .show-inline-container .show-inline:not(:last-child) {
    margin-bottom: 15px;
  }
  
  section {
    position: relative;
  }
  
  .has-box-shadow {
    box-shadow: 5px 5px 17px rgba(0, 0, 0, 0.15);
  }
  
  .has-border-radius {
    border-radius: 10px;
  }
  
  .has-border-radius-5 {
    border-radius: 5px !important;
  }
  
  .bg-light-grey {
    background-color: rgba(30, 40, 47, 0.034);
  }
  
  .close-button {
    width: 30px;
    padding: 5px;
    cursor: pointer;
  }
  
  .has-upper-border-radius {
    border-radius: 10px 10px 0px 0px;
  }
  
  .bg-grey {
    background-color: #FAFAFA;
  }
  
  .placeholder-background {
    width: auto;
    height: 100px;
  }
  
  .bg-position-top-right {
    background-position: top right;
  }
  
  .bg-position-top-right-offset {
    background-position: top 45px right 24px;
  }
  
  @media (min-width: 1024px) {
    .bg-position-top-right-offset {
      background-position: top 60px right 30px;
    }
  }
  
  .bg-position-center-right {
    background-position: center right;
  }
  
  .bg-position-center-right-offset {
    background-position: top 20px right 40px;
  }
  
  @media (min-width: 1024px) {
    .bg-position-center-right-offset {
      background-position: top 20px right 50px;
    }
  }
  
  @media (min-width: 768px) {
    .bg-dots-variant-1 {
      background-image: url("/images/dots-variant-1.svg");
    }
  }
  
  .bg-repeat-no-repeat {
    background-repeat: no-repeat;
  }
  
  .bg-gradient {
    background-image: linear-gradient(to right, #FBB040 0%, #FFDE17 100%);
  }
  
  .bg-gradient-secondary {
    background-image: linear-gradient(to right, #4D4D4D 0%, #000000 100%);
  }
  
  .bg-light-gray {
    background-color: #EBEBEB;
  }
  
  #search-form #search-form-top {
    position: relative;
    background-color: #1E282E;
    padding: 30px;
  }
  
  #search-form #search-form-top .input-group {
    background: transparent;
  }
  
  #search-form #search-form-top .input-group input::-webkit-input-placeholder,
  #search-form #search-form-top .input-group textarea::-webkit-input-placeholder {
    color: #fff;
    opacity: 0.5;
  }
  
  #search-form #search-form-top .input-group input:-ms-input-placeholder,
  #search-form #search-form-top .input-group textarea:-ms-input-placeholder {
    color: #fff;
    opacity: 0.5;
  }
  
  #search-form #search-form-top .input-group input::-webkit-input-placeholder, #search-form #search-form-top .input-group textarea::-webkit-input-placeholder {
    color: #fff;
    opacity: 0.5;
  }
  
  #search-form #search-form-top .input-group input:-ms-input-placeholder, #search-form #search-form-top .input-group textarea:-ms-input-placeholder {
    color: #fff;
    opacity: 0.5;
  }
  
  #search-form #search-form-top .input-group input::placeholder,
  #search-form #search-form-top .input-group textarea::placeholder {
    color: #fff;
    opacity: 0.5;
  }
  
  #search-form #search-form-top .input-group input:not([type="submit"]):not(.btn),
  #search-form #search-form-top .input-group textarea {
    color: #fff;
    border-color: rgba(255, 255, 255, 0.05);
    background-color: rgba(255, 255, 255, 0.05);
  }
  
  #search-form #search-form-top .input-group input:not([type="submit"]):not(.btn):focus,
  #search-form #search-form-top .input-group textarea:focus {
    border-color: rgba(250, 228, 57, 0.5);
  }
  
  @media (min-width: 1024px) {
    #search-form #search-form-top .input-group input[type="submit"],
    #search-form #search-form-top .input-group .btn {
      min-width: 180px;
    }
  }
  
  #search-form #search-form-top #search {
    padding-left: 68px;
    background-image: url("/images/icon-search.svg");
    background-position: center left 16px;
    background-repeat: no-repeat;
    background-size: 36px;
  }
  
  #search-form #search-form-top #search-form-top-buttons {
    position: relative;
  }
  
  #search-form #search-form-top #search-form-top-buttons .btn {
    display: block;
    width: 100%;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
  }
  
  #search-form #search-form-top #search-form-top-buttons .btn:not(:last-child) {
    margin-bottom: 16px;
  }
  
  @media (min-width: 768px) {
    #search-form #search-form-top #search-form-top-buttons .btn {
      flex-grow: 1;
      margin-bottom: 0 !important;
    }
    #search-form #search-form-top #search-form-top-buttons .btn:not(:last-child) {
      margin-right: 30px;
    }
  }
  
  @media (min-width: 768px) {
    #search-form #search-form-top #search-form-top-buttons {
      display: flex;
      flex: 1 1 auto;
      justify-content: space-between;
      margin-bottom: -70px;
      z-index: 3;
    }
  }
  
  @media (min-width: 768px) {
    #search-form #search-form-top {
      padding: 50px;
    }
  }
  
  #search-form #search-form-bottom {
    position: relative;
    z-index: 1;
    background-color: #fff;
    padding: 30px;
    position: relative;
    border-left: 5px solid #fae439;
    border-right: 5px solid #fae439;
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.15);
  }
  
  #search-form #search-form-bottom #search-form-bottom-buttons {
    position: relative;
  }
  
  #search-form #search-form-bottom #search-form-bottom-buttons .btn {
    display: block;
    width: 100%;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
  }
  
  #search-form #search-form-bottom #search-form-bottom-buttons .btn:not(:last-child) {
    margin-bottom: 16px;
  }
  
  @media (min-width: 768px) {
    #search-form #search-form-bottom #search-form-bottom-buttons .btn {
      margin-bottom: 0 !important;
    }
    #search-form #search-form-bottom #search-form-bottom-buttons .btn:not(:last-child) {
      margin-right: 30px;
    }
  }
  
  @media (min-width: 768px) {
    #search-form #search-form-bottom #search-form-bottom-buttons {
      display: flex;
      flex: 1 1 auto;
      justify-content: center;
      margin-bottom: -70px;
      z-index: 3;
    }
  }
  
  @media (min-width: 768px) {
    #search-form #search-form-bottom {
      padding: 50px 80px;
    }
  }
  
  @media (min-width: 768px) {
    #search-form {
      margin-bottom: 10px;
    }
  }
  
  #search-filter_options {
    flex-wrap: wrap;
    border: 1px solid rgba(55, 66, 71, 0.4);
  }
  
  #search-filter_options .search-filter_option {
    padding: 12px;
    font-size: 12px;
    text-align: center;
    display: flex;
    min-width: 20%;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s all;
    font-weight: 700;
  }
  
  @media (max-width: 575.98px) {
    #search-filter_options .search-filter_option {
      flex-wrap: wrap;
      flex-direction: column;
    }
  }
  
  #search-filter_options .search-filter_option .icon-search-filter_options {
    fill: rgba(17, 40, 132, 0.3);
    height: 18px;
  }
  
  @media (min-width: 768px) {
    #search-filter_options .search-filter_option .icon-search-filter_options {
      height: 24px;
    }
  }
  
  #search-filter_options .search-filter_option:hover {
    background-color: #fae439;
  }
  
  #search-filter_options .search-filter_option:hover .icon-search-filter_options {
    fill: #000;
  }
  
  #search-filter_options .search-filter_option.active {
    color: #fff;
    background-color: #000;
  }
  
  #search-filter_options .search-filter_option.active .icon-search-filter_options {
    fill: #fff;
  }
  
  @media (min-width: 768px) {
    #search-filter_options .search-filter_option {
      padding: 16px;
      font-size: 16px;
    }
  }
  
  .list-property .list-property-image img {
    max-width: 100%;
    border-radius: 10px;
  }
  
  .list-property .list-property-content {
    border-radius: 10px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
  }
  
  .list-property .list-property-content .list-property-content-header {
    background-color: #1E282E;
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 16px;
  }
  
  @media (min-width: 1024px) {
    .list-property .list-property-content .list-property-content-header {
      padding: 30px;
      font-size: 18px;
    }
  }
  
  .list-property .list-property-content .list-property-content-company {
    background-color: rgba(30, 40, 46, 0.1);
    padding: 16px 30px;
  }
  
  .list-property .list-property-content .list-property-content-company .property-agent .property-agent-image {
    max-width: 90px;
    border-radius: 50%;
  }
  
  @media (min-width: 1024px) {
    .list-property .list-property-content .list-property-content-company .property-agent .property-agent-image {
      margin-top: -72px;
    }
  }
  
  @media (min-width: 1024px) {
    .list-property .list-property-content .list-property-content-company .property-agent .property-agent-image.dashboard-view {
      margin-top: -42px;
    }
  }
  
  .list-property .list-property-content .list-property-content-company .property-company {
    min-width: 210px;
  }
  
  .list-property .list-property-content .list-property-content-company .property-company .property-company-image {
    max-width: 50px;
    margin-left: 8px;
  }
  
  @media (min-width: 1024px) {
    .list-property .list-property-content .list-property-content-company {
      padding: 20px 45px;
    }
  }
  
  .list-property .list-property-content .list-property-content-details {
    padding: 16px 30px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .list-property .list-property-content .list-property-content-details .property-meta > div:not(:last-child) {
    border-bottom: 1px solid rgba(30, 40, 46, 0.3);
  }
  
  @media (min-width: 1024px) {
    .list-property .list-property-content .list-property-content-details .property-meta > div:not(:last-child) {
      border-right: 1px solid rgba(30, 40, 46, 0.3);
      border-bottom: none;
    }
  }
  
  .list-property .list-property-content .list-property-content-details .property-share .property-rating img {
    width: 24px;
  }
  
  .list-property .list-property-content .list-property-content-details .property-share .property-like #icon-list_property-like {
    width: 26px;
  }
  
  .list-property .list-property-content .list-property-content-details .property-share .property-save #icon-list_property-save {
    width: 20px;
  }
  
  @media (min-width: 1024px) {
    .list-property .list-property-content .list-property-content-details {
      padding: 20px 45px;
    }
  }
  
  .list-property + .list-property {
    margin-top: 30px;
  }
  
  .property-card {
    border: none;
    text-align: left;
    border-radius: 10px;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .property-card .property-image {
    max-width: 100%;
  }
  
  .property-card .property-image img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    max-width: 100%;
  }
  
  .property-card .card-body {
    padding: 30px 24px 24px;
  }
  
  .property-card .property-type {
    text-align: center;
    margin-top: -50px;
  }
  
  .property-card .property-agent .property-agent-image {
    max-width: 50px;
    border-radius: 50%;
    margin-right: 8px;
  }
  
  .property-card .property-specifications {
    border-top: 2px solid rgba(55, 64, 71, 0.1);
    margin-top: 12px;
    padding-top: 12px;
  }
  
  .property-card .property-specifications .property-specification .property-specification-icon.property-specification-icon-bedrooms {
    width: 22px;
    height: 13px;
  }
  
  .property-card .property-specifications .property-specification .property-specification-icon.property-specification-icon-bathrooms {
    width: 18px;
    height: 17px;
  }
  
  .property-card .property-specifications .property-specification .property-specification-icon.property-specification-icon-area {
    width: 17px;
    height: 17px;
  }
  
  hr.no-border {
    border: none;
  }
  
  hr.height-4 {
    height: 4px;
  }
  
  hr.height-2 {
    height: 2px;
  }
  
  label.dashed-border {
    border: 0.1rem dashed #606060;
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    cursor: pointer;
  }
  
  .modal {
    padding-right: 0 !important;
    margin: auto;
    font-size: 15px;
    text-align: center;
  }
  
  .modal .modal-lg {
    max-width: 900px;
  }
  
  .modal .modal-dialog {
    margin: 2rem auto;
  }
  
  @media (max-width: 767.98px) {
    .modal .modal-dialog {
      margin: 0.7rem;
    }
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  
  @media (min-width: 1024px) {
    .w-lg-auto {
      width: auto !important;
    }
  }
  
  .text-grey {
    color: #A2A2A2;
  }
  
  .font-weight-medium {
    font-weight: 500;
  }
  
  .icon-24 {
    width: 24px;
    height: 24px;
  }
  
  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes rotating {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  .btn {
    min-width: 100px;
  }
  
  .btn.btn-gradient {
    background-image: linear-gradient(to right, #FBB040 0%, #FFDE17 100%);
  }
  
  .btn.btn-gradient-secondary {
    color: #fff;
    background-image: linear-gradient(to right, #4D4D4D 0%, #000000 100%);
  }
  
  .btn.btn-rounded {
    border-radius: 60px;
  }
  
  .btn.btn-rounded-lg {
    border-radius: 70px;
  }
  
  .btn.btn-rounded-sm {
    border-radius: 50px;
  }
  
  .btn.btn-xs {
    padding: 8px 16px;
    font-size: 12px;
  }
  
  .btn.btn-custom-width {
    min-width: 200px;
  }
  
  .btn.btn-outline-primary, .btn.btn-outline-secondary, .btn.btn-outline-success, .btn.btn-outline-warning, .btn.btn-outline-danger {
    border-width: 1px !important;
  }
  
  .btn.btn-outline-primary:not(:hover):not(:focus), .btn.btn-outline-secondary:not(:hover):not(:focus), .btn.btn-outline-success:not(:hover):not(:focus), .btn.btn-outline-warning:not(:hover):not(:focus), .btn.btn-outline-danger:not(:hover):not(:focus) {
    color: #232323;
  }
  
  .form-control {
    background: #FAFAFA;
  }
  
  .form-control.secondary-input {
    border: none;
  }
  
  .input-group {
    background: #FAFAFA;
  }
  
  .input-group .input-group-prepend {
    margin: 0px 20px;
  }
  
  .input-group .input-group-prepend img {
    max-width: 27px;
  }
  
  .input-group .form-control {
    background: transparent;
  }
  
  select.form-control {
    position: relative;
    -webkit-appearance: none;
            appearance: none;
    background-image: url("/images/icon-filter-dropdown.svg");
    background-position: center right 16px;
    background-repeat: no-repeat;
    background-size: 14px 6px;
    background-color: white;
  }
  
  select.form-control:focus {
    background-color: white;
  }
  
  select.form-control option:checked, select.form-control option:active, select.form-control option:hover, select.form-control option:focus {
    background-color: #fae439 !important;
  }
  
  select.form-control.secondary-select {
    background-color: #FAFAFA;
    border: none;
    min-width: 100px;
  }
  
  input[type="checkbox"] {
    display: none;
  }
  
  input[type="checkbox"] + label {
    position: relative;
    padding-left: 45px;
    display: inline-block;
  }
  
  input[type="checkbox"] + label::after {
    position: absolute;
    left: 0;
    top: -5px;
    display: block;
    content: " ";
    background-color: white;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.082);
    border-radius: 0;
    height: 30px;
    width: 30px;
    transition: 0.3s all;
  }
  
  input[type="checkbox"]:checked + label::after {
    background-color: black;
    border: 8px solid white;
  }
  
  input[type="radio"] {
    display: none;
  }
  
  input[type="radio"] + label {
    position: relative;
    padding-left: 36px;
    display: inline-block;
  }
  
  input[type="radio"] + label::after {
    position: absolute;
    left: 0;
    top: -5px;
    display: block;
    content: " ";
    background-color: transparent;
    border: 8px solid #000000;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    transition: 0.3s all;
  }
  
  input[type="radio"]:checked + label::after {
    background-color: #fae439;
    background-image: linear-gradient(to right, #FBB040 0%, #FFDE17 100%);
  }
  
  .form-check {
    padding-left: 0;
  }
  
  .label-with-tooltip {
    display: inline;
    vertical-align: middle;
  }
  
  .label-with-tooltip img.form-tooltip {
    max-width: 17px;
    margin: 0px 0px 0px 10px;
  }
  
  .tooltip .tooltip-inner {
    background-color: #FAFAFA;
    color: #dc3545;
  }
  
  .tooltip .tooltip-arrow {
    border-color: #FAFAFA;
  }
  
  .primary-input {
    background: white;
    border: 1px solid rgba(120, 120, 120, 0.445);
    border-radius: 11px;
    box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.007);
    color: #444444;
  }
  
  header.header .navbar-brand {
    padding: 15px 0;
  }
  
  @media (min-width: 1440px) {
    header.header .navbar-brand {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
  
  header.header .navbar-nav .nav-item {
    position: relative;
  }
  
  header.header .navbar-nav .nav-item .nav-link {
    font-size: 25px;
    color: #000;
  }
  
  header.header .navbar-nav .nav-item .nav-link:hover {
    color: #000;
  }
  
  @media (max-width: 1439.98px) {
    header.header .navbar-nav .nav-item .nav-link {
      white-space: nowrap;
      font-size: 22px;
    }
  }
  
  header.header .navbar-nav .nav-item.active .nav-link {
    color: #000;
  }
  
  @media (min-width: 1440px) {
    header.header .navbar-nav .nav-item:not(:last-child) {
      margin-right: 45px;
    }
  }
  
  @media (min-width: 1024px) and (max-width: 1439.98px) {
    header.header .navbar-nav .nav-item:not(:last-child) {
      margin-right: 30px;
    }
  }
  
  header.header .navbar-expand-lg {
    flex-flow: wrap;
  }
  
  header.header .dropdown-toggle::after {
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8"><defs><style>.cls-1{fill-rule:evenodd;}</style></defs><title>Country Dropdown</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M1.47.23,7,5.79,12.54.23c.73-.75,2,.48,1.22,1.23L7.51,7.8a.69.69,0,0,1-1,0L.22,1.46C-.51.71.74-.52,1.47.23Z"/></g></g></svg>');
    border: none;
    width: 15px;
    margin-top: -10px;
    -webkit-transform: translateY(3px);
            transform: translateY(3px);
  }
  
  header.header .navbar-collapse {
    padding: 16px 24px;
  }
  
  header.header .navbar-collapse .nav-link {
    padding: 4px 0;
  }
  
  header.header .navbar-collapse .dropdown-menu {
    border: none;
    background-color: transparent;
  }
  
  @media (min-width: 1440px) {
    header.header .navbar-collapse .dropdown-menu {
      border: 1px solid rgba(0, 0, 0, 0.15);
      background-color: #fff;
    }
  }
  
  @media (min-width: 1440px) {
    header.header .navbar-collapse {
      padding: 0;
      background-color: transparent;
    }
  }
  
  header.header .header-bottom {
    background-color: #1E282E;
    font-size: 20px;
  }
  
  header.landing-page-header {
    background: #F1F1F1;
    padding: 0px 25px;
  }
  
  @media (max-width: 1023.98px) {
    header.landing-page-header {
      padding: 0;
    }
  }
  
  header.landing-page-header .navbar-brand {
    padding: 15px 0;
  }
  
  @media (min-width: 1440px) {
    header.landing-page-header .navbar-brand {
      padding-bottom: 30px;
    }
  }
  
  header.landing-page-header .navbar-nav .nav-item {
    position: relative;
  }
  
  header.landing-page-header .navbar-nav .nav-item .nav-link {
    font-size: 19px;
    color: #6A6975;
  }
  
  header.landing-page-header .navbar-nav .nav-item .nav-link:hover {
    color: #000;
  }
  
  @media (max-width: 1439.98px) {
    header.landing-page-header .navbar-nav .nav-item .nav-link {
      white-space: nowrap;
      font-size: 22px;
    }
  }
  
  header.landing-page-header .navbar-nav .nav-item.active .nav-link {
    color: #000;
  }
  
  @media (min-width: 1440px) {
    header.landing-page-header .navbar-nav .nav-item:not(:last-child) {
      margin-right: 45px;
    }
  }
  
  @media (min-width: 1024px) and (max-width: 1439.98px) {
    header.landing-page-header .navbar-nav .nav-item:not(:last-child) {
      margin-right: 30px;
    }
  }
  
  header.landing-page-header .navbar-expand-lg {
    flex-flow: wrap;
  }
  
  header.landing-page-header .navbar-collapse {
    padding: 16px 24px;
  }
  
  header.landing-page-header .navbar-collapse .nav-link {
    padding: 4px 0;
  }
  
  @media (min-width: 1440px) {
    header.landing-page-header .navbar-collapse {
      padding: 0;
      background-color: transparent;
    }
  }
  
  footer.footer #footer-form-container {
    margin: 60px auto;
  }
  
  footer.footer #footer-form-container > .row {
    border: 2px solid rgba(55, 64, 71, 0.1);
  }
  
  footer.footer #footer-form-container #footer-form {
    padding: 30px;
    border-right: 2px solid rgba(55, 64, 71, 0.1);
  }
  
  @media (max-width: 1023.98px) {
    footer.footer #footer-form-container #footer-form {
      border-right: none;
    }
  }
  
  footer.footer #footer-form-container #footer-form input,
  footer.footer #footer-form-container #footer-form textarea {
    font-size: 14px;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 0;
    padding: 12px 18px;
    height: auto;
  }
  
  footer.footer #footer-form-container #footer-form input:focus,
  footer.footer #footer-form-container #footer-form textarea:focus {
    border-bottom-width: 2px;
  }
  
  @media (min-width: 1024px) {
    footer.footer #footer-form-container #footer-form {
      padding: 60px;
    }
  }
  
  footer.footer #footer-form-container .social-icons {
    flex-direction: row;
  }
  
  footer.footer #footer-form-container .social-icons .social-icon {
    text-align: center;
    display: inline-block;
  }
  
  footer.footer #footer-form-container .social-icons .social-icon img {
    width: 28px;
    height: 28px;
  }
  
  footer.footer #footer-form-container .social-icons .social-icon:not(:last-child) {
    border-right: 2px solid rgba(55, 64, 71, 0.1);
  }
  
  @media (max-width: 1023.98px) {
    footer.footer #footer-form-container .social-icons .social-icon:not(:last-child) {
      border-right: none;
    }
  }
  
  @media (min-width: 1024px) {
    footer.footer #footer-form-container .social-icons .social-icon {
      padding: 35px;
    }
    footer.footer #footer-form-container .social-icons .social-icon:not(:last-child) {
      border-bottom: 2px solid rgba(55, 64, 71, 0.1);
      border-right: 0;
    }
  }
  
  @media (max-width: 1023.98px) {
    footer.footer #footer-form-container .social-icons .social-icon {
      padding: 20px;
    }
  }
  
  @media (min-width: 1024px) {
    footer.footer #footer-form-container .social-icons {
      flex-direction: column;
    }
  }
  
  @media (min-width: 1024px) {
    footer.footer #footer-form-container {
      margin: 120px auto;
    }
  }
  
  .footer-bg {
    background-image: url("/images/footer-bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .yellow-border-right:after {
    border-right: 0.140rem solid #FFCB18;
    content: "";
    position: absolute;
    right: 0;
    top: 15%;
    height: 70%;
  }
  
  @media (max-width: 1023.98px) {
    .yellow-border-right:after {
      display: none;
    }
  }
  
  .white-border-right:after {
    border-right: 0.140rem solid white;
    content: "";
    position: absolute;
    right: 0;
    top: 15%;
    height: 70%;
  }
  
  @media (max-width: 1023.98px) {
    .white-border-right:after {
      display: none;
    }
  }
  
  .footer-link-section {
    padding: 20px;
  }
  
  .footer-logo-bg {
    background-image: url("/images/footer-logo-bg.png");
    background-size: 100% auto;
  }
  
  .footer-link.nav-link {
    color: #b9b9b9;
    padding: 0px;
    padding-bottom: 10px;
  }
  
  .footer-image {
    width: auto;
    height: 40px;
  }
  
  .copyright-text {
    margin-top: 20px;
    font-size: 14px;
  }
  
  .landing-page-footer {
    background: url("/images/landing-page-footer-bg.png");
    background-size: 100% 100%;
    padding: 1.7rem;
  }
  
  .landing-page-footer .website-description, .landing-page-footer .copyright-text {
    color: #b9b9b9;
  }
  
  .landing-page-footer .newsletter-email {
    background-color: #6A6975;
  }
  
  .landing-page-footer .newsletter-email > * {
    color: #C9D2D8;
  }
  
  .landing-page-footer .newsletter-email .form-control {
    border: none;
  }
  
  .landing-page-footer .newsletter-email .input-group-append {
    display: flex;
    align-items: center;
    padding: 12px;
  }
  
  .landing-page-footer .newsletter-email .fa-envelope {
    color: #4E4D5A;
    font-size: 26px;
  }
  
  .landing-page-footer .newsletter-email ::-webkit-input-placeholder {
    color: #C9D2D8 !important;
  }
  
  .landing-page-footer .newsletter-email :-ms-input-placeholder {
    color: #C9D2D8 !important;
  }
  
  .landing-page-footer .newsletter-email ::placeholder {
    color: #C9D2D8 !important;
  }
  
  .landing-page-footer .social-nav a {
    background-color: #6A6975;
    padding: 14px 18px;
    border-radius: 50px;
  }
  
  .landing-page-footer .social-nav a > * {
    font-size: 15px;
    color: white;
  }
  
  .landing-page-footer .social-nav a .fa-facebook-f {
    font-size: 20px;
  }
  
  .landing-page-footer .social-nav a:hover {
    background-color: white;
  }
  
  .landing-page-footer .social-nav a:hover > * {
    color: #6A6975;
  }
  
  #landing-section_1 #landing-section_1-banner {
    padding: 60px 0;
  }
  
  @media (min-width: 1024px) {
    #landing-section_1 #landing-section_1-banner {
      padding: 120px 0 90px;
    }
  }
  
  @media (min-width: 768px) {
    #landing-section_1 #search-form #search-form-top {
      margin-left: 30px;
      margin-right: 30px;
    }
  }
  
  @media (min-width: 1024px) {
    #landing-section_1 {
      background-image: url("/images/landing-section_1-bg.png");
      background-size: calc(50% + 357px) auto;
      background-position: right top;
      background-repeat: no-repeat;
    }
  }
  
  #landing-section_2 #landing-section_2-market_leader {
    padding: 60px 0;
  }
  
  @media (min-width: 1024px) {
    #landing-section_2 #landing-section_2-market_leader {
      padding: 120px 0;
    }
  }
  
  #landing-section_3 #landing-section_3-recent_properties #recent_properties_carousel {
    overflow: hidden;
  }
  
  #landing-section_3 #landing-section_3-recent_properties #recent_properties_carousel .owl-stage-outer {
    overflow: visible;
  }
  
  #landing-section_3 #landing-section_3-recent_properties #recent_properties_carousel .owl-dots {
    margin-top: 26px;
    margin-bottom: 0;
  }
  
  #landing-section_4 #landing-section_4-featured_properties {
    padding: 60px 0 0;
  }
  
  #landing-section_4 #landing-section_4-featured_properties #featured_properties_carousel {
    overflow: hidden;
  }
  
  #landing-section_4 #landing-section_4-featured_properties #featured_properties_carousel .owl-stage-outer {
    overflow: visible;
  }
  
  #landing-section_4 #landing-section_4-featured_properties #featured_properties_carousel .featured-property .featured-property-images {
    max-width: 100%;
  }
  
  #landing-section_4 #landing-section_4-featured_properties #featured_properties_carousel .featured-property .featured-property-images span:not(:last-child) {
    margin-right: 16px;
  }
  
  #landing-section_4 #landing-section_4-featured_properties #featured_properties_carousel .featured-property .featured-property-images img {
    max-width: 400px;
    border-radius: 10px;
  }
  
  @media (min-width: 1440px) {
    #landing-section_4 #landing-section_4-featured_properties #featured_properties_carousel .featured-property .featured-property-images img {
      max-width: 340px;
    }
  }
  
  @media (min-width: 768px) and (max-width: 1023.98px) {
    #landing-section_4 #landing-section_4-featured_properties #featured_properties_carousel .featured-property .featured-property-images img {
      max-width: 300px;
    }
  }
  
  #landing-section_4 #landing-section_4-featured_properties #featured_properties_carousel .featured-property .featured-property-card .card-body {
    padding: 24px 24px;
  }
  
  @media (min-width: 1024px) {
    #landing-section_4 #landing-section_4-featured_properties #featured_properties_carousel .featured-property .featured-property-card {
      max-width: 320px;
      margin-top: -120px;
    }
  }
  
  #landing-section_4 #landing-section_4-featured_properties #featured_properties_carousel .owl-dots {
    margin-top: 26px;
    margin-bottom: 0;
  }
  
  @media (min-width: 1024px) {
    #landing-section_4 #landing-section_4-featured_properties {
      padding: 120px 0 0;
    }
  }
  
  #landing-section_5 #landing-section_5-fractional_ownership {
    padding: 60px 0;
  }
  
  @media (min-width: 1024px) {
    #landing-section_5 #landing-section_5-fractional_ownership {
      padding: 120px 0;
    }
  }
  
  #landing-section_6 #landing-section_6-investment_deals #investment_deals_carousel {
    overflow: hidden;
  }
  
  #landing-section_6 #landing-section_6-investment_deals #investment_deals_carousel .owl-stage-outer {
    overflow: visible;
  }
  
  #landing-section_6 #landing-section_6-investment_deals #investment_deals_carousel .investment-deal .investment-deal-images {
    max-width: 100%;
    position: relative;
  }
  
  #landing-section_6 #landing-section_6-investment_deals #investment_deals_carousel .investment-deal .investment-deal-images .investment-deal-banner {
    position: absolute;
    top: 24px;
    right: 24px;
    padding: 10px 15px 21px;
    background-image: url("/images/investment_deal-sale_banner-bg.svg");
    background-size: 145px 55px;
    background-repeat: no-repeat;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  #landing-section_6 #landing-section_6-investment_deals #investment_deals_carousel .investment-deal .investment-deal-images img {
    max-width: 100%;
    border-radius: 10px;
  }
  
  @media (min-width: 768px) {
    #landing-section_6 #landing-section_6-investment_deals #investment_deals_carousel .investment-deal .investment-deal-card {
      max-width: 360px;
      margin-top: -120px;
    }
  }
  
  #landing-section_6 #landing-section_6-investment_deals #investment_deals_carousel .owl-dots {
    margin-top: 26px;
    margin-bottom: 0;
  }
  
  #search-section_1 #search-section_1-form {
    position: relative;
    z-index: 2;
  }
  
  #search-section_1 #search-section_1-form::after {
    display: block;
    position: absolute;
    content: " ";
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #1E282E;
    z-index: 1;
  }
  
  @media (min-width: 768px) {
    #search-section_1 #search-section_1-form::after {
      height: 166px;
    }
  }
  
  #search-section_1 #search-form {
    max-width: 1333.33px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 3;
  }
  
  #search-section_1 #search-form #search-form-top {
    margin-left: 30px;
    margin-right: 30px;
  }
  
  @media (min-width: 1024px) {
    #search-section_1 #search-form {
      padding-bottom: 60px;
    }
  }
  
  #search-section_3 #search-section_3-results #search-results-list {
    padding: 30px 0 60px;
  }
  
  #search-section_3 #search-section_3-results #search-results-map {
    padding: 30px 0 60px;
  }
  
  .review-modal .modal-header {
    border-bottom: none;
    padding: 0 1rem;
    padding-top: 0.7rem;
  }
  
  .review-modal .modal-content {
    border-radius: 20px;
  }
  
  .review-modal .modal-footer {
    border-top: none;
    padding-bottom: 1rem;
  }
  
  .review-modal .close {
    font-size: 2.5rem;
    font-weight: 500;
  }
  
  .review-modal .close:focus {
    outline: none;
  }
  
  .review-modal .reviewer-image {
    max-width: 90px;
    border-radius: 50%;
  }
  
  .review-modal .review-rating img {
    width: 28px;
  }
  
  .review-modal .review-disclaimer {
    color: #A2A2A2;
    font-weight: 500;
  }
  
  #user-registration-section_1 #user-registration-section_1-signup-step1, #user-registration-section_1 #user-registration-section_1-signup-step2, #user-registration-section_1 #user-registration-section_1-signup-step3, #user-registration-section_1 #user-registration-section_1-signup-step4, #user-registration-section_1 #user-registration-section_1-signup-step5, #user-registration-section_1 #user-registration-section_1-signup-step6 {
    padding: 60px 0;
  }
  
  @media (min-width: 1024px) {
    #user-registration-section_1 #user-registration-section_1-signup-step1, #user-registration-section_1 #user-registration-section_1-signup-step2, #user-registration-section_1 #user-registration-section_1-signup-step3, #user-registration-section_1 #user-registration-section_1-signup-step4, #user-registration-section_1 #user-registration-section_1-signup-step5, #user-registration-section_1 #user-registration-section_1-signup-step6 {
      padding: 120px 0;
    }
  }
  
  #company-registration-section_1 #company-registration-section_1-card, #company-registration-section_1 #company-registration-section_2-card, #company-registration-section_1 #company-registration-section_3-card, #company-registration-section_2 #company-registration-section_1-card, #company-registration-section_2 #company-registration-section_2-card, #company-registration-section_2 #company-registration-section_3-card, #company-registration-section_3 #company-registration-section_1-card, #company-registration-section_3 #company-registration-section_2-card, #company-registration-section_3 #company-registration-section_3-card {
    padding: 60px 0;
  }
  
  @media (min-width: 1024px) {
    #company-registration-section_1 #company-registration-section_1-card, #company-registration-section_1 #company-registration-section_2-card, #company-registration-section_1 #company-registration-section_3-card, #company-registration-section_2 #company-registration-section_1-card, #company-registration-section_2 #company-registration-section_2-card, #company-registration-section_2 #company-registration-section_3-card, #company-registration-section_3 #company-registration-section_1-card, #company-registration-section_3 #company-registration-section_2-card, #company-registration-section_3 #company-registration-section_3-card {
      padding: 60px 20px;
    }
  }
  
  .otp-verify-image {
    max-width: 80px;
  }
  
  .upload-photo-icon {
    max-width: 140px;
    min-width: 130px;
  }
  
  .skip-for-later, .edit-number {
    max-width: 20px;
  }
  
  .user-registration-card-bg, .company-registration-card-bg {
    display: flex;
    align-items: flex-end;
    width: auto;
    height: 100%;
  }
  
  #property-detail-hero {
    position: relative;
    z-index: 2;
  }
  
  #property-detail-hero img {
    max-width: 100%;
  }
  
  #property-detail-hero #property-detail-hero-image {
    position: relative;
  }
  
  #property-detail-hero #property-detail-hero-image #property-detail-hero-image-badge {
    position: absolute;
    top: 15px;
    right: 0;
    padding: 8px;
    background-color: #1E282E;
    color: #fff;
  }
  
  @media (min-width: 1024px) {
    #property-detail-hero #property-detail-hero-image {
      height: 825px;
    }
    #property-detail-hero #property-detail-hero-image img {
      width: auto;
      height: 100%;
      object-fit: cover;
    }
  }
  
  #property-detail-hero #property-detail-hero-gallery {
    position: relative;
    z-index: 3;
  }
  
  #property-detail-hero #property-detail-hero-gallery #property-detail-hero-gallery-images .property-detail-hero-gallery-image {
    display: inline-block;
  }
  
  #property-detail-hero #property-detail-hero-gallery #property-detail-hero-gallery-images .property-detail-hero-gallery-image + .property-detail-hero-gallery-image {
    margin-top: 24px;
  }
  
  @media (min-width: 1024px) {
    #property-detail-hero #property-detail-hero-gallery #property-detail-hero-gallery-images .property-detail-hero-gallery-image + .property-detail-hero-gallery-image {
      margin-top: 30px;
    }
  }
  
  #property-detail-hero #property-detail-hero-gallery #property-detail-hero-gallery-images .property-detail-hero-gallery-image:nth-of-type(1n+3) {
    display: none;
  }
  
  #property-detail-hero #property-detail-hero-gallery #property-detail-hero-gallery-find-more {
    cursor: pointer;
    padding: 24px;
    background-color: rgba(30, 40, 46, 0.4);
    text-align: center;
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  
  #property-detail-hero #property-detail-share {
    padding: 16px 24px;
    background-color: white;
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.15);
  }
  
  #property-detail-hero #property-detail-share #property-detail-share-icons a {
    display: inline-block;
  }
  
  #property-detail-hero #property-detail-share #property-detail-share-icons a img {
    height: 21px;
  }
  
  #property-detail-hero #property-detail-share #property-detail-share-icons a + a {
    margin-left: 10px;
  }
  
  #property-detail-meta-and-banner {
    background-color: rgba(96, 96, 96, 0.05);
  }
  
  #property-detail-meta-and-banner #property-detail-meta #property-detail-meta-top {
    padding: 24px;
    background-color: #1E282E;
    color: #fff;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 3;
  }
  
  #property-detail-meta-and-banner #property-detail-meta #property-detail-meta-top .property-detail-meta {
    font-size: 18px;
  }
  
  #property-detail-meta-and-banner #property-detail-meta #property-detail-meta-top .property-detail-meta img {
    height: 40px;
  }
  
  #property-detail-meta-and-banner #property-detail-meta #property-detail-meta-top .property-detail-meta:not(:last-child) {
    border-bottom: 1px solid #fff;
  }
  
  @media (min-width: 1024px) {
    #property-detail-meta-and-banner #property-detail-meta #property-detail-meta-top .property-detail-meta:not(:last-child) {
      border-bottom: none;
      border-right: 1px solid #fff;
    }
  }
  
  #property-detail-meta-and-banner #property-detail-meta #property-detail-meta-middle {
    padding: 36px;
    background-color: #fff;
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 3;
  }
  
  #property-detail-meta-and-banner #property-detail-meta #property-detail-meta-middle .property-detail-meta:not(:last-child) {
    border-bottom: 1px solid rgba(30, 40, 46, 0.3);
  }
  
  @media (min-width: 1024px) {
    #property-detail-meta-and-banner #property-detail-meta #property-detail-meta-middle .property-detail-meta:not(:last-child) {
      border-bottom: none;
      border-right: 1px solid rgba(30, 40, 46, 0.3);
    }
  }
  
  @media (min-width: 1024px) {
    #property-detail-meta-and-banner #property-detail-meta #property-detail-meta-middle {
      margin-left: 30px;
      margin-right: 30px;
      padding-bottom: 72px;
    }
  }
  
  #property-detail-meta-and-banner #property-detail-meta #property-detail-meta-bottom {
    padding: 24px;
    background-color: #1E282E;
    color: #fff;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 3;
  }
  
  #property-detail-meta-and-banner #property-detail-meta #property-detail-meta-bottom .property-detail-meta {
    font-size: 24px;
  }
  
  #property-detail-meta-and-banner #property-detail-meta #property-detail-meta-bottom .property-detail-meta img {
    height: 36px;
  }
  
  #property-detail-meta-and-banner #property-detail-meta #property-detail-meta-bottom .property-detail-meta:not(:last-child) {
    border-bottom: 1px solid #fff;
  }
  
  @media (min-width: 1024px) {
    #property-detail-meta-and-banner #property-detail-meta #property-detail-meta-bottom .property-detail-meta:not(:last-child) {
      border-bottom: none;
      border-right: 1px solid #fff;
    }
  }
  
  #property-detail-meta-and-banner #property-detail-meta #property-detail-meta-bottom #property-detail-meta-bedrooms img {
    height: 27px;
  }
  
  @media (min-width: 1024px) {
    #property-detail-meta-and-banner #property-detail-meta #property-detail-meta-bottom {
      margin-top: -36px;
    }
  }
  
  @media (min-width: 1024px) {
    #property-detail-meta-and-banner #property-detail-meta {
      margin-top: -270px;
    }
  }
  
  #property-detail-content #property-detail-tab-links-wrapper {
    background-color: #f7f7f7;
  }
  
  #property-detail-content #property-detail-tab-links #property-detail-tabs {
    border: none;
  }
  
  #property-detail-content #property-detail-tab-links #property-detail-tabs .nav-item .nav-link {
    color: #1E282E;
    font-size: 21px;
    font-weight: 700;
    border: none;
    padding-bottom: 12px;
  }
  
  #property-detail-content #property-detail-tab-links #property-detail-tabs .nav-item .nav-link.active {
    background-color: transparent;
    border-bottom: 4px solid #1E282E;
  }
  
  #property-detail-content #property-detail-tab-amenities > div.bg-black {
    background-color: #1E282E;
    color: #fff;
  }
  
  #property-detail-content #property-detail-amenities .property-detail-amenity img {
    height: 50px;
  }
  
  #property-detail-content #property-detail-reviews .property-detail-review {
    border-bottom: 1px solid rgba(0, 0, 0, 0.11);
    padding-bottom: 20px !important;
  }
  
  #property-detail-content #property-detail-reviews .property-detail-review:last-of-type {
    border: none;
  }
  
  #property-detail-content #property-detail-reviews .property-detail-review .property-detail-review-image img {
    max-width: 50px;
    border-radius: 50%;
  }
  
  #property-detail-content #property-detail-reviews .property-detail-review + .property-detail-review {
    margin-top: 30px;
  }
  
  #property-detail-content #property-detail-reviews .property-rating img {
    width: 22px;
  }
  
  #other-property-carousel {
    overflow: hidden;
  }
  
  #other-property-carousel .owl-stage-outer {
    overflow: visible;
  }
  
  .other-property .other-property-images {
    max-width: 100%;
    position: relative;
  }
  
  .other-property .other-property-images .other-property-banner {
    position: absolute;
    top: 24px;
    right: 24px;
    padding: 10px 15px 21px;
    background-image: url("/images/other-property_banner-bg.svg");
    background-size: 145px 55px;
    background-repeat: no-repeat;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  .other-property .other-property-images img {
    max-width: 100%;
    border-radius: 10px;
  }
  
  @media (min-width: 768px) {
    .other-property .other-property-card {
      max-width: 340px;
      margin-top: -120px;
    }
  }
  
  #property-contact-card .property-agent-image {
    max-width: 60px;
    border-radius: 50%;
    background: linear-gradient(to right, #FBB040 0%, #FFDE17 100%);
    padding: 3px;
  }
  
  #property-contact-card .property-detail-share-icon {
    max-width: 27px;
  }
  
  #property-contact-card .property-contact-details .company-name {
    border-top: 1px solid #C4C4C4;
  }
  
  #property-contact-card .property-contact-details .company-icon {
    max-width: 30px;
    margin-left: 5px;
  }
  
  #property-contact-card .property-contact-details .call-agent-icon {
    max-width: 24px;
    float: left;
  }
  
  #property-contact-card .property-contact-details .list-group {
    padding: 20px 0px;
  }
  
  #property-contact-card .property-contact-details .list-group .list-group-item {
    display: flex;
    justify-content: space-between;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
  }
  
  #property-contact-card .property-contact-details .list-group .list-group-item p {
    margin-bottom: 0;
    vertical-align: middle;
  }
  
  #property-contact-card .property-contact-details .whatsapp-button {
    background: #66E26F;
    color: white;
    width: 100%;
  }
  
  #property-contact-card .property-contact-details .email-button {
    background: #64B5F6;
    color: white;
    width: 100%;
  }
  
  #kyc-process-section_1 #kyc-process-section_1-step1, #kyc-process-section_1 #kyc-process-section_1-step2, #kyc-process-section_1 #kyc-process-section_1-step3, #kyc-process-section_1 #kyc-process-section_1-step4, #kyc-process-section_1 #kyc-process-section_1-step5, #kyc-process-section_1 #kyc-process-section_1-step6, #kyc-process-section_1 #kyc-process-section_1-step7 {
    padding: 60px 0;
  }
  
  @media (min-width: 1024px) {
    #kyc-process-section_1 #kyc-process-section_1-step1, #kyc-process-section_1 #kyc-process-section_1-step2, #kyc-process-section_1 #kyc-process-section_1-step3, #kyc-process-section_1 #kyc-process-section_1-step4, #kyc-process-section_1 #kyc-process-section_1-step5, #kyc-process-section_1 #kyc-process-section_1-step6, #kyc-process-section_1 #kyc-process-section_1-step7 {
      padding: 120px 0;
    }
  }
  
  #kyc-process-section_1 .kyc-process-inner-card {
    min-height: 500px;
  }
  
  #kyc-process-section_1 .back-button {
    display: flex;
    align-items: center;
    font-size: 17px;
  }
  
  #kyc-process-section_1 .back-button img {
    max-width: 30px;
    margin: 0px 10px;
  }
  
  #kyc-process-section_1 .kyc-process-loader img {
    max-width: 80px;
    margin: 10px;
    cursor: pointer;
  }
  
  #kyc-process-section_1 .kyc-process-completion img {
    max-width: 100px;
    max-width: 80px;
    margin: 10px;
  }
  
  #kyc-process-section_1 .list-group {
    padding: 20px 0px;
  }
  
  #kyc-process-section_1 .list-group.document-list .list-group-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0;
    border: 0;
    background: #FAFAFA;
    margin: 10px 0px;
    font-weight: 600;
  }
  
  #kyc-process-section_1 .list-group.document-list .list-group-item p {
    margin-bottom: 0;
    vertical-align: middle;
    margin: 0px 20px;
  }
  
  #kyc-process-section_1 .list-group.document-list .list-group-item img {
    width: 40px;
    height: 40px;
    margin: 0px 20px;
  }
  
  #kyc-process-section_1 .list-group.document-list input[type=radio] {
    display: none;
  }
  
  #kyc-process-section_1 .list-group.document-list input[type=radio]:checked + label li {
    background: #fae439;
  }
  
  #kyc-process-section_1 .list-group.document-list input[type=radio] + label {
    padding-left: 0;
  }
  
  #kyc-process-section_1 .list-group.document-list input[type=radio] + label::after {
    display: none;
  }
  
  #kyc-process-section_1 .list-group.country-list .list-group-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0;
    border: 0;
    background: transparent;
  }
  
  #kyc-process-section_1 .list-group.country-list .list-group-item p {
    margin-bottom: 0;
    vertical-align: middle;
    margin: 0px 20px;
  }
  
  #kyc-process-section_1 .list-group.country-list .list-group-item img {
    max-width: 50px;
    height: 50px;
  }
  
  #kyc-source-of-wealth-questionnaire-section_1 #kyc-source-of-wealth-questionnaire-section_1-container {
    padding: 60px 0;
  }
  
  @media (min-width: 1024px) {
    #kyc-source-of-wealth-questionnaire-section_1 #kyc-source-of-wealth-questionnaire-section_1-container {
      padding: 120px 0;
    }
  }
  
  #kyc-source-of-wealth-questionnaire-section_1 .kyc-source-of-wealth-questionnaire-inner-card {
    min-height: 500px;
  }
  
  #otp-inner-wrapper {
    left: 0;
    position: sticky;
  }
  
  #otp-outer-wrapper {
    width: 310px;
    overflow: hidden;
  }
  
  #partitioned-otp {
    padding-left: 22px;
    letter-spacing: 69px;
    border: 0;
    background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 77px 0.099em;
    background-repeat: repeat-x;
    background-position-x: 56px;
    width: 340px;
    font-weight: 500;
    min-width: 340px;
    margin-top: 15px;
  }
  
  #partitioned-otp:focus {
    outline: none;
  }
  
  #landing-page-section_1 {
    background-color: #f1f1f1;
  }
  
  #landing-page-section_1 #landing-page-section_1-container {
    padding: 60px 0;
    overflow: hidden;
    max-height: 800px;
  }
  
  @media (min-width: 1024px) {
    #landing-page-section_1 #landing-page-section_1-container {
      padding: 120px 0 90px;
    }
  }
  
  #landing-page-section_1 #landing-page-section_1-container:after {
    content: '';
    background-image: url("/images/landing-page-section-1_bg.png");
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 115% 100%;
    padding-top: 31%;
    z-index: 15;
  }
  
  #landing-page-section_1 #landing-page-section_1-container .mobile-screen .mobile-bg {
    position: absolute;
    height: 720px;
    width: auto;
    border-radius: 32px;
    left: 51%;
    -webkit-transform: translate(-49%);
            transform: translate(-49%);
    z-index: 7;
  }
  
  #landing-page-section_1 #landing-page-section_1-container .mobile-screen .description {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    z-index: 24;
  }
  
  #landing-page-section_1 #landing-page-section_1-container .inner-black-background {
    position: absolute;
    left: 50%;
    bottom: 20%;
    -webkit-transform: translate(-50%, -20%);
            transform: translate(-50%, -20%);
    z-index: 12;
  }
  
  #landing-page-section_1 #landing-page-section_1-container .inner-black-background img {
    width: 700px;
  }
  
  @media (max-width: 1023.98px) {
    #landing-page-section_1 #landing-page-section_1-container .inner-black-background img {
      width: auto;
    }
  }
  
  #landing-page-section_1 #landing-page-section_1-container .outer-black-background {
    position: absolute;
    left: 50%;
    bottom: 15%;
    -webkit-transform: translate(-50%, -15%);
            transform: translate(-50%, -15%);
    z-index: 4;
  }
  
  #landing-page-section_1 #landing-page-section_1-container .rotating-icon-list {
    -webkit-animation: rotating 25s linear infinite;
            animation: rotating 25s linear infinite;
    position: relative;
    width: 1200px;
    overflow: hidden;
    top: 15%;
    -webkit-transform: translate(-15%);
            transform: translate(-15%);
    max-width: none !important;
    will-change: transform;
  }
  
  @media (max-width: 767.98px) {
    #landing-page-section_1 #landing-page-section_1-container .rotating-icon-list {
      opacity: 0;
    }
  }
  
  #landing-page-section_2 {
    background-color: #444444;
  }
  
  #landing-page-section_2 #landing-page-section_2-container {
    padding: 60px 0;
  }
  
  @media (min-width: 1024px) {
    #landing-page-section_2 #landing-page-section_2-container {
      padding: 120px 0 90px;
    }
  }
  
  #landing-page-section_3 {
    background: url("/images/landing-page-section-3_about_us_bg.png");
    background-repeat: no-repeat;
    background-size: 100% 80%;
  }
  
  @media (max-width: 767.98px) {
    #landing-page-section_3 {
      background-size: contain;
    }
  }
  
  #landing-page-section_3 #landing-page-section_3-container {
    padding: 60px 0;
  }
  
  @media (min-width: 1024px) {
    #landing-page-section_3 #landing-page-section_3-container {
      padding: 120px 0 90px;
    }
  }
  
  #landing-page-section_3 #landing-page-section_3-container ul.about-us-list {
    list-style-image: url("/images/landing-page-section-3_about_us_list-bullet.svg");
    padding: 0;
  }
  
  #landing-page-section_3 #landing-page-section_3-container ul.about-us-list li {
    padding: 20px 0px;
  }
  
  #landing-page-section_4 {
    background-image: url("/images/landing-page-section_4-dots-bg.svg");
    background-repeat: no-repeat;
    background-position: top 0px right 24px;
  }
  
  @media (max-width: 767.98px) {
    #landing-page-section_4 {
      background-image: none;
    }
  }
  
  #landing-page-section_4 #landing-page-section_4-container {
    padding: 60px 0;
  }
  
  @media (min-width: 1024px) {
    #landing-page-section_4 #landing-page-section_4-container {
      padding: 120px 0 90px;
    }
  }
  
  #landing-page-section_4 #landing-page-section_4-container .benefit-image img {
    width: 220px;
  }
  
  #landing-page-section_4 #landing-page-section_4-container .benefits-artwork {
    min-width: 520px;
    margin-left: 20px;
  }
  
  @media (max-width: 1439.98px) {
    #landing-page-section_4 #landing-page-section_4-container .benefits-artwork {
      min-width: 390px;
      margin-left: 0;
    }
  }
  
  @media (max-width: 767.98px) {
    #landing-page-section_4 #landing-page-section_4-container .benefits-artwork {
      min-width: unset;
      width: auto;
    }
  }
  
  #landing-page-section_5 {
    background-image: url("/images/landing-page-section-5_bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  
  @media (max-width: 1023.98px) {
    #landing-page-section_5 {
      background-size: cover;
    }
  }
  
  #landing-page-section_5 #landing-page-section_5-container {
    padding: 60px 0;
  }
  
  @media (min-width: 1024px) {
    #landing-page-section_5 #landing-page-section_5-container {
      padding: 90px 0 90px;
    }
  }
  
  #landing-page-section_5 #landing-page-section_5-container .access-code-artwork {
    min-width: 760px;
  }
  
  @media (max-width: 1023.98px) {
    #landing-page-section_5 #landing-page-section_5-container .access-code-artwork {
      min-width: unset;
      width: auto;
    }
  }
  
  #landing-page-section_5 #landing-page-section_5-container .nav-tabs {
    border-bottom: none;
    background: #EFEFEF;
    padding: 10px;
    border-radius: 11px;
    color: #1E1E1E;
    width: 65%;
    margin: auto;
  }
  
  #landing-page-section_5 #landing-page-section_5-container .nav-tabs .nav-item a {
    color: #1E1E1E;
    font-weight: 500;
    padding: 12px 15px;
    border-radius: 11px;
  }
  
  #landing-page-section_5 #landing-page-section_5-container .nav-tabs .nav-item a.active {
    background-color: #1E1E1E;
    color: white;
  }
  
  #landing-page-section_5 #landing-page-section_5-container label {
    color: #333231;
    font-weight: 600;
    margin-bottom: 0;
  }
  
  #landing-page-section_5 #landing-page-section_5-container input, #landing-page-section_5 #landing-page-section_5-container select {
    background: none !important;
    border: none;
    border-bottom: 1px solid #8D8D8D !important;
    border-radius: 0;
    padding: 0 !important;
  }
  
  #landing-page-section_6 {
    background-image: url("/images/landing-page-section-6_dots_bg.png");
    background-size: calc(100% - 20px);
    background-repeat: no-repeat;
  }
  
  #landing-page-section_6 #landing-page-section_6-container {
    padding: 60px 0;
  }
  
  @media (min-width: 1024px) {
    #landing-page-section_6 #landing-page-section_6-container {
      padding: 120px 0 90px;
    }
  }
  
  #landing-page-section_6 #landing-page-section_6-container .card {
    background: white;
    box-shadow: 10px 10px 99px #D3D3D329;
    border-radius: 26px;
    border: none;
    margin: 30px 0px;
    font-size: 18px;
  }
  
  #landing-page-section_6 #landing-page-section_6-container .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding: 1.2rem;
    border: none;
  }
  /*# sourceMappingURL=theme.css.map */
.markerMap {
  width: 30px;
  height: 30px;
}

/* select box */
/* .select-box {
    z-index: 4;
  } */
.select-box > div:first-child {
  height: calc(1.6875rem + 2rem + 2px);
}

.select-box > div:first-child:hover {
  height: calc(1.6875rem + 2rem + 2px);
}

/* toast color */

.Toastify__toast--success {
  background: #f8b63a !important;
}
.Toastify__toast--error {
  background: #fae439 !important;
}

.loaderHolder {
  left: 0;
  top: 0;
  position: fixed;
  background: transparent;
  width: 100%;
  height: 100%;
  background-color: rgba(126, 125, 111, 0.23);
  z-index: 1000;
}
.loaderMain {
  position: absolute;
  left: 50%;
  top: 35%;
  z-index: 1000;
  height: 31px;
  width: 31px;
}

.tokenization {
  margin-bottom: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 40px;
}

.btn-custom {
  background-color: #fdc63d !important;
  border-color: #fdc63d !important;
  color: #000 !important;
  border-radius: 5px;
}

.btn-default {
  background-color: #fff !important;
  border-color: #ccc !important;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}
.pagination > li {
  display: inline;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #337ab7;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  z-index: 2;
  color: #23527c;
  background-color: #eeeeee;
  border-color: #ddd;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  z-index: 3;
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
  cursor: default;
}

.tab-content > .tab-pane {
  display: block !important;
}

@media (min-width: 768px) {
  #search-form #search-form-bottom #search-form-bottom-buttons {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    margin-bottom: -70px;
    z-index: 0 !important;
  }
}

.reatinn-box {
  display: flex;
  max-width: 500px !important;
  height: auto;
  flex-wrap: wrap;
}

.reatinn-box > div.dashed-border {
  position: relative;
  padding: 0 !important;
  margin-right: 10px;
}

.reatinn-box > div.dashed-border .image-item__btn-wrapper {
  position: absolute;
  top: 0;
  right: 0;
}

.reatinn-box > div.dashed-border > img {
  height: 100px;
  object-fit: cover;
  width: 100px;
}

.reatinn-box > div.dashed-border .image-item__btn-wrapper > button {
  width: 25px;
  height: 25px;
  overflow: hidden;
  border: 0;
  background-color: rgb(255 255 255 / 74%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #c32e0b;
}

.reatinn-box > label.dashed-border {
  width: 100px;
  min-width: 130px;
  height: 100px;
  padding: 0 10px !important;
  margin: 0;
}
.reatinn-box > * {
  margin-bottom: 10px;
}
.reatinn-box .dashed-border .image-upload > p {
  width: 100px;
}

.review-modal .form-control.secondary-input {
  border: 1px solid #ccc !important;
}

.error {
  color: #b00020;
}
#landing-page-section_4-container .benefit-image {
  height: 110px;
  min-width: 110px;
  width: 110px;
  padding: 1rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#landing-page-section_4-container .no-gutters .d-flex {
  justify-content: space-between;
}

.App {
  font-family: sans-serif;
  text-align: center;
}
.modal {
  background: rgba(0, 0, 0, 0.6);
}
.modal-container {
  position: absolute;
  width: 60%;
  background: #ffffff;
  left: 25%;
  top: 30%;
  padding: 20px;
}
.whyus_header {
  margin-top: 8rem !important;
}
.img-fluid-col {
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-nav {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.landing-page-footer .social-nav a {
  display: flex;
  width: 45px;
  height: 45px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.card .property-image {
  max-height: 220px !important;
  overflow: hidden;
}
.card .property-image img {
  min-height: 220px;
  width: 100% !important;
}
.owl-stage-outer {
  padding: 1rem 0;
}
.investment-deal .investment-deal-images {
  max-height: 220px !important;
  height: 220px;
  overflow: hidden;
  border-radius: 10px;
}
.investment-deal .investment-deal-images img {
  min-height: 180px !important;
  display: block;
}
.investment-deal .investment-deal-card {
  margin-top: -20px !important;
}

.footer_bottom {
  padding: 3rem 0;
  padding-bottom: 3rem;
  color: #b9b9b9;
}
.copyright-text {
  color: #b9b9b9 !important;
}
.footer_bottom p {
  font-size: 0.85rem;
  font-weight: normal;
}
.footer_bottom p:first-child {
  font-size: 1rem;
}

.comingSoonBtn {
  position: absolute;
  top: -10px;
  right: 0;
  background-image: linear-gradient(to right, #fbb040 0%, #ffde17 100%);
  font-size: 14px;
  padding: 0.1rem 0.75rem;
}

#property-detail-amenities {
  flex-wrap: wrap;
  grid-row-gap: 2rem;
  row-gap: 2rem;
  justify-content: flex-start !important;
}

#property-detail-amenities .property-detail-amenity {
  width: 25%;
}

.upload-agent-profile {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
}

.list-property-image {
  overflow: hidden;
  max-height: 363px;
  border-radius: 10px;
}
.featured-property-images a {
  overflow: hidden;
  height: 260px;
  border-radius: 10px;
}
.featured-property .property-card {
  margin-top: -80px !important;
}
.featured-property-images img {
  min-height: 260px;
}
.investment_deals_carousel {
}
/* .investment-deal-images {
  min-height: 260px;
} */
.investment-deal {
}
.image-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.agent-profile_upload {
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
}
.agent-profile_upload img {
  position: absolute;
  top: 50%;
  width: 120%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

#search-form-top-buttons {
  margin-bottom: 35px !important;
}
#search-form-top-buttons .btn {
  background: rgb(255, 255, 255);
}
#search-form-top-buttons .btn.btn-gradient {
  background-image: linear-gradient(
    to right,
    #fbb040 0%,
    #ffde17 100%
  ) !important;
}
.forerroemsg .error {
  position: absolute;
  top: 100px;
}
#company-nav-tab-agent-management #search-filter_options {
  margin-bottom: 0 !important;
}

.agent-image-box {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-top: -70px;
  top: -10px;
  background-color: #fff;
  left: -3rem;
}

.agent-image-box img {
  margin-top: 0 !important;
  max-width: 110% !important;
  border-radius: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
@media (max-width: 1024px) {
  .agent-image-box {
    left: 50%;
    margin-top: 0;
    margin: 1rem 0;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

.pageNotFound {
  max-width: 700px;
  margin: 0 auto;
  position: relative;
  top: 4rem;
  text-align: center;
}

.pageNotFound img {
  width: 100%;
}
.pageNotFound h1 {
  margin-bottom: 2rem;
}
.pageNotFound p {
  width: 70%;
  margin: 0 auto;
  font-size: 20px;
  color: grey;
}

.pageNotFound .btnBox {
  display: flex;
  margin-top: 3rem;
  justify-content: center;
  grid-gap: 2rem;
  gap: 2rem;
}

#search-form-top {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.image-upload .form-group {
  position: absolute;
}

.video-react .video-react-big-play-button {
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important;
}

.property-card .empty--card {
  height: 420px;
}
.featured--proprty {
  overflow: hidden;
}

.featured--proprty a {
  text-decoration: none;
  color: #212529;
}

.featured--proprty .card-body {
  position: relative;
}
.featured--proprty .card-body .not--approved {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
}
.featured--proprty .card-body .property-type {
  position: relative;
}
.property-agent {
  display: flex;
  align-items: center;
}
.investment--banner {
  background-image: url(/static/media/investments-banner.89f1d079.png);
  background-size: cover;
  background-position: left bottom;
  background-repeat: no-repeat;
  color: #fff;
}
.investment--banner h1 span {
  background-image: linear-gradient(to right, #fbb040 0%, #ffde17 100%);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.investment--banner .banner--heading {
  padding: 8rem 2rem;
}
.investment--banner .banner--heading h1 {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  width: 70%;
  margin: 0 auto;
}

.investorDataList {
  list-style: none;
  padding: 0;
}

#investor-section_6 {
  padding: 4rem 0;
}

.realEstateDataList {
  list-style: none;
  padding: 0;
}
.realEstateDataList li {
  display: flex;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  margin-bottom: 1rem;
  align-items: flex-start;
}
.realEstateDataList .icon {
  min-width: 60px;
  height: 60px;
  border-radius: 50%;
}

.realEstateDataList .icon img {
  object-fit: cover;
}

/******************************
 *  DASHBOARD THEME  *
 ******************************/
/**********************
 * SETTINGS OVERRIDES *
 **********************/
 @media (max-width: 1023.98px) {
  body.has-overlay {
    position: relative;
    overflow-y: hidden;
  }
  body.has-overlay .overlay {
    position: absolute;
    content: " ";
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(1, 1, 1, 0.1);
    z-index: 1;
  }
}

.dashboard-nav-tabs {
  border: none;
}

.dashboard-nav-tabs .nav-item .nav-link {
  position: relative;
  color: #1E282E;
  font-weight: 700;
  border: none;
  padding: 16px 24px 16px 36px;
}

.dashboard-nav-tabs .nav-item .nav-link:after {
  position: absolute;
  display: none;
  content: " ";
  background-image: linear-gradient(to right, #FBB040 0%, #FFDE17 100%);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 14px;
  top: 4px;
  left: 0;
  bottom: 4px;
}

.dashboard-nav-tabs .nav-item .nav-link.active {
  background-color: transparent;
}

.dashboard-nav-tabs .nav-item .nav-link.active:after {
  display: block;
}

.dashboard-table-container {
  background-color: #F0F2F7;
  padding: 24px 16px;
  width: 100%;
  min-height: 500px;
}

.dashboard-table-container table.dashboard-table {
  width: 100%;
  border-collapse: inherit;
  border-spacing: 2px 12px;
  overflow: auto;
}

.dashboard-table-container table.dashboard-table thead,
.dashboard-table-container table.dashboard-table tfoot {
  background-color: rgba(30, 40, 46, 0.4);
  color: #fff;
}

.dashboard-table-container table.dashboard-table thead th,
.dashboard-table-container table.dashboard-table tfoot th {
  padding: 16px;
  text-align: center;
  font-size: 18px;
}

.dashboard-table-container table.dashboard-table thead th:first-child,
.dashboard-table-container table.dashboard-table tfoot th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  overflow: hidden;
}

.dashboard-table-container table.dashboard-table thead th:last-child,
.dashboard-table-container table.dashboard-table tfoot th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .dashboard-table-container table.dashboard-table thead th,
  .dashboard-table-container table.dashboard-table tfoot th {
    padding: 24px;
  }
}

.dashboard-table-container table.dashboard-table tbody tr {
  background-color: #fff;
  border-radius: 10px;
  margin-top: 8px;
  box-shadow: -1px 1.7px 4.25px rgba(0, 0, 0, 0.15);
}

.dashboard-table-container table.dashboard-table tbody td {
  padding: 13px;
  text-align: center;
  font-weight: 700;
}

.dashboard-table-container table.dashboard-table tbody td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  overflow: hidden;
}

.dashboard-table-container table.dashboard-table tbody td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}

.dashboard-table-pagination {
  display: inline-block;
}

.edit-button {
  cursor: pointer;
}

.edit-button span {
  vertical-align: middle;
  margin: 0px 5px;
}

.edit-button img {
  max-width: 22px;
}

.my-profile-view input, .my-profile-view select {
  pointer-events: none;
  background: none !important;
  border-bottom: 0.02rem solid #374047 !important;
  border-radius: 0;
  padding: 0 !important;
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
  z-index: 1;
}

@media (max-width: 1023.98px) {
  .wrapper {
    padding-left: 0px;
  }
}

.wrapper #sidebar {
  min-width: 320px;
  max-width: 320px;
  min-height: 100vh;
  transition: 0.3s -webkit-transform;
  transition: 0.3s transform;
  transition: 0.3s transform, 0.3s -webkit-transform;
  background-color: #fff;
  position: relative;
  z-index: 3;
  margin: 50px 0px;
}

.wrapper #sidebar .nav {
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.15);
}

@media (max-width: 1023.98px) {
  .wrapper #sidebar .nav {
    box-shadow: none;
  }
}

@media (max-width: 1023.98px) {
  .wrapper #sidebar {
    margin-top: -220px;
    margin-bottom: 0;
    -webkit-transform: translateX(-320px);
            transform: translateX(-320px);
    box-shadow: none;
    min-height: calc(100vh + 220px);
  }
  .wrapper #sidebar.active {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    background-color: #fff;
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.15);
  }
}

.wrapper #sidebar .sidebar-top:after {
  position: absolute;
  content: " ";
  background-image: linear-gradient(to right, #FBB040 0%, #FFDE17 100%);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  top: 0;
  z-index: 3;
  left: 0;
  height: 10px;
  width: 100%;
}

.wrapper #sidebar .sidebar-top .user-details-card {
  background-color: #FAFAFA;
  box-shadow: 1.3px 1.3px 7.34px rgba(0, 0, 0, 0.158);
}

.wrapper #sidebar .sidebar-top .user-details-card .user-profile-pic-section img.user-profile-pic {
  position: relative;
  border-radius: 50px;
  max-width: 80px;
  margin-top: -60px;
  z-index: 6;
}

@media (max-width: 1023.98px) {
  .wrapper #sidebar .sidebar-top .user-details-card .user-profile-pic-section img.user-profile-pic {
    margin-top: 0px;
  }
}

.wrapper #sidebar .sidebar-top .user-details-card .user-profile-pic-section img.edit-profile-pic {
  position: absolute;
  max-width: 35px;
  margin-left: -20px;
  top: 15px;
  z-index: 8;
}

@media (max-width: 1023.98px) {
  .wrapper #content {
    width: 100%;
    position: absolute;
  }
}

.wrapper #content .navbar {
  height: 190px;
  align-items: center;
}

.dark-search-bar-group {
  background-color: #1E282E;
  padding-left: 68px;
  background-image: url(/public/images/icon-search.svg);
  background-position: center left 16px;
  background-repeat: no-repeat;
  background-size: 28px;
}

.dropdown-toggle.language {
  font-size: 25px;
  color: #000;
}

.grey-header {
  background-color: rgba(30, 40, 46, 0.404);
  color: white;
}

.super-admin-wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
  z-index: 1;
}

.super-admin-wrapper #sidebar {
  min-width: 320px;
  max-width: 320px;
  min-height: 100vh;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.15);
  transition: 0.3s -webkit-transform;
  transition: 0.3s transform;
  transition: 0.3s transform, 0.3s -webkit-transform;
  background-color: #fff;
  position: relative;
  z-index: 2;
}

.super-admin-wrapper #sidebar .navbar-brand {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #FAFAFA;
}

@media (max-width: 1023.98px) {
  .super-admin-wrapper #sidebar {
    margin-top: -160px;
    -webkit-transform: translateX(-320px);
            transform: translateX(-320px);
    box-shadow: none;
    min-height: calc(100vh + 160px);
  }
  .super-admin-wrapper #sidebar.active {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.15);
    background-color: #fff;
  }
}

@media (max-width: 1023.98px) {
  .super-admin-wrapper #content {
    width: 100%;
    position: absolute;
  }
}

.super-admin-wrapper #content .navbar {
  height: 190px;
  align-items: center;
}

.super-admin-wrapper #content #spacer {
  height: 48px;
  background-color: #FEC63D;
}

@media (min-width: 1024px) {
  .super-admin-wrapper #content #spacer {
    height: 75px;
  }
}

.superadmin-cards .superadmin-card {
  border-radius: 10px;
}

.superadmin-cards .superadmin-card .superadmin-card-header {
  min-height: 68px;
  border-bottom: 2px solid rgba(55, 64, 71, 0.1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

ul.list-group.transactions .list-group-item {
  border: none;
  padding: 10px 25px;
}

ul.list-group.transactions .list-group-item:first-of-type {
  border-right: 1px solid #374047;
}

ul.list-group.transactions .list-group-item img {
  height: 60px;
  max-width: 60px;
}

.tokenization-modal .modal-content {
  border-radius: 20px;
}

.tokenization-modal .modal-footer {
  border-top: none;
}

.tokenization-modal .modal-body img {
  max-width: 120px;
  margin: 20px 0px;
}

.custom-modal {
  top: 10%;
}

.custom-modal .modal-content {
  border-radius: 20px;
}

.custom-modal .modal-footer {
  border-top: none;
}

.custom-modal .modal-body img {
  max-width: 110px;
  margin: 20px 0px;
}

.custom-modal .modal-body p {
  font-size: 18px;
}

.modal-close-btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 32px;
  background: none;
  margin: 0px 15px;
  outline: none;
  border: none;
  margin-left: auto;
  padding: 0;
}

.modal-close-btn:focus {
  outline: none;
  box-shadow: none;
}

.kyc-status-confirmed {
  background: url("/public/images/kyc-status-confirmed-bg.svg") no-repeat right -80px center;
}

.kyc-status-pending {
  background: url("/public/images/kyc-status-pending-bg.svg") no-repeat right -80px center;
}

.kyc-status-rejected {
  background: url("/public/images/kyc-status-rejected-bg.svg") no-repeat right -80px center;
}

.avatar-image {
  border-radius: 50%;
  max-width: 65px;
}
/*# sourceMappingURL=dashboard.css.map */
